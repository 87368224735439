<template>
  <div>
    <p>{{ $t('signup.description') }}</p>
    <ValidationObserver ref="form">
      <form>
        <div class="form-group">
          <label class="typo__label">{{ $t('form.selectoffice') }}</label>
          <multiselect :options-limit="300"
                      :clear-on-select="false"
                      :internal-search="false"
                      @search-change="searchOffices"
                      v-model="form.offices"
                      :options="offices"
                      :placeholder="$t('form.selectofficeplaceholder')" label="name" track-by="name">

            <template slot="option" slot-scope="props">
              <div class="media">
                <img class="mr-3" width="50" :src="props.option.thumbnail" alt="Generic placeholder image">
                <div class="media-body">
                  <h5 class="mt-0">{{ props.option.name }}</h5>
                  {{ props.option.address }}
                </div>
              </div>
            </template>

          </multiselect>
          <!-- <p class="errors" v-if="errors.has('officeName')">{{ errors.first('officeName')}}</p> -->
        </div>
        <div class="form-group">
          <label class="typo__label">{{ $t('form.selectlanguages') }}</label>
          <multiselect
            :multiple="true"
            v-model="form.speaking_languages"
            track-by="language"
            label="language"
            :searchable="false"
            :options="languages"
            :placeholder="$t('form.selectlanguagesplaceholder')"
          />
          <!-- <p class="errors" v-if="errors.has('language')">{{ errors.first('language')}}</p> -->
        </div>
        <div class="form-group">
          <label class="typo__label">{{ $t('form.selectprofessions') }}</label>
          <multiselect
                  :multiple="false"
                  v-model="form.professions"
                  track-by="title"
                  label="title"
                  :searchable="false"
                  :options="professions"
                  :placeholder="$t('form.selectprofessionsplaceholder')"
          />
        </div>
        <div class="form-group">
          <label class="typo__label">{{ $t('form.selectservices') }}</label>
          <multiselect
            :multiple="true"
            v-model="form.services"
            track-by="title"
            label="title"
            :searchable="false"
            :options="services"
            :placeholder="$t('form.selectservicesplaceholder')"
          />
          <!-- <p class="errors" v-if="errors.has('services')">{{ errors.first('services')}}</p> -->
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate';


export default {
    name: "officeinfo",
    props: {
        form: {
            type: Object
        }
    },
    data() {
        return {
            services: [],
            offices: [],
            languages: [
                { id: 1, language: "English" },
                { id: 2, language: "Chinese" },
                { id: 3, language: "Frensh" }
            ],
            professions: [],
            value: [],
            options: [
                { name: 'Vue.js', language: 'JavaScript' },
                { name: 'Rails', language: 'Ruby' },
                { name: 'Sinatra', language: 'Ruby' },
                { name: 'Laravel', language: 'PHP' },
                { name: 'Phoenix', language: 'Elixir' }
            ],
            isLoading: false
        };
    },
    mounted() {
        this.$http.get(process.env.VUE_APP_API_URL + "/services?limit=100").then(response => {
            const sorted = this.$_.orderBy(response.data.data, ['title'], ['asc']);
            this.services = sorted;
        }, error => {
            console.log("error", error.response);
        });
        this.$http.get(process.env.VUE_APP_API_URL + "/professionalcategories?limit=100").then(response => {
            const sorted = this.$_.orderBy(response.data.data, ['title'], ['asc']);
            this.professions = sorted;
        }, error => {
            console.log("error", error.response);
        });
    },
    methods: {
        async validation() {
            let isValid = await this.$refs.form.validate();
            this.$emit("on-validation", this.$props.form, isValid);
            return isValid;
        },
        searchOffices(searchQuery) {
            this.$http.get('/offices/search/selection?q=' + searchQuery)
                .then((response) => {
                this.offices = [];
                this.offices = response.data;
            });
        }
    },
    components: { ValidationObserver }
};
</script>
<style scoped>
p {
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
}

input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #e9e9f0;
  width: 100%;
  background-color: transparent;
  font-family: Poppins;
  margin: 0;
}

input:focus {
  border-color: black;
}

.btn-signup {
  color: #ffffff;
  background-color: #0fbc9c;
  border-color: #0fbc9c;
  font-weight: bold;
  border-radius: 1px;
  width: 100%;
}

.btn-signup:hover,
.btn-signup:active,
.btn-signup:focus,
.btn-signup.active {
  background: #169e85;
  color: #ffffff;
  border-color: #0fbc9c;
}

select {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #e9e9f0;
  width: 100%;
  background-color: transparent;
  font-family: Poppins;
  margin: 0.5rem 0;
}

select:focus {
  border-color: black;
}

.btn-wrap {
  padding-bottom: 30px;
}

.errors {
  text-align: left;
  color: red;
  font-weight: 400;
  font-size: 12px;
}

@media only screen and (max-width: 480px) {
  form input,
  form select {
    font-size: 12px !important;
  }

  form input::placeholder {
    font-size: 12px !important;
  }
}
</style>
