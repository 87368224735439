<template>
    <div class="section notifications">
        <div class="view">
            <b-container v-if="categories">
                <b-row>
                    <b-col xl="9" lg="9" md="9" sm="12">
                        <h3 class="mb-5 text-left title">{{ title }}</h3>
                    </b-col>
                    <!--                    <b-col v-if="pageType === 'main'">-->
                    <!--                        <button v-on:click="$router.push('/notifications/new/template').catch(err => {})"-->
                    <!--                                class="btn btn-success btn-block"><i class="fa fa-plus-circle"/> {{-->
                    <!--                            $t('notifications.addtemplate') }}-->
                    <!--                        </button>-->
                    <!--                    </b-col>-->
                </b-row>
                <b-card class="card-shadow main" v-loading="loading">
                    <b-tabs pills card start class="custom" v-if="pageType === 'main'">
                        <b-tab :title="$t('notification.text.default')" active>
                            <b-card-text>
                                <b-breadcrumb>
                                    <b-breadcrumb-item active>{{ $t('notification.text.categories') }}</b-breadcrumb-item>
                                </b-breadcrumb>
                                <hr>

                                <div class="list">
                                    <ul class="list-unstyled" v-if="result">
                                        <li v-for="category in defaultCategories" :key="category.id">
                                    <span>
                                        <router-link v-on:click="emailType = 'default'"
                                                     :to="'/notifications/category/' + '?id=' + category.id + '&type=default'">{{ category.title }}</router-link>

                                        <span class="i text-right">
                                            <i class="fa fa-caret-right"/>
                                        </span>
                                    </span>
                                        </li>
                                    </ul>
                                    <p class="text-center pt-3 pb-3" v-else>{{ message }}</p>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('notification.text.custom')">
                            <b-card-text>
                                <b-breadcrumb>
                                    <b-breadcrumb-item active>{{ $t('notification.text.categories') }}</b-breadcrumb-item>
                                </b-breadcrumb>
                                <hr>

                                <div class="list">
                                    <ul class="list-unstyled" v-if="result">
                                        <li v-for="category in customCategories" :key="category.id">
                                    <span>
                                        <router-link
                                                :to="'/notifications/category/' + '?id=' + category.id + '&type=custom'">{{ category.title }}</router-link>

                                        <span class="i text-right">
                                            <i class="fa fa-edit pr-1" v-b-modal.category
                                               v-on:click="setCategory(category.id, 'main', 'update')"/>
                                            <i class="fa fa-trash pr-1" v-b-modal.category-delete
                                               v-on:click="setCategory(category.id, 'main', 'delete')"/>
                                        </span>
                                    </span>
                                        </li>
                                    </ul>
                                    <p class="text-center pt-3 pb-3" v-else>{{ message }}</p>
                                </div>

                                <div v-if="pageType !== 'templates'">
                                    <!-- create or update modal -->
                                    <b-button v-b-modal.category class="btn btn-success"
                                              v-on:click="submitType = 'new'"><i class="fa fa-plus-circle"/>
                                        {{ $t('notification.text.add_new_category') }}
                                    </b-button>
                                    <b-modal
                                            id="category"
                                            ref="modal"
                                            :title="submitType === 'new' ? $t('notification.text.new_category') : $t('notification.text.update_category')"
                                            @show="resetModal"
                                            @hidden="resetModal"
                                            @ok="handleOk"
                                    >
                                        <form ref="form" @submit.stop.prevent="handleSubmit">
                                            <b-form-group
                                                    :state="categoryTitleState"
                                                    :label="$t('notification.text.category_title')"
                                                    label-for="name-input"
                                                    :invalid-feedback="$t('notification.text.category_title_required')"
                                            >
                                                <b-form-input
                                                        id="name-input"
                                                        v-model="category_form.title"
                                                        :state="categoryTitleState"
                                                        required
                                                ></b-form-input>
                                            </b-form-group>
                                        </form>
                                        <template v-slot:modal-footer="{ ok }">
                                            <!-- Emulate built in modal footer ok and cancel button actions -->
                                            <b-button size="sm" variant="success" @click="ok()"
                                                      v-if="submitType === 'new'">
                                                {{ $t('notification.text.save') }}
                                            </b-button>
                                            <b-button size="sm" variant="success" @click="ok()" v-else>
                                                {{ $t('notification.text.update') }}
                                            </b-button>
                                        </template>
                                    </b-modal>

                                    <!-- delete modal -->
                                    <b-modal
                                            id="category-delete"
                                            ref="category-delete"
                                            :title="$t('notification.text.delete_category')"
                                    >
                                        <h6>{{ $t('notification.text.delete_confirm') }}</h6>
                                        <template v-slot:modal-footer="{ ok, cancel }">
                                            <!-- Emulate built in modal footer ok and cancel button actions -->
                                            <b-button size="sm" variant="info" @click="cancel()">
                                                {{ $t('notification.text.cancel') }}
                                            </b-button>
                                            <b-button size="sm" variant="danger" @click="deleteCategory">
                                                {{ $t('notification.text.confirm') }}
                                            </b-button>
                                        </template>
                                    </b-modal>
                                </div>
                                <div v-else>
                                    <button v-on:click="$router.push('/notifications/new/template').catch(err => {})"
                                            class="btn btn-success"><i class="fa fa-plus-circle"/> {{
                                        $t('notifications.addtemplate') }}
                                    </button>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>

                    <div v-else>
                        <b-breadcrumb v-if="pageType === 'sub'">
                            <b-breadcrumb-item to="/notifications">{{ $t('notification.text.categories') }}</b-breadcrumb-item>
                            <b-breadcrumb-item active>{{ $t('notification.text.sub_categories') }}</b-breadcrumb-item>
                        </b-breadcrumb>
                        <b-breadcrumb v-if="pageType === 'templates'">
                            <b-breadcrumb-item to="/notifications">{{ $t('notification.text.categories') }}</b-breadcrumb-item>
                            <b-breadcrumb-item
                                    :to="'/notifications/category/'+ '?id=' + $route.query['main_id'] + '&type=' + emailType">
                                {{ $t('notification.text.sub_categories') }}
                            </b-breadcrumb-item>
                            <b-breadcrumb-item active>{{ $t('notification.text.email_templates') }}</b-breadcrumb-item>
                        </b-breadcrumb>
                        <hr>
                        <!--                        <notification-item class="list" v-if="pageType === 'sub'"-->
                        <!--                                           :categories="filteredCategories"-->
                        <!--                                           :categoryType="'sub'" :result="result" :main_id="main_id"/>-->
                        <div class="list" v-if="pageType === 'sub'">
                            <ul class="list-unstyled" v-if="result">
                                <li v-for="category in filteredCategories" :key="category.id">
                                    <span>
                                        <router-link
                                                :to="'/notifications/templates/' + '?type=' + emailType + '&main_id=' + $route.query['id'] + '&sub_id=' + category.id">
                                            {{category.title}}
                                        </router-link>
                                        <span class="i text-right" v-if="emailType === 'custom'">
                                            <i class="fa fa-edit pr-1" v-b-modal.category
                                               v-on:click="setCategory(category.id, 'update')"/>
                                            <i class="fa fa-trash pr-1" v-b-modal.category-delete
                                               v-on:click="setCategory(category.id, 'main', 'delete')"/>
                                        </span>
                                        <span class="i text-right" v-else>
                                        <i class="fa fa-caret-right"/>
                                        </span>

                                    </span>
                                </li>
                            </ul>
                            <p class="text-center pt-3 pb-3" v-else>{{ message }}</p>
                        </div>
                        <div class="list" v-if="pageType === 'templates'">
                            <ul class="list-unstyled" v-if="result">
                                <li v-for="template in filteredTemplates" :key="template.id">
                                    <span>
                                        <router-link
                                                :to="'/notifications/send/template/' + '?type=' + emailType + '&main_id=' + $route.query['main_id'] + '&sub_id=' + $route.query['sub_id'] + '&email=' + template.id">
                                            {{template.title}}
                                        </router-link>

                                        <span class="i text-right">
                                            <i class="fa fa-caret-right"/>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <p class="text-center pt-3 pb-3" v-else>{{ message }}</p>
                        </div>

                        <div v-if="pageType !== 'templates' && emailType === 'custom'">
                            <b-button v-b-modal.category class="btn btn-success" v-on:click="submitType = 'new'"><i
                                    class="fa fa-plus-circle"/>
                                {{ $t('notification.text.add_new_category') }}
                            </b-button>
                            <b-modal
                                    id="category"
                                    ref="modal"
                                    :title="submitType === 'new' ? $t('notification.text.new_category') : $t('notification.text.update_category')"
                                    @show="resetModal"
                                    @hidden="resetModal"
                                    @ok="handleOk"
                            >
                                <form ref="form" @submit.stop.prevent="handleSubmit">
                                    <b-form-group
                                            :state="categoryTitleState"
                                            :label="$t('notification.text.category_title')"
                                            label-for="name-input"
                                            :invalid-feedback="$t('notification.text.category_title_required')"
                                    >
                                        <b-form-input
                                                id="name-input"
                                                v-model="category_form.title"
                                                :state="categoryTitleState"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </form>
                                <template v-slot:modal-footer="{ ok }">
                                    <!-- Emulate built in modal footer ok and cancel button actions -->
                                    <b-button size="sm" variant="success" @click="ok()"
                                              v-if="submitType === 'new'">
                                        {{ $t('notification.text.save') }}
                                    </b-button>
                                    <b-button size="sm" variant="success" @click="ok()" v-else>
                                        {{ $t('notification.text.update') }}
                                    </b-button>
                                </template>
                            </b-modal>

                            <!-- delete modal -->
                            <b-modal
                                    id="category-delete"
                                    ref="category-delete"
                                    :title="$t('notification.text.delete_category')"
                            >
                                <h6>{{ $t('notification.text.delete_confirm') }}</h6>
                                <template v-slot:modal-footer="{ ok, cancel }">
                                    <!-- Emulate built in modal footer ok and cancel button actions -->
                                    <b-button size="sm" variant="info" @click="cancel()">
                                        {{ $t('notification.text.cancel') }}
                                    </b-button>
                                    <b-button size="sm" variant="danger" @click="deleteCategory">
                                        {{ $t('notification.text.confirm') }}
                                    </b-button>
                                </template>
                            </b-modal>
                        </div>
                        <div v-if="pageType === 'templates' && emailType === 'custom'">
                            <button v-on:click="$router.push('/notifications/new/template?'+ '&type=' + emailType + '&main_id=' + $route.query['main_id'] + '&sub_id=' + $route.query['sub_id']).catch(err => {})"
                                    class="btn btn-success"><i class="fa fa-plus-circle"/> {{
                                $t('notifications.addtemplate') }}
                            </button>
                        </div>
                    </div>
                </b-card>
            </b-container>

            <b-container v-if="newNotification">
                <h3 class="mb-5 text-left title">{{ title }}</h3>
                <b-breadcrumb>
                    <b-breadcrumb-item to="/notifications">{{ $t('notification.text.categories') }}</b-breadcrumb-item>
                    <b-breadcrumb-item
                            :to="'/notifications/category/'+ '?id=' + $route.query['main_id'] + '&type=' + emailType">
                        {{ $t('notification.text.sub_categories') }}
                    </b-breadcrumb-item>
                    <b-breadcrumb-item
                            :to="'/notifications/templates/'+ '?type=' + emailType + '&main_id=' + $route.query['main_id'] + '&sub_id=' + $route.query['sub_id']">
                        {{ $t('notification.text.email_templates') }}
                    </b-breadcrumb-item>
                    <b-breadcrumb-item active>{{ $t('notification.text.create_new_template') }}</b-breadcrumb-item>
                </b-breadcrumb>
                <b-card class="card-shadow main">
                    <notification-template :form="form"/>
                </b-card>
            </b-container>

            <b-container v-if="sendNotification">
                <b-breadcrumb>
                    <b-breadcrumb-item to="/notifications">{{ $t('notification.text.categories') }}</b-breadcrumb-item>
                    <b-breadcrumb-item
                            :to="'/notifications/category/'+ '?id=' + $route.query['main_id'] + '&type=' + emailType">
                        {{ $t('notification.text.sub_categories') }}
                    </b-breadcrumb-item>
                    <b-breadcrumb-item
                            :to="'/notifications/templates/'+ '?type=' + emailType + '&main_id=' + $route.query['main_id'] + '&sub_id=' + $route.query['sub_id']">
                        {{ $t('notification.text.email_templates') }}
                    </b-breadcrumb-item>
                    <b-breadcrumb-item active>{{ $t('notification.text.edit_or_send_template') }}</b-breadcrumb-item>
                </b-breadcrumb>
                <hr>
                <b-card no-body class="template">
                    <b-tabs pills card start v-if="emailType === 'custom'">
                        <b-tab :title="$t('notifications.edittemplatetitle')" active>
                            <b-card-text>
                                <h3 class="mb-5 text-left title">{{ $t('notifications.edittemplatetitle') }}</h3>
                                <b-card class="card-shadow main">
                                    <notification-template :email_templates="true"/>
                                </b-card>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('notifications.sendnotification')">
                            <b-card-text>
                                <h3 class="mb-5 text-left title">{{ $t('notifications.sendnotification') }}</h3>
                                <b-card class="card-shadow main">
                                    <notification-send/>
                                </b-card>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                    <div v-else>
                        <b-card-text>
                            <h3 class="mb-5 text-left title">{{ $t('notifications.sendnotification') }}</h3>
                            <b-card class="card-shadow main">
                                <notification-send/>
                            </b-card>
                        </b-card-text>
                    </div>
                </b-card>
            </b-container>
        </div>
    </div>
</template>

<script>
    import NotificationTemplate from "../components/Notifications/Template";
    import sendTemplate from "../components/Notifications/SendTemplate";

    export default {
        name: "Notifications",
        components: {
            'notification-template': NotificationTemplate,
            'notification-send': sendTemplate
        },
        data: () => {
            return {
                title: 'Notification Templates',
                message: "No Data Available at the moment",
                pageType: '',
                categories: true,
                newNotification: false,
                editNotification: false,
                sendNotification: false,
                loading: false,
                result: false,
                defaultCategories: [],
                customCategories: [],
                filteredCategories: [],
                customFilteredCategories: [],
                filteredTemplates: [],
                customFilteredTemplates: [],
                main_id: '',
                form: {
                    patient_name: '',
                    title: '',
                    content: '',
                    categories: {
                        selected: null,
                        options: []
                    }
                },
                category_form: {
                    title: ''
                },
                categoryTitleState: null,
                submitType: 'new',
                sub_category_id: '',
                category_id: '',
                emailType: 'default',
                delete_id: ''
            }
        },
        created() {
            this.routeMethods();
            this.message = this.$t('notification.text.no_data')
        },
        mounted() {
            this.message = this.$t('notification.text.no_data')
            this.title = this.$t('notifications.index');
        },
        watch: {
            $route: function () {
                this.routeMethods();
            }
        },
        methods: {
            routeMethods() {
                // title page
                if (this.$route.name === 'notification.title') {
                    this.titlePage();
                }

                // templates page
                if (this.$route.name === 'notification.templates') {
                    this.templatePage();
                }

                // new page
                else if (this.$route.name === 'notification.new') {
                    this.newPage();
                }

                // edit page
                else if (this.$route.name === 'notification.edit') {
                    this.editPage();
                }

                // send page
                else if (this.$route.name === 'notification.send') {
                    this.sendPage();
                }

                // index page
                else if (this.$route.name === 'notification.index') {
                    this.indexPage();
                }
            },
            indexPage() {
                this.pageType = 'main';
                this.categories = true;
                this.editNotification = false;
                this.newNotification = false;
                this.sendNotification = false;

                this.getCategories();
                this.getCustomCategories();
            },
            titlePage() {
                this.pageType = 'sub';
                this.categories = true;
                this.editNotification = false;
                this.newNotification = false;
                this.sendNotification = false;

                this.title = this.$t('notifications.index');
                this.emailType = this.$route.query['type'];
                this.getCategories();
                this.getCustomCategories();
            },
            templatePage() {
                this.pageType = 'templates';
                this.categories = true;
                this.editNotification = false;
                this.newNotification = false;
                this.sendNotification = false;

                this.emailType = this.$route.query['type'];
                if (this.emailType === 'default') {
                    this.getEmailTemplates()
                } else {
                    this.getEmailCustomTemplates()
                }
            },
            editPage() {
                // this.pageType = false;
                this.categories = false;
                this.editNotification = true;
                this.newNotification = false;
                this.sendNotification = false;

                this.emailType = this.$route.query['type'];
                // setting the page title
                this.title = this.$t('notifications.edittemplatetitle');
            },
            newPage() {
                // this.pageType = false;
                this.categories = false;
                this.editNotification = false;
                this.newNotification = true;
                this.sendNotification = false;

                this.emailType = this.$route.query['type'];
                // setting the page title
                this.title = this.$t('notifications.newtemplatetitle');
            },
            sendPage() {
                // this.pageType = false;
                this.categories = false;
                this.editNotification = false;
                this.newNotification = false;
                this.sendNotification = true;

                this.emailType = this.$route.query['type'];
                // setting the page title
                this.title = this.$t('notifications.sendnotification');
            },
            getCategories() {
                this.loading = true;

                if (this.pageType === 'main') {
                    this.$http.get('/notifcationcategories')
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                this.result = true;
                                response.data.data.forEach(notification => {
                                    if (typeof this.$route.query['id'] !== 'undefined') {
                                        if (notification.id === this.$route.query['id']) {
                                            this.title = notification.title;
                                            this.main_id = notification.id;
                                            this.defaultCategories = notification.sub_categories
                                            // console.log(notification.sub_categories.length)
                                            if (notification.sub_categories.length > 0) {
                                                this.result = true;
                                            } else {
                                                this.result = false;
                                                if (this.pageType === 'main') {
                                                    this.defaultCategories = response.data.data;
                                                } else if (this.pageType === 'sub') {
                                                    this.defaultCategories = [];
                                                }
                                            }
                                        }
                                    } else {
                                        this.defaultCategories = response.data.data;
                                    }
                                });
                            } else {
                                this.result = false;
                            }

                            setTimeout(() => {
                                this.loading = false
                            }, 1000)
                        })
                } else {
                    this.$http.get('/notifcationcategories')
                        .then((response) => {
                            // console.log("response", response)
                            if (response.data.data.length > 0) {
                                this.result = true;
                                response.data.data.forEach(notification => {
                                    if (typeof this.$route.query['id'] !== 'undefined') {
                                        if (notification.id === this.$route.query['id']) {
                                            this.title = notification.title;
                                            this.main_id = notification.id;
                                            this.filteredCategories = notification.sub_categories
                                            // console.log(notification.sub_categories.length)
                                            if (notification.sub_categories.length > 0) {
                                                this.result = true;
                                            } else {
                                                this.result = false;
                                                if (this.pageType === 'main') {
                                                    this.filteredCategories = response.data.data;
                                                } else if (this.pageType === 'sub') {
                                                    this.filteredCategories = [];
                                                }
                                            }
                                        }
                                    } else {
                                        this.filteredCategories = response.data.data;
                                    }
                                });
                            } else {
                                this.result = false;
                            }

                            setTimeout(() => {
                                this.loading = false
                            }, 1000)
                        })
                }

            },
            getCustomCategories() {
                this.loading = true;
                if (this.pageType === 'main') {
                    this.$http.get('/notifcationcategories/custom')
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                this.customFilteredCategories = response.data.data
                                this.result = true;
                                response.data.data.forEach(notification => {
                                    if (typeof this.$route.query['id'] !== 'undefined') {
                                        if (notification.id === this.$route.query['id']) {
                                            this.title = notification.title;
                                            this.main_id = notification.id;
                                            this.customCategories = notification.sub_categories
                                            // console.log(notification.sub_categories.length)
                                            this.result = notification.sub_categories.length > 0;
                                        }
                                    } else {
                                        this.customCategories = response.data.data
                                    }
                                });
                            } else {
                                this.result = false
                            }

                            setTimeout(() => {
                                this.loading = false
                            }, 1000)
                        })
                } else {
                    this.$http.get('/notifcationcategories/custom')
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                this.customFilteredCategories = response.data.data
                                this.result = true;
                                response.data.data.forEach(notification => {
                                    if (typeof this.$route.query['id'] !== 'undefined') {
                                        if (notification.id === this.$route.query['id']) {
                                            this.title = notification.title;
                                            this.main_id = notification.id;
                                            this.filteredCategories = notification.sub_categories
                                            // console.log(notification.sub_categories.length)
                                            this.result = notification.sub_categories.length > 0;
                                        }
                                    } else {
                                        this.filteredCategories = response.data.data
                                    }
                                });
                            } else {
                                this.result = false
                            }

                            setTimeout(() => {
                                this.loading = false
                            }, 1000)
                        })
                }
            },
            getEmailTemplates() {
                this.loading = true;
                this.$http.get('/emailtemplates')
                    .then((response) => {
                        if (response.data.data.length > 0) {
                            this.result = true;
                            // email templates
                            this.filteredTemplates = this.$_.filter(response.data.data, {
                                'category_id': this.$route.query['main_id'],
                                'sub_category_id': this.$route.query['sub_id']
                            });
                        }
                        else{
                            this.result = false;
                        }

                        setTimeout(() => {
                            this.loading = false
                        }, 1000)
                    });

                // this.form.category_id = this.form.categories.selected.id;
                // this.form.sub_category_id = this.form.subCategories.selected.id;
                // this.$http.get('/customtemplates', this.form)
                //     .then((response) => {
                //         if (response.data.data.length > 0) {
                //             // custom email templates
                //             this.form.templates[1].options = this.$_.filter(response.data.data, {
                //                 'category_id': mainCategory,
                //                 'sub_category_id': subCategory
                //             });
                //         }
                //     });

            },
            getEmailCustomTemplates() {
                this.loading = true;
                this.$http.get('/customtemplates')
                    .then((response) => {
                        if (response.data.data.length > 0) {
                            this.result = true;
                            // email templates
                            this.filteredTemplates = this.$_.filter(response.data.data, {
                                'category_id': this.$route.query['main_id'],
                                'sub_category_id': this.$route.query['sub_id']
                            });
                        } else {
                            this.result = false
                        }

                        setTimeout(() => {
                            this.loading = false
                        }, 1000)
                    });

                // this.form.category_id = this.form.categories.selected.id;
                // this.form.sub_category_id = this.form.subCategories.selected.id;
                // this.$http.get('/customtemplates', this.form)
                //     .then((response) => {
                //         if (response.data.data.length > 0) {
                //             // custom email templates
                //             this.form.templates[1].options = this.$_.filter(response.data.data, {
                //                 'category_id': mainCategory,
                //                 'sub_category_id': subCategory
                //             });
                //         }
                //     });

            },
            setCategory(category_id, submit_type, mode) {
                this.submitType = submit_type;
                this.delete_id = category_id;
                this.category_id = category_id;
                this.sub_category_id = category_id;

                if (mode !== 'delete') {
                    if (this.pageType === 'main') {
                        this.$http.get('/notifcationcategories/' + category_id)
                            .then((response) => {
                                this.category_form.title = response.data.data.title;
                            })
                    } else {
                        this.$http.get('/notifcationsubcategories/' + category_id)
                            .then((response) => {
                                this.category_form.title = response.data.data.title;
                                this.sub_category_id = category_id
                            })
                    }
                }
            },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.categoryTitleState = valid
                return valid
            },
            resetModal() {
                this.category_form.title = ''
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return
                }
                // Push the name to submitted names
                // this.submittedNames.push(this.name)
                // Hide the modal manually
                if (this.pageType === 'main') {
                    this.$http.post('/notifcationcategories/custom', {
                        id: this.category_id,
                        title: this.category_form.title,
                    }).then(() => {
                        this.$refs['modal'].hide();
                        if (this.submitType === 'new') {
                            this.$message.success('Category successfully created!');
                        } else {
                            this.$message.success('Category successfully updated!');
                        }
                        this.getCustomCategories();
                    })
                } else if (this.pageType === 'sub') {
                    this.$http.post('/notifcationsubcategories/custom', {
                        id: this.sub_category_id,
                        title: this.category_form.title,
                        category_id: this.$route.query['id']
                    }).then(() => {
                        this.$refs['modal'].hide();
                        if (this.submitType === 'new') {
                            this.$message.success('Category successfully created!');
                        } else {
                            this.$message.success('Category successfully updated!');
                        }
                        this.getCustomCategories();
                    })
                }
            },
            deleteCategory() {
                if (this.pageType === 'main') {
                    this.$http.delete('/notifcationcategories/' + this.delete_id)
                        .then(() => {
                            this.$refs['category-delete'].hide();
                            this.$message.success('Category successfully deleted!');
                            this.getCustomCategories();
                        })
                } else if (this.pageType === 'sub') {
                    this.$http.delete('/notifcationsubcategories/' + this.delete_id)
                        .then(() => {
                            this.$refs['category-delete'].hide();
                            this.$message.success('Category successfully deleted!');
                            this.getCustomCategories();
                        })
                }
            }
        }
    }
</script>