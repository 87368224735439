<template>
  <div class="section office" v-loading="loading" v-if="!$_.isEmpty(office)">
    <div class="view">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3">
            <div class="sidebar">
              <div class="img-container">
                <img class="img-fluid" :src="office.thumbnail"/>
              </div>
              <span v-if="claim_avaiable">
                <div class="btn-set mt-3" v-if="!office.professional_id">
                  <b-button :disabled="office.claimed" v-b-modal.modal-1 class="mt-3 btn-block btn-default location">
                    <span v-if="!office.claimed">{{$t('office.claimoffice')}}</span>
                    <span v-else>{{$t('office.claimofficepending')}}</span>
                  </b-button>
                </div>
              </span>
              <working-hours :working_hours="office.working_days"></working-hours>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9">
            <div class="details">
              <div class="row">
                <div class="col-xl-9 col-lg-9">
                  <h2 v-if="lang === 'en'">{{ office.name }}</h2>
                  <h2 v-else>{{ office.multi_language_office ? office.multi_language_office[0].name : office.name }}</h2>
                  <h6>{{ office.professionals.length }} {{$t('professionals.index')}} {{$t('professionals.associated')}}</h6>
                  <table>
                    <tr>
                      <td>
                        <i class="fa fa-map-marker"></i>
                      </td>
                      <td>{{ office.address }}</td>
                    </tr>
                    <tr v-if="office.languages">
                      <td>
                        <i class="fa fa-globe"></i>
                      </td>
                      <td>
                        <span v-for="(language, index) in office.languages" :key="'lang-'+language">
                          {{ language }}<span v-if="index !== office.languages.length - 1">, </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="lang !== 'en' && office.multi_language_office.length">
                      <td v-if="office.multi_language_office[0].phone_number">
                        <i class="fa fa-phone"></i>
                      </td>
                      <td>{{ office.multi_language_office[0].phone_number }}</td>

                    </tr>
                    <tr v-else>
                      <td v-if="office.phone_number">
                        <i class="fa fa-phone"></i>
                      </td>
                      <td>{{ office.phone_number }}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-3 col-lg-3">
                  <div class="ratings text-center">
                    <span v-on:click="$router.push('/dr-profile-review?type=office&office_id='+office.id).catch(err => {})">
                      <h3>
                        {{ office.rating }}
                        <span>/5</span>
                      </h3>
                      <span class="stars">
                        <i v-for="n in 5"
                           :key="'stars'+n"
                           :class="{'active fa-star' : office.rating >= n,'fa-star-o': office.rating < n}"
                           class="fa "/>
                        <br>
                      </span>
                      <sub>{{ office.reviews.length }} {{$t('professional.reviews')}}</sub>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="overview" v-if="office && office.bio && lang === 'en'">
              <h3>{{ $t('officeprofile.overview') }}</h3>
              <p class="text-justify">
                <span>
                  {{ office.bio }}
                </span>
              </p>
            </div>
            <div class="overview"
                 v-if="lang !== 'en' && (office && office.multi_language_office.length && office.multi_language_office[0].bio)">
              <h3>{{ $t('officeprofile.overview') }}</h3>
              <p class="text-justify">
                <span>
                  {{ office.multi_language_office[0].bio }}
                </span>
              </p>
            </div>
            <professionals v-if="office.professionals"
                           :clickablecard="true"
                           :lang="lang"
                           :professionals="office.professionals"></professionals>
            <services :services="office.services" :lang="lang"></services>
            <photos :photos="office.photos"></photos>
          </div>
        </div>
        <b-modal id="modal-1">
          <template v-slot:modal-header>
            <h5 class="text-center">{{$t('office.claimoffice')}}</h5>
          </template>
          <template v-slot:modal-footer="{ cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="onClick">
              Submit
            </b-button>
          </template>
          <form class="claim-form">
            <div class="alert alert-danger" role="alert" :key="'errors'+index" v-for="(error,index) in response_errors">
              {{ error[0] }}
            </div>
            <h6 class="text-center pt-1 pb-2">To Claim this Office please provide below details</h6>
            <div class="form-group">
              <label for="proof_1">Identity Proof 1</label>
              <b-form-file
                  id="proof_1"
                  v-model="form.proof_1"
                  ref="proof_1"
                  @change="handlerProof1"
                  placeholder="Choose a file or drop here..."
                  drop-placeholder="Drop file here..."
              />
            </div>
            <div class="form-group">
              <label for="proof_2">Identity Proof 2 <small>(Optional)</small></label>
              <b-form-file
                  id="proof_2"
                  name="proof_2"
                  ref="proof_2"
                  v-model="form.proof_2"
                  placeholder="Choose a file or drop here..."
                  drop-placeholder="Drop file here..."
              />
            </div>
            <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors?.has('file')">The field is
              required</p>
            <div class="form-group">
              <label for="email">Work Email</label>
              <input name="email" v-model="form.work_email" v-validate="{required:true}" id="email"
                     type="email" class="form-control"
                     placeholder="Work email address">
              <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors?.has('work_email')">
                {{ errors.first('work_email') }}</p>
            </div>
            <div class="form-group">
              <label for="number">Work Phone Number</label>
              <input name="number" v-model="form.work_phone" v-validate="{required:true}" id="number"
                     type="text"
                     class="form-control"
                     placeholder="Work's Main reception phone number">
              <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors?.has('number')">The field
                is required</p>
            </div>
            <div class="form-group">
              <label for="number">Contact Person Name</label>
              <input name="number" v-model="form.contact_person" v-validate="{required:true}" id="contact_person"
                     type="text"
                     class="form-control"
                     placeholder="Full name">
              <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors?.has('contact_person')">The field
                is required</p>
            </div>
            <div class="form-group">
              <label for="contact_time">Time</label>
              <input name="contact_time"
                     id="contact_time"
                     v-model="form.contact_time"
                     v-validate="{required:true}"
                     type="text"
                     class="form-control"
                     placeholder="Thu Aug 12,2019 between 3 PM and 5 PM ">
              <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors?.has('contact_time')">The field
                is required</p>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import WorkingHours from '../components/OfficeProfile/WorkingHours'
import Professionals from '../components/OfficeProfile/Professionals'
import Services from '../components/OfficeProfile/Services'
import Photos from '../components/OfficeProfile/Photos'
import i18n from '../lang'

import _ from 'lodash'
import { EventBus } from '../event-bus'

export default {
  name      : 'officeprofile',
  components: {
    'working-hours': WorkingHours,
    professionals  : Professionals,
    services       : Services,
    photos         : Photos,
  },
  data      : function () {
    return {
      form                 : {
        file           : null,
        email          : '',
        phone_number   : '',
        contact_time   : '',
        proof_1        : null,
        proof_2        : null,
        claim_type     : 'office',
        model_id       : '',
        professional_id: '',
        contact_person : '',
      },
      state                : {
        proof_1: null,
        proof_2: null,
      },
      office               : {},
      working_hours        : [],
      professionals_details: [],
      response_errors      : {},
      errors               : new Set(),
      lang                 : this.$route.query.lang ? this.$route.query.lang : i18n.locale,
      claim_avaiable       : false,
    }
  },
  computed  : {
    user: function () {
      return this.$store.getters.user
    },
  },
  mounted() {


    this.getOffice()

    EventBus.$on('langChanged', () => {
      this.loading = true
      this.lang    = i18n.locale
      this.getOffice()
    })
  },
  methods: {
    getOffice() {


      let loading = this.$loading('loading...')
      this.$http.get('/offices/' + this.$route.query.office_id + '?lang=' + this.lang)
          .then((response) => {
            this.office = response.data.data
            setTimeout(() => {
              this.form.model_id = this.office.id
            }, 1000)

            setTimeout(() => {
              if (!_.isEmpty(this.user)) {
                if (!_.isEmpty(this.user.professional)) {
                  this.form.professional_id = this.user.professional.id
                  this.claim_avaiable       = true
                }

              }
            }, 2000)

            loading.close()
            this.loading = false
          })
    },
    onClick() {
      const formData = new FormData()
      this.$_.forEach(this.form, (val, key) => {
        formData.append(key, val)
      })
      this.$validator.validate()
          .then(() => {
            if (!this.errors.any()) {
              // this.$refs['modal-1'].hide()
              this.$http.post('/claims', formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  .then(() => {
                    this.$bvModal.hide('modal-1')
                    this.office.claimed = true
                    this.$message.success('your claim has been submited.! we will contact you back soon.')
                  })
                  .catch((errors) => {
                    this.response_errors = errors.response.data.errors
                  })
            }
          })


    },
    handlerProof1() {
      this.form.proof_1 = this.$refs.proof_1.files[0]
    },
  },
}
</script>
<style scoped>
.fa.fa-star {
  font-size: 25px;
}

.fa.fa-star.active {
  color: #FDCC28;
}

#modal-1 .modal-footer {
  display: none !important;
}

.claim-form {
  font-family: "Poppins";
  font-size: 13px;
}

.claim-form h6 {
  font-size: 15px;
  font-weight: 500;
}

.claim-form label {
  font-size: 14px;
  font-weight: 600;
}

.claim-form .form-control {
  border: none;
  border-bottom: 1px solid #3a3a3a;
  border-radius: 0;
  padding-left: 0;
  font-size: 13px;
}

.claim-form .form-control:focus {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #14705f;
}

.claim-form .form-control::placeholder {
  font-size: 13px;
}

.claim-form .custom-file-label {
  border-radius: 0 !important;
}

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  /*border-color: #3a3a3a !important;*/
  border-radius: 0 !important;
}
</style>
