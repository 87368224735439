import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'

import i18n from './lang'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'

Vue.prototype.moment = moment
import App from './App.vue'
import {sync} from 'vuex-router-sync';
import "./bootstrap/libraries";
import "./bootstrap/plugins";

import VueLazyload from 'vue-lazyload'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as VeeValidate from 'vee-validate';
import { extend } from "vee-validate";
import { required, email, integer, between, min, confirmed } from 'vee-validate/dist/rules';
import httpPlugin from './plugins/http';
import VuePluralize from 'vue-pluralize'
import Multiselect from 'vue-multiselect'

import vueDebounce from 'vue-debounce'

Vue.use(vueDebounce)

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

require("@fancyapps/fancybox");
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });

// Install BootstrapVue
Vue.use(BootstrapVue)

Vue.use(VueLazyload)
extend('required', required)
extend('email', email)
extend('integer', integer)
extend('between', between)
extend('min', min)
extend('confirmed', confirmed)
Vue.use(VeeValidate);
Vue.use(httpPlugin,{store, router})
Vue.use(VuePluralize)
Vue.component('multiselect', Multiselect)

// Element UI
import {Message,Loading} from 'element-ui';
import './assets/styles/element.css';
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.use(Loading.directive);

// Effortlessly keep vue-router and vuex store in sync.
sync(store, router); // https://github.com/vuejs/vuex-router-sync/tree/next

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')