<template>
    <div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" v-for="category  in local_categories" v-bind:key="category.id">
                <div class="category">
                    <router-link v-if="lang.locale === 'en'" :to="'/search?q='+category.title">
                        <div class="img-container">
                            <img :src="category.thumbnail" alt/>
                        </div>
                        <div class="text-wrap">

                            <h2><a href="#">{{ category.title}} </a></h2>

                        </div>
                    </router-link>
                    <span v-else >
                        <span  v-if="categoryType === 'services'">
                            <router-link v-if="!category.multi_language_service" :to="'/search?q='+category.title">
                                <div class="img-container">
                                    <img :src="category.thumbnail" alt/>
                                </div>
                                <div class="text-wrap">

                                    <h2><a href="#">{{ category.title}} </a></h2>

                                </div>
                            </router-link>
                            <span v-else>
                                <span v-if="category.multi_language_service.length">
                                    <router-link :to="'/search?q='+category.multi_language_service[0].title">
                                        <div class="img-container">
                                            <img :src="category.thumbnail" alt/>
                                        </div>
                                        <div class="text-wrap">
                                            <h2  v-if="category.multi_language_service">
                                                <span v-if="category.multi_language_service.length">
                                                    <a href="#">{{ category.multi_language_service[0].title}} </a>
                                                </span>
                                                <a href="#" v-else>{{ category.title}} </a>
                                            </h2>
                                            <h2  v-else><a href="#">{{ category.title}} </a></h2>
                                        </div>
                                    </router-link>
                                </span>
                                <router-link v-else :to="'/search?q='+category.title">
                                    <div class="img-container">
                                        <img :src="category.thumbnail" alt/>
                                    </div>
                                    <div class="text-wrap">

                                        <h2><a href="#">{{ category.title}} </a></h2>

                                    </div>
                                </router-link>

                            </span>

                        </span>
                        <span v-else>
                            <router-link v-if="!category.multi_language_professional_category" :to="'/search?q='+category.title">
                                <div class="img-container">
                                    <img :src="category.thumbnail" alt/>
                                </div>
                                <div class="text-wrap">

                                    <h2><a href="#">{{ category.title}} </a></h2>

                                </div>
                            </router-link>
                            <span v-else>
                                <span v-if="category.multi_language_professional_category.length">
                                    <router-link  :to="'/search?q='+category.multi_language_professional_category[0].title">
                                        <div class="img-container">
                                            <img :src="category.thumbnail" alt/>
                                        </div>
                                        <div class="text-wrap">
                                            <h2  v-if="category.multi_language_professional_category">
                                                <span v-if="category.multi_language_professional_category.length">
                                                    <a href="#">{{ category.multi_language_professional_category[0].title}} </a>
                                                </span>
                                                <a href="#" v-else>{{ category.title}} </a>
                                            </h2>
                                            <h2  v-else><a href="#">{{ category.title}} </a></h2>
                                        </div>
                                    </router-link>
                                </span>

                                <router-link v-else :to="'/search?q='+category.title">
                                    <div class="img-container">
                                        <img :src="category.thumbnail" alt/>
                                    </div>
                                    <div class="text-wrap">

                                        <h2><a href="#">{{ category.title}} </a></h2>

                                    </div>
                                </router-link>
                            </span>

                        </span>
                    </span>


                </div>
            </div>
        </div>
        <div class="md-5 mt-4" v-loading="loading"></div>
        <div class="text-center">
            <button class="mt-5 link" v-if="availableProfessional && categoryType === 'professionals'"  @click.prevent="more">{{ $t('home.viewmore') }}</button>
        </div>
        <div class="text-center">
            <button class="mt-5 link" v-if="availableService && categoryType === 'services'"  @click.prevent="more">{{ $t('home.viewmore') }}</button>
        </div>
    </div>
</template>
<script>
    import i18n from "../../lang";
    import {CONFIG} from "../../config";
    export default {
        name: "CategoryCard",
        props: {
            categories: {
                type: Array,
                default() {
                    return [];
                }
            },
            categoryType: {
                type: String,
                default: "services"
            }
        },
        data(){
          return {
              loading: false,
              local_categories: [],
              professional_limit: 6,
              service_limit: 6,
              config: CONFIG,
              availableProfessional: true,
              availableService: true,
              lang: i18n
          }
        },
        computed: {
            DentalServiceImg() {
                return require("../../assets/images/dental-cleaning.jpg");
            }
        },
        mounted() {
            console.log('locading services', i18n.locale);
        },
        methods:{
            more(){
                this.loading = true
                setTimeout(() => {
                    if(this.categoryType === 'services'){
                        this.service_limit += 6;
                        this.getServices();
                    }else{
                        this.professional_limit += 6;
                        this.getProfessionalCategories()
                    }
                },1000)
            },
            getProfessionalCategories(){
                this.$http.get('/professionalcategories?limit='+this.professional_limit+'&lang='+i18n.locale)
                    .then((response) => {
                        this.local_categories = response.data.data
                        if(this.professional_limit > this.local_categories.length){
                            this.availableProfessional = false
                        }

                        this.loading = false
                    })
            },
            getServices(){
                this.$http.get('/services?limit='+this.service_limit+'&lang='+i18n.locale)
                    .then((response) => {
                        console.log(response, ' services response');
                        this.local_categories = response.data.data
                        if(this.service_limit > this.local_categories.length){
                            this.availableService = false
                        }
                        this.loading = false
                    })
            },
        },
        watch:{
            categories : function (){
                this.local_categories = this.categories
            }
        }
    };
</script>
<style scoped>
    .category .img-container img{
        border-radius:0px;
    }
    button:focus {outline:0;}
</style>