<template>
    <div>
        <p style="margin: 20px 0;">{{ $t('signup.description') }}</p>
        <ValidationObserver ref="form">
            <form v-on:submit.prevent>
                <div class="form-group">
                    <ValidationProvider name="firstName" rules="required" v-slot="{errors}">
                        <input
                            v-model="form.first_name"
                            type="text"
                            name="firstName"
                            :placeholder="$t('form.firstnameplaceholder')"
                        />
                        <p class="errors" v-if="errors[0]">First name field is required</p>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <ValidationProvider name="lastName" rules="required" v-slot="{errors}">
                        <input
                            v-model="form.last_name"
                            type="text"
                            name="lastName"
                            :placeholder="$t('form.lastnameplaceholder')"
                        />
                        <p class="errors" v-if="errors[0]">Last name field is required</p>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <ValidationProvider vid="email" ref="email" name="emailAddress" rules="required|email" v-slot="{errors}">
                        <input
                            v-model="form.email"
                            type="email"
                            name="emailAddress"
                            :placeholder="$t('form.emailplaceholder')"
                        />
                        <p class="errors" v-if="errors[0]">{{errors[0].replace('emailAddress', 'Email')}}</p>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <ValidationProvider name="phoneNumber" rules="required" v-slot="errors">
                        <input
                            v-mask="'(###) ###-####'"
                            v-model="form.phone_number"
                            type="text"
                            name="phoneNumber"
                            :placeholder="$t('form.phoneplaceholder')"
                        />
                        <p class="errors" v-if="errors[0]">Phone number field is required</p>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <select  v-model="form.language_preference" style="padding-left: 0px;border: 0px;border-bottom: 1px solid #e9e9ef;" class="form-control" >
                    <option value="english">English</option>
                    <option value="chinese">Chinese</option>
                    </select>
                </div>
                <div class="form-group">
                    <ValidationProvider vid="password" name="password" rules="required|min:6" v-slot="{errors}">
                        <input
                            v-model="form.password"
                            type="password"
                            name="password"
                            :placeholder="$t('form.passwordplaceholder')"
                        />
                        <p class="errors" v-if="errors[0]">{{ errors[0]}}</p>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <ValidationProvider name="passwordConfirmation" rules="required|confirmed:password" v-slot="{errors}">
                        <input name="passwordConfirmation"
                        v-model="form.password_confirmation"
                        type="password"
                        :class="{'is-danger': errors[0]}"
                        :placeholder="$t('form.confirmpasswordplaceholder')">
                        <p class="errors" v-if="errors[0]">{{ errors[0].replace('passwordConfirmation', 'Password confirmation') }}</p>
                    </ValidationProvider>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
    import {VueMaskDirective} from 'v-mask'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import _ from 'lodash'

    const dict = {
        custom: {
            password: {
                required: 'Password field is required',
                min: 'Password field must be at least 6 characters'
            },
            passwordConfirmation: {
                required: 'Confirm Password field is required',
                confirmed: 'Password confirmation does not match'
            }
        }
    };

    export default {
        name: "signupbasicinfo",
        props: {
            form: {
                type: Object
            }
        },
        components: {
            /* eslint-disable vue/no-unused-components */
            VueMaskDirective,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                emailInvalid: false
            }
        },
        mounted() {
            this.form.language_preference = "english"
        },
        watch: {
            'form.email': _.debounce(function(value) {
                // console.log(this.$refs.email)
                this.$http.post(process.env.VUE_APP_API_URL + "/checkEmail",{
                    email: value
                }, {
                    headers: {
                        Accept: 'application/json'
                    }
                }).then((response) => {
                    this.emailInvalid = false;
                }).catch((error) => {
                    this.$refs.form.setErrors({
                        email: error.response.data.errors.email
                    })
                    this.emailInvalid = true;
                })
            }, 500)
        },
        methods: {
            async validation() {
                // this.$validator.localize('en', dict);
                if(this.emailInvalid) {
                    return false;
                }
                let isValid = await this.$refs.form.validate();
                this.$emit('on-validation', this.$props.form, isValid);
                if(isValid) {
                    this.form.claimProfile.contact_person = this.form.first_name + " " + this.form.last_name
                    this.form.contact_phone_number = this.form.phone_number
                    this.form.contact_email = this.form.email
                }
                return isValid;
                
            }
        },
        directives: {
            mask: VueMaskDirective
        }
    };
</script>
<style scoped>
    p {
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #e9e9f0;
        width: 100%;
        background-color: transparent;
        font-family: Poppins;
        margin: 0.5rem 0;
    }

    input:focus {
        border-color: black;
    }

    .btn-signup {
        color: #ffffff;
        background-color: #0fbc9c;
        border-color: #0fbc9c;
        font-weight: bold;
        border-radius: 1px;
        width: 100%;
    }

    .btn-signup:hover,
    .btn-signup:active,
    .btn-signup:focus,
    .btn-signup.active {
        background: #169e85;
        color: #ffffff;
        border-color: #0fbc9c;
    }

    .btn-wrap {
        padding-bottom: 30px;
    }

    .errors {
        text-align: left;
        color: red;
        font-weight: 400;
        font-size: 12px;
    }

    @media only screen and (max-width: 480px) {
        form input {
            font-size: 12px !important;
        }

        form input::placeholder {
            font-size: 12px !important;
        }
    }
</style>
