<template>
  <div class="search-item">
    <b-card class="mb-3" v-for="search_item in search_items" :key="search_item.id">
      <b-row>
        <b-col xl="3" lg="3" md="3" sm="3">
          <router-link :to="'/profile?professional_id='+search_item.id">
            <b-card-img :src="search_item.avatar"/>
          </router-link>
        </b-col>
        <b-col xl="8" lg="8" md="8" sm="8">
          <b-card-title>
            <span class="h4" >
              <router-link :to="'/profile?professional_id='+search_item.id+'&lang='+lang.locale">
                {{ professionalName(search_item) }}
              </router-link>
            </span>
            <span class="reviews">
              <span class="stars">
                <i v-for="n in 5"
                   :key="'stars'+n"
                   :class="{'active fa-star' : search_item.rating >= n,'fa-star-o': search_item.rating < n}"
                   class="fa "/>
              </span>
            </span>
            <span class="count">({{ search_item.reviews_count }})</span>
          </b-card-title>
          <b-card-text>
            <span class="clearfix" v-if="search_item.offices && search_item.offices.length">
              <h6 >
                <span v-for="(office, index) in search_item.offices"
                      :key="'office-'+office.id">
                  {{ professionalOffice(office) }}
                  <span v-if="index !== search_item.offices.length - 1">, </span>
                </span>
              </h6>
              <p class="location mb-0 small">{{ search_item.offices.length?search_item.offices[0].address:'' }}{{  search_item.offices.length && search_item.offices[0].address ? ' - ' : '' }}{{  search_item.offices.length?search_item.offices[0].city:'' }}</p>
            </span>
            <span></span>
            <h6 v-if="search_item.professionalcategories && search_item.professionalcategories.length">
              <span v-for="(category, index) in search_item.professionalcategories"
                    :key="'cat-'+category.id">
                {{ professionalCategory(category) }}
                <span v-if="index !== search_item.professionalcategories.length - 1">, </span>
              </span>
            </h6>
            <span class="address">
              <!-- <span class="location">{{ search_item.address }}{{search_item.address ? ' - ': ''}}{{search_item.city}}</span> -->
              <span class="distance"
                    v-if="search_item.distance">{{ search_item.distance.toFixed(1) }}km</span>
            </span>
          </b-card-text>
        </b-col>
        <b-col xl="1" lg="1" md="1" sm="1" class="message text-right" v-if="search_item.user">
          <router-link :to="'/message-center/new?user_id=' + search_item.user_id"><i class="fa fa-envelope"/>
          </router-link>
        </b-col>
      </b-row>
    </b-card>
    <h4 class="text-center" v-if="search_items?.length === 0">{{hasLocation ? 'No results found within 100km. Please refine your search fields to narrow down your search' : 'No Results Found!'}}</h4>
  </div>
</template>

<script>
const imgLink = require('../../assets/images/asset-13.png')
import i18n from '../../lang'

export default {
  name : 'SearchItems',
  props: ['search_items', 'hasLocation'],
  data: () => {
    return {
      image: imgLink,
      lang : i18n,
    }
  },
  methods:{
    professionalName:function(professional){
      if( this.lang.locale !== 'en' && professional.multi_language_profile.length ){
        const languageData = professional.multi_language_profile[0];
        if(this.lang.locale === languageData.language){
          return languageData.first_name +' '+ languageData.last_name
        }
      }

      return professional.name??'';
    },

    officeName:function(professional){
      if(this.lang.locale !== 'en' && professional.professionalcategories.length ){
        const category = professional.professionalcategories[0];
        if(category && professional.professionalcategories[0].multi_language_professional_category.length){
          const languageData = professional.professionalcategories[0].multi_language_professional_category[0];
          if( this.lang.locale === languageData.language){
            return languageData.title;
          }
        }
      }

      if(professional.offices.length && professional.offices.name){
        return professional.offices[0].name;
      }
      return ''
    },

    professionalOffice: function (office) {
      if (this.lang.locale !== 'en') {
        if (office && office.multi_language_office && office.multi_language_office.length) {
          const languageData = office.multi_language_office[0]
          if (this.lang.locale === languageData.language) {
            return languageData.name
          }
        }
      }

      return office.name ? office.name : ''
    },

    professionalCategory: function (category) {
      if (this.lang.locale !== 'en') {
        if (category && category.multi_language_professional_category && category.multi_language_professional_category.length) {
          const languageData = category.multi_language_professional_category[0]
          if (this.lang.locale === languageData.language) {
            return languageData.title
          }
        }
      }

      return category.title ? category.title : ''
    },
  }
}
</script>
