// https://vuex.vuejs.org/en/actions.html
import * as TYPES from "./types";
import { localStorageGetItem, localStorageSetItem } from "../plugins/local";
import { http } from "./../plugins/http";
import router from "../router";

export default {

  setFetching({ commit }, obj = { fetching: true }) {
    commit(TYPES.MAIN_SET_FETCHING, obj);
  },

  setMessage({ commit }, obj) {
    commit(TYPES.MAIN_SET_MESSAGE, obj);
  },

  resetMessages({ commit }) {
    commit(TYPES.MAIN_SET_MESSAGE, { type: "success", message: "" });
    commit(TYPES.MAIN_SET_MESSAGE, { type: "error", message: [] });
    commit(TYPES.MAIN_SET_MESSAGE, { type: "warning", message: "" });
    commit(TYPES.MAIN_SET_MESSAGE, { type: "validation", message: {} });
  },



  // Authentication
  getUser: ({ commit, dispatch }) => {
    http.defaults.headers.common.Authorization = `Bearer ${localStorageGetItem(
      "token"
    )}`;
    http
      .get("user")
      .then(res => {
        let permissions = res.data.data.role.permissions;
          // 1. set user data
          commit(TYPES.SET_USER, res.data.data);
          // 2. set user info
          // commit(TYPES.SET_USER_INFO, res.data.info);
          // 3. set user permissions
          commit(TYPES.SET_PERMISSIONS, permissions);
      })
        // eslint-disable-next-line no-unused-vars
      .catch(error => {
        dispatch("logout");
      });
  },

  setToken: ({ commit }, token) => {
    localStorageSetItem("token", token);
    commit(TYPES.SET_TOKEN, token);
  },

  setLang: ({ commit }, lang = "en") => {
    commit(TYPES.SET_LANG, lang);
  },

  // eslint-disable-next-line no-unused-vars
  logout: ({ commit, dispatch }) => {
    localStorage.removeItem("token");
    commit(TYPES.SET_TOKEN, null);
    commit(TYPES.SET_PERMISSIONS, []);
    commit(TYPES.SET_USER, {});
    router.push({ name: "login" });
  },
};
