<template>
  <div class="search-item">
    <b-card class="mb-3" v-for="search_item in search_items" :key="search_item.id">
      <b-row>
        <b-col xl="3" lg="3" md="3" sm="3">
          <b-card-img :src="search_item.thumbnail"/>
        </b-col>
        <b-col xl="8" lg="8" md="8" sm="8">
          <b-card-title>
            <span class="h4">
              <router-link :to="'/office-profile?office_id='+search_item.id+'&lang='+lang.locale">
                {{ officeName(search_item) }}
              </router-link>
            </span>

            <span class="reviews">
              <span class="stars">
                <i v-for="n in 5"
                   :key="'stars'+n"
                   :class="{'active fa-star' : search_item.rating >= n,'fa-star-o': search_item.rating < n}"
                   class="fa "/>
              </span>
            </span>
            <span class="count">({{ search_item.reviews_count }})</span>
          </b-card-title>
          <b-card-text>
            <span v-if="search_item.professionals_count"
                  class="clearfix">{{ search_item.professionals_count }} {{ 'Professional' | pluralize(search_item.professionals_count) }}</span>
            <span></span>
            <span class="address">
              <span class="location">{{ search_item.address }}</span>
              <span class="distance" v-if="search_item.distance">{{ search_item.distance.toFixed(1) }}km</span>
            </span>
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
    <h4 class="text-center" v-if="search_items?.length === 0">{{hasLocation ? 'No results found within 100km. Please refine your search fields to narrow down your search' : 'No Results Found!'}}</h4>
  </div>
</template>

<script>
import i18n from '../../lang'

const imgLink = require('../../assets/images/asset-13.png')

export default {
  name : 'SearchOfficeItems',
  props: ['search_items', 'hasLocation'],
  data : () => {
    return {
      image: imgLink,
      lang : i18n,
    }
  },
  methods: {
    officeName:function(office){
      if( this.lang.locale !== 'en' && office.multi_language_office.length ){
        const languageData = office.multi_language_office[0];
        if(this.lang.locale === languageData.language){
          return languageData.name
        }
      }

      return office.name??'';
    },
  }
}
</script>
