<template>
    <div>
        <b-form @submit.prevent="save" v-loading="loading">
            <div v-for="(error,index) in response_errors" :key="index" class="alert alert-danger" role="alert">
                <span v-html="error[0]"></span>
            </div>
            <b-form-group id="input-group-1" :label="$t('form.officename')" label-for="office_name">
                <b-form-input
                        id="input_office_name"
                        v-model="office.name"
                        required
                />
            </b-form-group>
          <div class="row">
            <div class="col-6">
              <b-form-group id="street_address_1_group" :label="$t('form.addnewoffice.street_address_1')" label-for="street_address_1">
                <b-form-input
                    id="street_address_1"
                    v-model="office.street_address_1"
                    required
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group id="street_address_2_group" :label="$t('form.addnewoffice.street_address_2')" label-for="street_address_2">
                <b-form-input
                    id="street_address_2"
                    v-model="office.street_address_2"
                />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <b-form-group id="city" :label="$t('form.addnewoffice.city')" label-for="city">
                <b-form-input
                    id="city"
                    v-model="office.city"
                    required
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group id="state" :label="$t('form.addnewoffice.state')" label-for="state">
                <b-form-input
                    id="state"
                    v-model="office.state"
                    required
                />
              </b-form-group>
            </div>
          </div>




          <b-form-group id="zip_code" :label="$t('form.addnewoffice.zip_code')" label-for="zip_code">
            <b-form-input
                id="zip_code"
                v-model="office.zip_code"
                required
            />
          </b-form-group>

            <b-form-group id="input_office__phone" :label="$t('form.phonenumber')" label-for="office_phone">
                <b-form-input
                        id="office_phone"
                        v-model="office.phone_number"
                        required
                        v-mask="'(###) ###-####'"
                />
            </b-form-group>

            <b-form-group id="input_contact_email" :label="$t('form.contactemail')" label-for="contact_email">
                <b-form-input
                        id="contact_email"
                        v-model="office.contact_email"
                        required
                />
            </b-form-group>

            <b-form-group class="mt-5">
                <b-button type="submit" variant="success">{{ $t('button.submit') }}</b-button>
                <!--                <b-button type="reset" variant="default">Cancel</b-button>-->
            </b-form-group>
        </b-form>
    </div>
</template>

<script>

    import {VueMaskDirective} from 'v-mask'
    import gmapsInit from "../../utils/gmaps";

    export default {
        name: "OfficeForm",
        data: () => {
            return {
                office: {
                    latitude: '',
                    longitude: '',
                    attachment: ''
                },
                loading: false,
                response_errors: [],
                location : '',
                autocomplete: null,
            }
        },
        components: {
            /* eslint-disable vue/no-unused-components */
            VueMaskDirective
        },
        directives: {
            mask: VueMaskDirective
        },
        computed: {
            user() {
                return this.$store.getters.user;
            }
        },
        async mounted() {
            try {
                const google = await gmapsInit();

                this.autocomplete = new google.maps.places.Autocomplete(
                    this.$refs.autocomplete, {types: ['geocode']});

                // Avoid paying for data that you don't need by restricting the set of
                // place fields that are returned to just the address components.

                const fillInAddress = () => {
                    this.fillInAddress()
                }

                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                this.autocomplete.addListener('place_changed', fillInAddress);
            } catch (error) {
                // console.error(error);
            }


        },
        created() {

        },
        methods: {
            save() {
                this.loading = true
                this.response_errors = []
                if(this.user.professional){
                    this.office.professional_id = this.user.professional.id
                }
                if(this.$route.query.attachment){
                    this.office.attachment = this.$route.query.attachment
                }

                this.$http.post('/officerequests',this.office)
                    .then(() => {
                        this.$message.success('New Office Request has been Sent!. Redirecting ....')
                        this.loading = false
                        this.office = {}
                            setTimeout(() => {
                                this.$message.warning('Redirecting ....')
                                if(this.$route.query.attachment){

                                    if(this.$route.query.attachment === 'workspace'){
                                        this.$router.push('/account-settings/professional?tab=work');
                                    }

                                    if(this.$route.query.attachment === 'ownership'){
                                        this.$router.push('/account-settings/professional?tab=office')
                                    }
                                }
                            },2000)
                    }).
                    catch((errors) => {
                    this.loading = false
                        this.response_errors = errors.response.data.errors
                })
            },

            fillInAddress() {
                // Get the place details from the autocomplete object.
                var place = this.autocomplete.getPlace();


                console.log(place)
                this.office.latitude = place.geometry.location.lat();
                this.office.longitude  = place.geometry.location.lng();
                this.office.address  = place.formatted_address;

                this.location = this.$refs.autocomplete.value;
            },
        }
    }
</script>
<style scoped>
    .search-bar{
        width: 100%;
        padding: 8px;
        /* border-radius: 3px; */
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }
</style>
