<template>
    <div class="section not-found">
        <div class="view mt-5 mb-5">
            <h2 class="text-center mb-5 title">404 Page Not Found</h2>
            <div class="container">
                <div class="row">
                    <div class="col-md-4 offset-4">
                        <router-link to="/" class="btn btn-success btn-block"><i class="fa fa-home"></i> Back to Home
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'about',
        data() {
            return {
                article: "",
                loading: false,
            }
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                this.loading = true
                this.$http.get('/blogs/1b882233-826e-4030-93db-560c1fdd659a')
                    .then((response) => {
                        this.article = response.data.data
                        this.loading = false
                    })
            }
        }
    }
</script>