<template>
    <div>
        <b-form>
            <b-row>
                <b-col xl="4" lg="4" md="4" sm="6" v-for="member in members" :key="member.id">
                    <b-card
                            :title="member.name"
                            :img-src="member.avatar"
                            img-alt="Image"
                            img-top
                            class="mb-2 card-shadow"
                            :class="{'select-member': memberSelect}"
                            @click="memberSelect ? clickInput('checkbox_' + member.id) : null"
                    >
                        <b-card-text>
                            <span v-if="member.professionalcategories">
                                <span v-if="member.professionalcategories.length">
                                    {{ member.professionalcategories[0].title }}
                                </span>
                            </span>

                            <b-form-group v-if="memberSelect" class="mt-4">
                                <b-form-checkbox
                                        :id="'checkbox_' + member.id"
                                        v-model="selectedMembers"
                                        :value="member.id"
                                />
                            </b-form-group>

                            <b-form-group v-if="makeAdmin" class="mt-4">
                                Make an administrator
                                <b-form-checkbox
                                        :id="'checkbox_' + member.id"
                                        v-model="selectedProfessionals"
                                        :value="member.id"
                                />
                            </b-form-group>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-if="selectedMembers.length" class="mt-4">
                <b-col xl="6" lg="6" md="6" sm="12">
                    <b-button @click="sendInvitations" type="button" class="btn-block" variant="success">{{ $t('profile.sendinvitation') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row v-if="makeAdmin" class="mt-4">
                <b-col xl="4" lg="4" md="4" sm="4" offset="8">
                    <b-button style="border: 1px solid #c0c4cc !important;" @click="MakeAdministrator" type="button"  variant="default">{{ $t('profile.savechanges') }}
                    </b-button>
                </b-col>
            </b-row>

            <hr>

        </b-form>
    </div>
</template>

<script>
    export default {
        name: "Members",
        props: {
            members: {
                type: Array,
                default() {
                    return [];
                }
            },
            memberSelect: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            makeAdmin: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            office: {
                type: Object,
                default() {
                    return {};
                }
            },

        },
        data: () => {
            return {
                selectedMembers: [],
                selectedProfessionals: []
            }
        },
        methods: {
            clickInput(id){
                document.getElementById(id).click()
            },
            sendInvitations(){
                this.$_.forEach(this.selectedMembers,(professional_id) => {
                    this.$http.post('/offices/'+this.office.id+'/professionals/'+professional_id+'/invitation')
                        .then((response) => {
                            console.log(response)
                            this.$emit('updated', true)
                            this.$message.success('invitation has been sent!')
                        })
                })

            },
            MakeAdministrator(){
                this.$http.post('/offices/'+this.office.id+'/professionals/make-admin?ids='+this.selectedProfessionals.join())
                        .then((response) => {
                            console.log(response)
                            this.$message.success('Saved!')
                 })

            }
        }
    }
</script>