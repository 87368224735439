<template>
    <div>
        <div class="row">
            <b-col xl="6" lg="6" md="6" class="mb-3" v-for="office in offices" :key="office.id">
                <b-card>
                    <b-row>
                        <b-col xl="3" lg="3" md="3" sm="3">
                            <b-card-img :src="office.thumbnail"/>
                        </b-col>
                        <b-col xl="9" lg="9" md="9" sm="9">
                                <span class="h4">
                                    <router-link
                                            v-if="route === 'office_profile'"
                                                 :to="'/office-profile?office_id=' + office.id">{{ office.name }}</router-link>
                                    <router-link
                                            v-else-if="route === 'manage_office'"
                                                 :to="'/account-settings/professional?tab=office&type=new&office_id=' + office.id">{{ office.name }}</router-link>
                                    <router-link
                                            v-else-if="route === 'manage_work'"
                                                 :to="'/account-settings/professional?tab=work&office_id=' + office.id">{{ office.name }}</router-link>

                                    <span class="link_office" v-else>{{ office.name }}</span>
                                   <!-- <router-link v-else
                                                 :to="'/review/office/' + office.name.toLowerCase().replace(/ /g, '-') + '/write?id=' + office.id">{{ office.name }}</router-link>-->
                                </span>
                            <b-card-text class="mt-1">
                                <span class="location">{{office.address}}</span>
                            </b-card-text>
                          <a href="#" @click.prevent="unOwnOffice(office)">remove</a>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Offices",
        props: {
            offices: {
                type: Array,
                default() {
                    return [];
                }
            },
            route: {
                type: String,
                default: 'office_profile'
            }
        },
        methods:{
          unOwnOffice(office) {
            if(!confirm('Are you sure? after this you will not able to manage this office.')) return
            this.$http.post('/offices/'+office.id+'/un-own')
            .then(() => {
              this.offices.splice(this.offices.indexOf(office), 1);
            })
          }
        }
    }
</script>
<style scoped>
    .link_office{
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 600;
        color: #2E95DB;
        text-decoration: none;
    }
</style>