<template>
    <div>


        <business-hours type="select" @updated-hours="updatedHours" class="busniess-hours-vue" :days="working_hours"></business-hours>

        <div class="timeline">
            
        </div>
    </div>

</template>

<script>
    import BusinessHours from 'vue-business-hours';

    export default {
        name: "Hours",
        props: {
            placement: {
              type: String,
              default: "workspace"
            },
            working_hours_data: {
                type: Object,
                // eslint-disable-next-line vue/require-valid-default-prop
                default: {
                    "monday": [
                        {
                            "id": "5ca5578b0c5d1",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "tuesday": [
                        {
                            "id": "5ca5578b0c5d8",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "wednesday": [
                        {
                            "id": "5ca5542b0c5d8",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "thursday": [
                        {
                            "id": "5ca5578b0c5e6",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "friday": [
                        {
                            "id": "5ca5578b0c5e6",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "saturday": [
                        {
                            "id": "5ca5578b0c5f8",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "sunday": [
                        {
                            "id": "5ca5578b0c5c7",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": false
                        }
                    ],
                },
            }
        },
        components: {BusinessHours},
        data: () => {
            return {
                working_hours: {},
                switchOpen: 'Open',
                switchClosed: 'Closed',
                placeholderOpens: 'Opens',
                placeholderCloses: 'Closes',
                addHours: 'Add hours',
                open: {
                    invalidInput: 'Please enter an opening time in the 12 hour format (ie. 08:00 AM). You may also enter "24 hours".',
                    greaterThanNext: 'Please enter an opening time that is before the closing time.',
                    lessThanPrevious: 'Please enter an opening time that is after the previous closing time.',
                    midnightNotLast: "Midnight can only be selected for the day's last closing time."
                },
                close: {
                    invalidInput: 'Please enter a closing time in the 12 hour format (ie. 05:00 PM). You may also enter "24 hours" or "Midnight".',
                    greaterThanNext: 'Please enter a closing time that is after the opening time.',
                    lessThanPrevious: 'Please enter a closing time that is before the next opening time.',
                    midnightNotLast: "Midnight can only be selected for the day's last closing time."
                },
                t24hours: '24 hours',
                midnight: 'Midnight',
                days: {
                    monday: 'Monday',
                    tuesday: 'Tuesday',
                    wednesday: 'Wednesday',
                    thursday: 'Thursday',
                    friday: 'Friday',
                    saturday: 'Saturday',
                    sunday: 'Sunday',
                    newYearsEve: 'New Year\'s Eve', // prettier-ignore
                    newYearsDay: 'New Year\'s Day', // prettier-ignore
                    martinLutherKingJrDay: 'Martin Luther King, Jr. Day',
                    presidentsDay: 'Presidents\' Day', // prettier-ignore
                    easter: 'Easter',
                    memorialDay: 'Memorial Day',
                    independenceDay: 'Independence Day',
                    fourthOfJuly: '4th of July',
                    laborDay: 'Labor Day',
                    columbusDay: 'Columbus Day',
                    veteransDay: 'Veterans Day',
                    thanksgivingDay: 'Thanksgiving Day',
                    christmasEve: 'Christmas Eve',
                    christmas: 'Christmas',
                }
            }
        },
        mounted() {
            this.days.monday = this.$t('profile.monday');
            this.days.tuesday = this.$t('profile.tuesday');
            this.days.wednesday = this.$t('profile.wednesday');
            this.days.thursday = this.$t('profile.thursday');
            this.days.friday = this.$t('profile.friday');
            this.days.sunday = this.$t('profile.sunday');
            // console.log('got this data');
            if (!this.$_.isEmpty(this.working_hours_data)) {
                this.working_hours = this.working_hours_data;
            }
            else {
                this.working_hours = {
                    "monday": [
                        {
                            "id": "5ca5578b0c5d1",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "tuesday": [
                        {
                            "id": "5ca5578b0c5d8",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "wednesday": [
                        {
                            "id": "5ca5542b0c5d8",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "thursday": [
                        {
                            "id": "5ca5578b0c5e6",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "friday": [
                        {
                            "id": "5ca5578b0c5e6",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "saturday": [
                        {
                            "id": "5ca5578b0c5f8",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": true
                        }
                    ],
                    "sunday": [
                        {
                            "id": "5ca5578b0c5c7",
                            "open": "0900",
                            "close": "1700",
                            "isOpen": false
                        }
                    ],
                };
            }
            setTimeout(() => {
                this.$emit('change-working-hours', this.working_hours)
            }, 2000)

        },
        methods: {
            hourUpdate(day) {
                if (day === 'mon') {
                    this.date.monChecked = this.date.mon.hours.filter(hour => hour.checked);

                    if (this.date.monChecked.length > 2) {
                        // revert last selections (roughly) and update boxes based on new limit
                        let tickDiff = this.date.monChecked.length - 1;
                        for (let i = this.date.monChecked.length - 1; i >= (this.date.monChecked.length - tickDiff); i--) {
                            this.date.mon.hours.find(hour => {
                                if (hour.time == this.date.monChecked[i].time) hour.checked = false;
                            })
                        }
                    }
                } else if (day === 'tue') {
                    this.date.tueChecked = this.date.tue.hours.filter(hour => hour.checked);

                    if (this.date.tueChecked.length > 2) {

                        // revert last selections (roughly) and update boxes based on new limit
                        let tickDiff = this.date.tueChecked.length - 1;
                        for (let i = this.date.tueChecked.length - 1; i >= (this.date.tueChecked.length - tickDiff); i--) {
                            this.date.tue.hours.find(hour => {
                                if (hour.time == this.date.tueChecked[i].time) hour.checked = false;
                            })
                        }
                    }
                } else if (day === 'wed') {
                    this.date.wedChecked = this.date.wed.hours.filter(hour => hour.checked);

                    if (this.date.wedChecked.length > 2) {

                        // revert last selections (roughly) and update boxes based on new limit
                        let tickDiff = this.date.wedChecked.length - 1;
                        for (let i = this.date.wedChecked.length - 1; i >= (this.date.wedChecked.length - tickDiff); i--) {
                            this.date.wed.hours.find(hour => {
                                if (hour.time == this.date.wedChecked[i].time) hour.checked = false;
                            })
                        }
                    }
                } else if (day === 'thu') {
                    this.date.thuChecked = this.date.thu.hours.filter(hour => hour.checked);

                    if (this.date.thuChecked.length > 2) {

                        // revert last selections (roughly) and update boxes based on new limit
                        let tickDiff = this.date.thuChecked.length - 1;
                        for (let i = this.date.thuChecked.length - 1; i >= (this.date.thuChecked.length - tickDiff); i--) {
                            this.date.thu.hours.find(hour => {
                                if (hour.time == this.date.thuChecked[i].time) hour.checked = false;
                            })
                        }
                    }
                } else if (day === 'fri') {
                    this.date.friChecked = this.date.fri.hours.filter(hour => hour.checked);

                    if (this.date.friChecked.length > 2) {

                        // revert last selections (roughly) and update boxes based on new limit
                        let tickDiff = this.date.friChecked.length - 1;
                        for (let i = this.date.friChecked.length - 1; i >= (this.date.friChecked.length - tickDiff); i--) {
                            this.date.fri.hours.find(hour => {
                                if (hour.time == this.date.friChecked[i].time) hour.checked = false;
                            })
                        }
                    }
                } else if (day === 'sat') {
                    this.date.satChecked = this.date.sat.hours.filter(hour => hour.checked);

                    if (this.date.satChecked.length > 2) {

                        // revert last selections (roughly) and update boxes based on new limit
                        let tickDiff = this.date.satChecked.length - 1;
                        for (let i = this.date.satChecked.length - 1; i >= (this.date.satChecked.length - tickDiff); i--) {
                            this.date.sat.hours.find(hour => {
                                if (hour.time == this.date.satChecked[i].time) hour.checked = false;
                            })
                        }
                    }
                } else if (day === 'sun') {
                    this.date.sunChecked = this.date.sun.hours.filter(hour => hour.checked);

                    if (this.date.sunChecked.length > 2) {

                        // revert last selections (roughly) and update boxes based on new limit
                        let tickDiff = this.date.sunChecked.length - 1;
                        for (let i = this.date.sunChecked.length - 1; i >= (this.date.sunChecked.length - tickDiff); i--) {
                            this.date.sun.hours.find(hour => {
                                if (hour.time == this.date.sunChecked[i].time) hour.checked = false;
                            })
                        }
                    }
                }

            },
            updatedHours: function () {
                this.$emit('change-working-hours', this.working_hours)
            }
        },
    }

</script>
<style scoped>
    .busniess-hours-vue >>> .add-hours {
        display: none
    }

    .busniess-hours-vue >>> .time-errors {
        display: none
    }

    .busniess-hours-vue >>> .flex-row.day {
        font-size: 14px;
        font-weight: 600;
        color: #3a3a3a !important;
    }

    .busniess-hours-vue >>> .vue-js-switch .v-switch-core {
        border-radius: 0 !important;
    }

    .busniess-hours-vue >>> .vue-js-switch.toggled .v-switch-core {
        background-color: #0FBC9C !important;
    }

    .busniess-hours-vue >>> .flex-row.hours .time-input {
        font-size: 12px !important;
        font-weight: 600;
        color: #5e5e5e !important;
        text-align: center;
        border: none;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    }

    @media only screen and (max-width: 480px) {
        .busniess-hours-vue >>> .flex-table {
            flex-flow: wrap;
            margin-bottom: 25px;
            padding: 0 !important;
        }

        .busniess-hours-vue >>> .flex-row.day {
            width: 40% !important;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .busniess-hours-vue >>> .flex-row.toggle {
            width: 60% !important;
            text-align: left;
        }

        .busniess-hours-vue >>> .flex-row.hours {
            padding: 5px 0;
        }

        .busniess-hours-vue >>> .flex-row.hours.open, .busniess-hours-vue >>> .flex-row.hours.close {
            width: 40%;
        }

        .busniess-hours-vue >>> .flex-row.hours.open {
            text-align: left;
        }

        .busniess-hours-vue >>> .flex-row.dash {
            width: 10%;
            text-align: center;
        }

        .busniess-hours-vue >>> .vue-js-switch .v-switch-core {
            /*width: 100% !important;*/
        }
    }
</style>