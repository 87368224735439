<template>
    <div v-loading="loading">
        <b-form @submit="onSubmit">
            <b-form-group id="patient">
                <label for="patientName">{{ $t('notifications.patient') }}</label>
                <multiselect
                        id="patientName"
                        name="patient_name"
                        v-model="form.patients.selected"
                        :options="form.patients.options"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :loading="isLoading"
                        track-by="id"
                        label="first_name"
                        :internal-search="false"
                        :clear-on-select="false"
                        placeholder="Type to search patients"
                        :options-limit="5"
                        :max-height="250"
                        :allow-empty="false"
                        v-validate="{ required: true }"
                        @search-change="asyncFind">
                </multiselect>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('patient_name')">The patient
                    name field is required</p>
            </b-form-group>
            <b-form-group id="main">
                <label for="mainCategory">{{ $t('notifications.maincategory') }}</label>
                <multiselect
                        id="mainCategory"
                        name="mainCategory"
                        v-model="form.categories.selected"
                        :options="form.categories.options"
                        :searchable="false"
                        :close-on-select="true"
                        :show-labels="true"
                        track-by="id"
                        label="title"
                        v-validate="{ required: true }"
                        @input="onChangeCategory($event)">
                </multiselect>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('mainCategory')">The main
                    category field is required</p>
            </b-form-group>
            <b-form-group id="sub" class="mt-3">
                <label for="subCategory">{{ $t('notifications.subcategory') }}</label>
                <multiselect
                        id="subCategory"
                        name="subCategory"
                        v-model="form.subCategories.selected"
                        :options="form.subCategories.options"
                        :searchable="false"
                        :close-on-select="true"
                        :show-labels="false"
                        track-by="id"
                        label="title"
                        v-validate="{ required: true }"
                        @input="onChangeSubCategory($event)"/>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('subCategory')">The sub category
                    field is required</p>
            </b-form-group>
            <b-form-group id="tem" class="mt-3">
                <label for="template">{{ $t('notifications.emailtemplate') }}</label>
                <multiselect
                        id="template"
                        name="template"
                        v-model="form.templates.selected"
                        :options="form.templates.options"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :group-select="false"
                        track-by="id"
                        label="title"
                        v-validate="{ required: true }"
                        @input="onChangeTemplate($event)"/>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('template')">The email template
                    field is required</p>
            </b-form-group>
            <b-form-group class="mt-3">
                <label for="title">{{ $t('notifications.templatetitle') }}</label>
                <b-form-input
                        id="title"
                        name="title"
                        type="text"
                        v-model="form.title"
                        :placeholder="$t('notifications.templatetitle')"
                        v-validate="{ required: true }"
                />
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('title')">The notification title
                    field is required</p>
            </b-form-group>
            <b-form-group class="mt-3">
                <label for="content">{{ $t('notifications.templatecontent') }}</label>
                <vue-editor id="content" v-model="form.content" :editor-toolbar="customToolbar"/>
            </b-form-group>
            <b-form-group>
                <b-col xl="12" lg="12" md="12" sm="12" class="mt-3 mb-3">
                    <b-form-checkbox
                            id="check"
                            name="checkbox-1"
                            value="1"
                            v-model="form.is_reply"
                    >{{ $t('notifications.reply') }}
                    </b-form-checkbox>
                </b-col>
            </b-form-group>
            <b-form-group>
                <b-col xl="6" lg="6" md="6" sm="6" offset="3">
                    <button type="submit"
                            class="btn btn-success btn-block" :disabled="isDisabled">{{
                        $t('notifications.sendnotification') }}
                    </button>
                </b-col>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {VueEditor} from "vue2-editor";

    export default {
        name: "NotificationTemplate",
        components: {
            Multiselect,
            VueEditor
        },
        data: () => {
            return {
                loading: true,
                isLoading: false,
                isDisabled: false,
                customToolbar: [
                    [{'font': []}],
                    [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
                    [{'size': ['small', false, 'large', 'huge']}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                    [{'header': 1}, {'header': 2}],
                    ['blockquote', 'code-block'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                    [{'script': 'sub'}, {'script': 'super'}],
                    [{'indent': '-1'}, {'indent': '+1'}],
                    [{'color': []}, {'background': []}],
                    ['link', 'formula'],
                    [{'direction': 'rtl'}],
                    ['clean'],
                ],
                form: {
                    patients: {
                        selected: null,
                        options: []
                    },
                    title: '',
                    content: '',
                    category_id: '',
                    sub_category_id: '',
                    categories: {
                        selected: null,
                        options: []
                    },
                    subCategories: {
                        selected: null,
                        options: []
                    },
                    templates: {
                        selected: null,
                        options: []
                    },
                    selected: null,
                    is_reply: 0
                },
                defaultList: [],
                customList: [],
            }
        },
        computed: {
            user() {
                return this.$store.getters.user;
            }
        },
        mounted() {
            this.getCategories(this.$route.query['main_id']);
            setTimeout(() => {
                this.loading = false
            }, 1000)
        },
        methods: {
            asyncFind(query) {
                this.isLoading = true;
                this.$http.get('/patients/search?q=' + query)
                    .then((response) => {
                        this.isLoading = false;
                        if (response.data.length > 0) {
                            this.form.patients.options = response.data
                        } else {
                            this.form.patients.options = []
                        }
                    });
            },
            getCategories(mainCategory) {
                if (this.$route.query['type'] === 'default') {
                    this.$http.get('/notifcationcategories')
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                // main categories
                                this.form.categories.options = response.data.data;
                                this.form.categories.selected = this.$_.find(this.form.categories.options, {'id': mainCategory})

                                // sub categories
                                this.getSubCategories(mainCategory);

                                // email templates
                                this.getEmailTemplates(mainCategory, this.$route.query['sub_id']);
                            }
                        });
                } else {
                    this.$http.get('/notifcationcategories/custom')
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                // main categories
                                this.form.categories.options = response.data.data;
                                this.form.categories.selected = this.$_.find(this.form.categories.options, {'id': mainCategory})

                                // sub categories
                                this.getSubCategories(mainCategory);

                                // email templates
                                this.getEmailTemplates(mainCategory, this.$route.query['sub_id']);
                            }
                        });
                }
            },
            getSubCategories(category) {
                this.form.subCategories.options = this.$_.find(this.form.categories.options, {'id': category}).sub_categories
                this.form.subCategories.selected = this.$_.find(this.form.subCategories.options, {'id': this.$route.query['sub_id']})
            },
            getEmailTemplates(mainCategory, subCategory) {
                if (this.$route.query['type'] === 'default') {
                    this.$http.get('/emailtemplates')
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                // email templates
                                this.form.templates.options = this.$_.filter(response.data.data, {
                                    'category_id': mainCategory,
                                    'sub_category_id': subCategory
                                });
                            }
                        });
                } else {
                    this.$http.get('/customtemplates', this.form)
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                // custom email templates
                                this.form.templates.options = this.$_.filter(response.data.data, {
                                    'category_id': mainCategory,
                                    'sub_category_id': subCategory
                                });
                                this.form.templates.selected = this.$_.find(response.data.data, {
                                    id: this.$route.query['email'],
                                    'category_id': mainCategory,
                                    'sub_category_id': subCategory
                                });

                                this.form.title = this.form.templates.selected.title;
                                this.form.content = this.form.templates.selected.content;
                            }
                        });
                }

                this.form.category_id = this.form.categories.selected.id;
                this.form.sub_category_id = this.form.subCategories.selected.id;

            },
            onChangeCategory(event) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    if (event) {
                        // changing sub categories
                        this.getSubCategories(event.id);

                        // changing email templates
                        if (this.form.subCategories.selected) {
                            this.getEmailTemplates(event.id, this.form.subCategories.selected)
                        } else {
                            this.form.templates.options = this.form.templates.selected = []
                        }
                    } else {
                        this.form.subCategories.options = this.form.subCategories.selected = []
                    }
                }, 1000)
            },
            onChangeSubCategory(event) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    if (event) {
                        // changing email templates
                        this.getEmailTemplates(this.form.categories.selected.id, event.id)
                    } else {
                        this.form.templates.options = this.form.templates.selected = []
                    }
                }, 1000)
            },
            onChangeTemplate(event) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    if (event) {
                        // changing title and content
                        this.form.title = this.form.templates.selected.title;
                        this.form.content = this.form.templates.selected.content;
                    } else {
                        this.form.title = this.form.content = ''
                    }
                }, 1000)
            },
            onSubmit(evt) {
                evt.preventDefault()
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // this.loading = true;
                        // this.isDisabled = true;
                        // setTimeout(() => {
                        let formData = {
                            to: this.form.patients.selected.user_id,
                            subject: this.form.title,
                            content: this.form.content,
                            is_repliable: this.form.is_reply,
                        };
                        this.$http.post('/messages/send', formData)
                            .then(() => {
                                // if (response.statusText === 'Created') {
                                this.$message.success('Notification successfully Sent to the patient')
                                this.getCategories(this.$route.query.main_id)
                                this.loading = false;
                                this.isDisabled = false;
                                // setTimeout(this.$router.go(this.$route.fullPath), 5000);
                                // }
                            })
                            .catch((e) => {
                                console.log(e);
                            })
                        // }, 1000)
                    } else {
                        this.loading = false;
                        this.isDisabled = false;
                    }
                })

            }
        }
    }
</script>