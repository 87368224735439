<template>
    <div>
        <div class="row">
            <b-col @click="selectable ? add(professional) : null" xl="6" lg="6" md="6" class="mb-3" v-for="professional in professionals" :key="professional.id">
                <b-card :class="{'active': $_.find(selected_professionals, {id: professional.id})}">
                    <b-row>
                        <b-col xl="3" lg="3" md="3" sm="3">
                            <b-card-img :src="professional.avatar"/>
                        </b-col>
                        <b-col xl="9" lg="9" md="9" sm="9">
                                <span class="h4">
                                     {{ professional.name }}
                                </span>
                            <b-card-text class="mt-1">
                                <span class="clearfix" v-if="professional.professionalcategories.length">
                                {{professional.professionalcategories[0].title}}
                                </span>
                            </b-card-text>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </div>
        <b-form-group v-if="selected_professionals.length">
            <b-col xl="4" lg="4" md="4" sm="12" offset="4" class="mt-5">
                <br>
                <button type="button" @click="next"
                        class="btn btn-success btn-block">{{ $t('form.continue') }}
                </button>
            </b-col>
        </b-form-group>
    </div>
</template>

<script>
    import _ from 'lodash'
    export default {
        name: "ProfessionalItem",
        props: ['professionals', 'route_params', 'page_type','office','selectable'],
        data: () => {
            return {
                office_id: '',
                params: '',
                selected_professionals: [],
                url: ''
            }
        },
        mounted() {
            this.office_id = this.$route.query.office_id;
            this.params = '&patient_id=' + this.$route.query.patient_id + '&existing_patient=' + this.$route.query.existing_patient + '&office_id=' + this.$route.query.office_id;
        },
        methods: {
            add(professional){
                if(this.selected_professionals.length){
                    if(_.find(this.selected_professionals, {id: professional.id})){

                         _.remove(this.selected_professionals, function (currentObject) {
                            return currentObject.id === professional.id
                        });

                    }
                    else {
                        this.selected_professionals.push(professional);
                    }
                }
                else {
                    this.selected_professionals.push(professional);
                }


                this.selected_professionals = _.uniq(this.selected_professionals);

            },
            next(){
                var ids = _.map(this.selected_professionals, 'id');
                this.url = '/review/office/'  + this.office_id + '/write?' + this.params + '&professional_id=' + ids;

                this.$router.push({path: this.url})
            }
        }
    }
</script>
<style scoped>
    .active{
        border: 2px solid #3498db !important;
    }
    .h4{
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 600;
        color: #2C3E50;
    }
    .account-settings .tab-content .all .card img, .review .offices .card img{
        height: auto !important;
    }
</style>