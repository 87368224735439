<template>
  <div class="services" v-if="services.length">
    <h3>{{ $t('drprofile.services') }}</h3>
    <ul v-if="requester==='professional'">
      <li v-for="service in services" :key="'service'+service.title">
        <span>
        {{servive(service)}}
        </span>
      </li>
    </ul>
    <ul v-else>
      <li v-for="service in services" :key="'service'+service">

        <span>
          {{ $_.isObject(service) ? service.title : service }}
        </span>
        <!--        <span v-else>-->
        <!--          <span v-if="service.multi_language_service.length">-->
        <!--            {{service.multi_language_service[0].title}}-->
        <!--          </span>-->
        <!--          <span v-else>-->
        <!--            {{$_.isObject(service) ? service.title : service }}-->
        <!--          </span>-->
        <!--         </span>-->
      </li>
    </ul>

  </div>
</template>
<script>

export default {
  name : 'services',
  props: {
    lang     : {
      type   : String,
      default: 'en',
    },
    services : {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
    },
    requester: {
      type   : String,
      default: 'office',
    },
  },
  methods: {
    servive: function (service) {
      if (this.lang !== 'en' && service.multi_language_service && service.multi_language_service.length) {
        const languageData = service.multi_language_service[0]
        if (this.lang === languageData.language) {
          return languageData.title
        }
      }

      return service.title ? service.title : ''
    },
  }
}
</script>
