<template>
    <div class="section howitworks" v-loading="loading">
        <div class="view">
            <h2 class="text-center mb-5">{{article.title}}</h2>
            <b-container class="mt-5">
                <div v-html="article.content"></div>
            </b-container>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../event-bus";
    import i18n from "../lang";

    export default {
        name: "HowItWorks",
        data() {
            return {
                article: "",
                loading: false
            }
        },
        mounted() {
            this.get()
            EventBus.$on('langChanged', () => {
                this.get()
            })
        },
        methods: {
            get(){
                this.loading = true
                this.$http.get('/blogs/49179431-8661-4ddf-adb5-a2b3b0931d4e'+'?lang='+i18n.locale)
                    .then((response) => {
                        this.article = response.data.data
                        if(this.article.multi_language_blog && this.article.multi_language_blog.length){
                            var translation = this.article.multi_language_blog[0];
                            this.article.title = translation.title;
                            this.article.description = translation.description;
                            this.article.content = translation.content ;
                        }
                        this.loading = false
                    })
            }
        }
    }
</script>