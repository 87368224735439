<template>
    <div v-loading="loading">
        <div class="mb-5 profile">
            <img class="img-fluid" v-if="form.avatar" :src="form.avatar" id="profileImage" alt="avatar">
            <img class="img-fluid" v-else src="/storage/placeholder-avatar.png" id="profileImage" alt="avatar">
            <span class="text-center" @click="chooseFiles()">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
        </div>
        <br/>
        <div v-for="(error,index) in errors" :key="index" class="alert alert-danger" role="alert">
            {{error[0]}}
        </div>
        <b-form @submit.prevent="updatePatient">
            <b-form-group id="input-group-1" :label="$t('form.firstname')" label-for="first_name">
                <b-form-input
                        id="first_name"
                        v-model="form.first_name"
                        required
                />
            </b-form-group>

            <input id="fileUpload" type="file" ref="fileInput" hidden @change="setUserAvatar">

            <b-form-group id="input-group-2" :label="$t('form.lastname')" label-for="last_name">
                <b-form-input
                        id="last_name"
                        v-model="form.last_name"
                        required
                />
            </b-form-group>
            <b-form-group
                    id="input-group-3"
                    :label="$t('form.email')"
                    label-for="email"
            >
                <b-form-input
                        id="email"
                        v-model="form.email"
                        type="email"
                        required
                />
            </b-form-group>
            <b-form-group id="input-group-4" :label="$t('form.address')" label-for="address">
                <b-form-input
                        id="address"
                        v-model="form.address"
                />
            </b-form-group>
            <b-form-group id="input-group-5" :label="$t('form.city')" label-for="city">
                <b-form-input
                        id="city"
                        v-model="form.city"
                />
            </b-form-group>
            <b-form-group id="input-group-6" :label="$t('form.state')" label-for="state">
                <b-form-input
                        id="state"
                        v-model="form.state"
                />
            </b-form-group>
            <b-form-group id="input-group-7" :label="$t('form.zipcode')" label-for="zip_code">
                <b-form-input
                        id="zip_code"
                        v-model="form.zip_code"
                />
            </b-form-group>
            <location-picker :place.sync="place"></location-picker>
            <b-form-group id="input_phone" :label="$t('form.phone')" label-for="phone">
                <b-form-input
                        id="phone"
                        v-model="form.phone_number"
                />
            </b-form-group>

            <b-form-group class="mt-5">
                <b-button type="submit" variant="success">{{ $t('profile.save') }}</b-button>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
    const imgLink = require('../../assets/images/profile.jpg');

    export default {
        name: "Patient",
        data: () => {
            return {
                proImage: imgLink,
                loading: false,
                form: {},
                place: null, // optional
                errors: []
            }
        },
        mounted() {
            this.loading = true;
            setTimeout(() => {
                this.getPatient();
            }, 1000)
        },
        computed: {
            user() {
                return this.$store.getters.user;
            },
        },
        methods: {
            getPatient() {
                this.loading = true;
                this.$http.get('/patients/find-by-user-id/' + this.user.id)
                    .then((response) => {
                        this.form = response.data.data
                        this.loading = false;
                    })
            },
            updatePatient() {
                this.loading = true;
                this.$http.post('/patients/' + this.form.id, this.form)
                    .then((response) => {
                        console.log(response)
                        this.form = response.data.data
                        this.loading = false;
                        this.$message.success('Saved!')
                        this.errors = [];
                    })
                    .catch((errors) => {
                        this.loading = false;
                        this.errors = errors.response.data.errors
                        this.$message.error('Please clear errors!')
                    })
            },
            setUserAvatar() {
                let file = this.$refs.fileInput.files[0];
                let reader = new FileReader();
                let vm = this;
                vm.form.avatar = ""

                setTimeout(() => {
                    reader.onload = (e) => {
                        vm.form.avatar = e.target.result;
                    };
                    reader.readAsDataURL(file);
                }, 1000)

            },
            onSubmit(evt) {
                evt.preventDefault()
                alert(JSON.stringify(this.form))
            },
            chooseFiles() {
                document.getElementById("fileUpload").click()
            },
            onFileChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0]);
            },
            createImage(file) {
                /*eslint-disable no-unused-vars*/
                const proImage = new Image();
                const reader = new FileReader();
                const vm = this;

                reader.onload = (e) => {
                    vm.proImage = e.target.result;
                };
                reader.readAsDataURL(file);
            },
        }
    }
</script>