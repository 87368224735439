<template>
  <div class="section howitworks" v-loading="loading">
    <div class="view">
      <h2 class="text-center mb-5">{{article.title}}</h2>
      <b-container class="mt-5" v-html="article.content">

      </b-container>
    </div>
  </div>
</template>
<script>
  import i18n from "../lang";
  import {EventBus} from "../event-bus";
export default {
    name:'about',
    data(){
        return {
          article: "",
          loading: false,
        }
    },
  mounted() {
    this.get()

    EventBus.$on('langChanged', () => {
      this.get()
    })
  },
    methods: {
      get(){
        this.loading = true
        this.$http.get('/blogs/1b882233-826e-4030-93db-560c1fdd659a'+'?lang='+i18n.locale)
                .then((response) => {
                  this.article = response.data.data
                  if(this.article.multi_language_blog && this.article.multi_language_blog.length){
                    var translation = this.article.multi_language_blog[0];
                    this.article.title = translation.title;
                    this.article.description = translation.description;
                    this.article.content = translation.content ;
                  }
                  this.loading = false
                })
      }
    }
}
</script>