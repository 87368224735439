<template>
    <div class="offices">

        <div class="all" v-if="$route.query.tab === 'work' && !$route.query.office_id">
            <div class="text-muted mb-4">{{$t('managework.description')}}</div>
            <multiselect
                    :options-limit="300"
                    @select="addmeInOffice"
                    :internal-search="false"
                     :clear-on-select="false"
                    @search-change="debounceSearch"
                    v-model="search.offices"
                    :options="options.offices"
                    placeholder="please Search and Select one" label="name" track-by="name">

                <template slot="option" slot-scope="props">
                    <div class="media">
                        <img class="mr-3" width="50" :src="props.option.thumbnail" alt="Generic placeholder image">
                        <div class="media-body">
                            <h5 class="mt-0">{{ props.option.name }}</h5>
                            {{ props.option.address }}
                        </div>
                    </div>
                </template>

                <template slot="noResult" >
                    <router-link to="/office/add?attachment=workspace">{{$t('profile.serachRequestnewOffice')}}</router-link>
                </template>
            </multiselect>
            <br>
            <br>
            <div v-loading="loading">
                <offices route="manage_work" :offices="offices"/>
            </div>

            <div v-loading="loading" v-if="pending_offices.length">
                <hr>
                <h6 class="mb-5 text-muted">Pending Requests</h6>
                <offices :route="false"  :offices="pending_offices"/>
            </div>

        </div>
        <div class="one" v-else>
            <div v-loading="loading">
                <hours v-if="!loading" @change-working-hours="handleWorkingHours" :working_hours_data="working_hours"/>
                <div class="my-4">
                    <button  class="btn btn-success" @click="save">{{ $t('profile.save') }}</button>

                    <router-link style="border: 1px solid #343a40;margin-top: 10px;" class="ml-4 btn"  to="/account-settings/professional?tab=work">{{ $t('profile.backbutton') }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Offices from "./Offices";
    import Hours from "./Hours";
    import {debounce} from 'lodash';
    export default {
        name: "ManageWork",
        components: {
            'offices': Offices,
            'hours': Hours
        },
        computed: {
            user: function () {
                return this.$store.getters.user;
            }
        },
        data: () => {
            return {
                tabType: false,
                working_hours: {},
                offices: [],
                pending_offices: [],
                selected_office : {},
                professional_working_hours:{},
                loading: true,
                search: {
                    offices: []
                },
                options: {
                    offices: []
                }
            }
        },
        mounted() {
          this.getProfessional();
          this.getPendingOffices();
        },
        methods:{
            save(){
                this.$http.post('professionals/working-hours/'+this.user.professional.id+'/'+this.$route.query.office_id,{'data': this.professional_working_hours })
                    .then(() => {
                       this.$message.success('changes has been saved!')
                    })
            },
            getProfessional() {
                this.loading = true
                    this.$http.get('offices/workplace')
                        .then((response) => {

                            this.offices = response.data.data;

                            if(this.$route.query.tab === 'work' && this.$route.query.office_id){
                                let office = this.$_.find(response.data.data, {'id': this.$route.query.office_id});
                                if(office){
                                    console.log('office', office);
                                    this.selected_office = office;
                                    if(office.professional_working_hours){
                                        this.working_hours = office.professional_working_hours.data;
                                    }else{
                                        this.working_hours = {}
                                    }

                                    this.loading = false;
                                }
                            }

                            this.loading = false

                        })
            },

            getPendingOffices() {
                this.$http.get('/officerequests/pending?attachment=workspace')
                    .then((response) => {
                        this.pending_offices = response.data.data;
                    })
            },
            searchOffices(searchQuery){
                this.$http.get('/offices/search/selection?q='+searchQuery)
                    .then((response) => {
                        this.options.offices = response.data
                        this.loading = false;
                    })
            },
            addmeInOffice(office){
              this.$http.post('/offices/'+office.id+'/addme')
                .then(() => {
                    this.options.offices = [];
                    this.search.offices = ''
                    this.getProfessional();
                })
            },
            handleWorkingHours(data) {
                this.professional_working_hours = data
            }
        },
        created() {
            this.tabType = this.$route.query.tab === "work";
            this.debounceSearch = debounce(this.searchOffices, 500)
        },
        watch: {

        }
    }
</script>