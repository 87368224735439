<template>
    <div>
        <p>{{ $t('signup.selectprofile') }}</p>
        <form v-on:submit.prevent>
            <div v-if="result">
                <div class="card mb-3 professional-card" @click="selected = !selected" :class="{'active' : selected}">
                    <div class="row">
                        <div class="col-md-4 col-sm-4">
                            <div class="img-wrap">
                                <img :src="professionals.avatar" class="card-img img-fluid" alt/>
                            </div>
                        </div>
                        <div class="col-md-8 col-sm-6 ">
                            <div class="card-body">
                                <h5 calss="font-weight-bold">
                                    {{ professionals.name }}
                                </h5>
                                <p class="cardp">{{ professionals.address }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p>
                    {{ $t('signup.noprofile') }}
                </p>
            </div>
        </form>
    </div>
</template>
<script>
    export default {
        name: "professionalsearch",
        props: {
            form: {
                type: Object
            }
        },
        data() {
            return {
                readonly: false,
                result: false,
                filteredProfessionals: [],
                professionals: null,
                selected: false
            };
        },
        methods: {
            toggleCheck() {
                this.form.professionalExists = !this.form.professionalExists

            },
            getProfessionals() {
                if (!this.form.professional_id) {
                    return
                }
                this.$http.get(process.env.VUE_APP_API_URL + "/professionals/findByID", {
                    params: {
                        professional_id: this.form.professional_id
                    }
                }).then((response) => {
                    this.professionals = response.data.data
                    this.form.professional_data = response.data.data
                    this.result = true
                }, () => {
                    this.professionals = [];
                    this.result = false;
                });
            },
            validation() {
                // this.$validator.validateAll();
                // let isValid = !this.errors.any();
                // this.$emit('on-validation', this.$props.form, isValid);
                // // return isValid
                return true;
            }
        },
        watch: {
            selected: function () {
                this.form.claim = this.selected ? this.form.professional_id : ''
                this.form.professionalExists = this.selected
            }
        }
    };
</script>
<style scoped>
    .professional-card.active {
        border: 2px solid #3297db;
    }

    h5 {
        font-family: Poppins;
        font-size: 24px;
    }

    p {
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
    }

    .card {
        font-family: "Poppins" !important;
        padding: 10px;
        -webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
    }

    .card p {
        text-align: left;
        font-family: Poppins;
        font-size: 15px;
        color: #979797;
    }

    .card-img {
        /*padding: 5px;*/
        /* padding-bottom: 5px; */
        border-color: transparent;
    }

    .card-body {
        padding: 0;
    }

    .card-body h5 {
        font-size: 20px;
        font-weight: 600;
        color: #2c3e50;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #e9e9f0;
        width: 100%;
        background-color: transparent;
        font-family: Poppins;
        margin: 0.5rem 0;
    }

    input:focus {
        border-color: black;
    }

    .btn-signup {
        color: #ffffff;
        background-color: #0fbc9c;
        border-color: #0fbc9c;
        font-weight: bold;
        border-radius: 1px;
        width: 100%;
    }

    .btn-signup:hover,
    .btn-signup:active,
    .btn-signup:focus,
    .btn-signup.active {
        background: #169e85;
        color: #ffffff;
        border-color: #0fbc9c;
    }

    .btn-wrap {
        padding-bottom: 30px;
    }

    .img-wrap {
        padding: 0;
        margin: 0;
    }

    .img-wrap img {
        padding: 0;
        margin: 0;
        border-radius: 10px;
    }

    .errors {
        text-align: left;
        color: red;
        font-weight: 400;
        font-size: 12px;
    }

    @media only screen and (max-width: 1024px) {
        .img-wrap img {
            width: 80px;
            height: 80px;
        }

        .col-md-4,
        .col-md-8 {
            padding: 0 10px;
        }

        .card-body h5 {
            font-size: 16px;
        }

        .card p {
            font-size: 13px;
        }
    }

    @media only screen and (max-width: 768px) {
        .img-wrap img {
            width: 80px;
            height: 80px;
        }

        .col-md-4,
        .col-md-8 {
            padding: 0 10px;
        }

        .card-body h5 {
            font-size: 16px;
            text-align: left;
        }
    }

    @media only screen and (max-width: 480px) {
        form input,
        form select {
            font-size: 12px !important;
        }

        form input::placeholder {
            font-size: 12px !important;
        }

        .col-sm-4 {
            width: 30%;
            text-align: left;
        }

        .col-sm-6,
        .col-md-8 {
            width: 70%;
        }

        .card {
            padding-bottom: 0;
        }

        .card-body h5 {
            text-align: left;
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 360px) {
        .img-wrap img {
            width: 70px;
            height: 70px;
        }

        .card-body h5 {
            font-size: 13px;
        }

        .card p {
            font-size: 11px;
        }
    }
</style>
