<template>
  <div class="professionals">
    <h3>{{ $t('professionals.index') }}</h3>
    <div class="row">
      <div
          class="col-xl-4 col-lg-4"
          v-for="professional in professionals"
          v-bind:key="professional.id"
      >
        <span v-if="clickablecard">
          <router-link :to="'/profile?professional_id='+professional.id+'&lang='+lang">
            <div class="panel card-shadow">
              <div class="img-container">
                <img :src="professional.avatar"/>
              </div>
              <h4 >
                <router-link :to="'/profile?professional_id='+professional.id+'&lang='+lang">
                  {{professionalName(professional)}}
                </router-link>
              </h4>
              <h5 v-if="professional.professionalcategories && professional.professionalcategories.length">
                <span v-for="(category, index) in professional.professionalcategories"
                      :key="'cat-'+category.id">
                  {{ professionalCategory(category) }}
                  <span v-if="index !== professional.professionalcategories.length - 1">, </span>
                </span>

              </h5>

              <div class="ratings">
                <h6>{{ professional.rating }}</h6>
                <span class="stars">
                  <i v-for="n in 5"
                     :key="'stars'+n"
                     :class="{'active fa-star' : professional.rating >= n,'fa-star-o': professional.rating < n}"
                     class="fa "/>
                </span>
                <span class="count">({{ professional.reviews.length }})</span>
              </div>
            </div>
          </router-link>

        </span>

        <span v-else>
          <div class="panel card-shadow">
            <div class="img-container">
              <img :src="professional.avatar"/>
            </div>
            <h4>
              <router-link :to="'/profile?professional_id='+professional.id+'&lang='+lang">
                {{professionalName(professional)}}
              </router-link>
            </h4>

            <h5 v-if="professional.professionalcategories && professional.professionalcategories.length">
              <span v-for="(category, index) in professional.professionalcategories"
                    :key="'cat-'+category.id">
                {{ professionalCategory(category) }}
                <span v-if="index !== professional.professionalcategories.length - 1">, </span>
              </span>

            </h5>

            <div class="ratings">
              <h6>{{ professional.rating }}</h6>
              <span class="stars">
                <i v-for="n in 5"
                   :key="'stars'+n"
                   :class="{'active fa-star' : professional.rating >= n,'fa-star-o': professional.rating < n}"
                   class="fa "/>
              </span>
              <span class="count">({{ professional.reviews.length }})</span>
            </div>
          </div>
        </span>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name    : 'profesional',
  props   : {
    lang         : {
      type   : String,
      default: 'en',
    },
    professionals: {
      type: Array,
      default() {
        return []
      },
    },
    clickablecard: {
      type   : Boolean,
      default: false,
    },
  },
  methods: {
    professionalName: function (professional) {
      if (this.lang !== 'en' && professional.multi_language_profile && professional.multi_language_profile.length) {
        const languageData = professional.multi_language_profile[0]
        if (this.lang === languageData.language) {
          return languageData.first_name + ' ' + languageData.last_name
        }
      }

      return professional.name ?? ''
    },

    professionalCategory: function (category) {
      if (this.lang !== 'en') {
        if (category && category.multi_language_professional_category && category.multi_language_professional_category.length) {
          const languageData = category.multi_language_professional_category[0]
          if (this.lang === languageData.language) {
            return languageData.title
          }
        }
      }

      return category.title ? category.title : ''
    },
  }

}
</script>
<style scoped>
a {
  color: #444;
}
</style>
