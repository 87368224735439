import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Home from "../views/Home";
import About from "../views/About";
import Contact from "../views/Contact";
import Blog from "../views/Blog";
import OfficeProfile from "../views/OfficeProfile";
import DrProfile from "../views/DrProfile";
import Login from "../views/Login";
import SignUp from "../views/SignUp";
import ThankYou from "../components/Signup/ThankYou";
import ForgetPassword from "../views/ForgetPassword";
import MessageCenter from "../views/MessageCenter";
import AccountSettings from "../views/AccountSettings";
import HowItWorks from "../views/HowItWorks";
import BlogPost from "../views/BlogPost";
import Search from "../views/Search";
import MyPatients from "../views/MyPatients";
import Notifications from "../views/Notifications";
// import NotificationTemplates from "../components/Notifications/Templates";
import Review from "../views/Review";
import DrProfileReview from "../views/DrProfileReview"
import NotFound from "../views/NotFound";
import ResetPassword from "../views/ResetPassword";
import AddOffice from "../views/AddOffice";

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        name: "about",
        path: "/about",
        component: About,
    },
    {
        name: "contact",
        path: "/contact",
        component: Contact,
    },
    {
        name: "blog",
        path: "/blog",
        component: Blog,
    },
    {
        name: "blog.article",
        path: "/blog/:id",
        component: BlogPost
    },
    {
        name: "officeprofile",
        path: "/office-profile",
        component: OfficeProfile
    },
    {
        name: "drprofile",
        path: "/profile",
        component: DrProfile
    },
    {
        name: "drprofilereview",
        path: "/dr-profile-review",
        component: DrProfileReview,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: "/signup",
        name: "signup",
        component: SignUp,
        meta: {
            guest: true
        }
    },
    {
        path: "/thankyou",
        name: "thankyou",
        component: ThankYou,
        meta: {
            guest: true
        }
    },
    {
        path: "/forget-password",
        name: "forget-password",
        component: ForgetPassword,
        meta: {
            guest: true
        }
    },
    {
        path: "/password/reset",
        name: "resetpassword",
        component: ResetPassword,
        meta: {
            guest: true
        }
    },
    {
        name: "accountsettings",
        path: "/account-settings/:profileType",
        component: AccountSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: "howitworks",
        path: "/how-it-works",
        component: HowItWorks
    },
    {
        name: "messagecenter",
        path: "/message-center/:type",
        component: MessageCenter,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'searchpage',
        path: '/search',
        component: Search,

    },
    {
        name: 'mypatients',
        path: '/my-patients',
        component: MyPatients,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'notification.index',
        path: '/notifications',
        component: Notifications,
        meta: {
            requiresAuth: true
        }

    },
    {
        name: 'notification.title',
        path: '/notifications/category/',
        component: Notifications,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'notification.templates',
        path: '/notifications/templates',
        component: Notifications,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'notification.edit',
        path: '/notifications/edit/:title',
        component: Notifications,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'notification.new',
        path: '/notifications/new/template',
        component: Notifications,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'notification.send',
        path: '/notifications/send/template',
        component: Notifications,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'review.index',
        path: '/review',
        component: Review,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'review.offices',
        path: '/review/offices',
        component: Review,
        meta: {
            requiresAuth: true
        }
    }, {
        name: 'review.offices.write',
        path: '/review/office/:name/write',
        component: Review,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'review.professionals',
        path: '/review/professionals',
        component: Review,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'review.professionals.write',
        path: '/review/professional/:name/write',
        component: Review,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'review.thanks',
        path: '/review/thank-you',
        component: Review,
        meta: {
            requiresAuth: true
        }
    },{
        name: 'office.add',
        path: '/office/add',
        component: AddOffice,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'notfound',
        path: '*',
        component: NotFound,
    },
];

const router = new VueRouter({
    routes: routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
});

router.beforeEach(beforeEach);

export default router;
