<template>
    <div v-loading="loading">
        <b-form @submit="onSubmit">
            <b-form-group id="main">
                <label for="mainCategory">{{ $t('notifications.maincategory') }}</label>
                <multiselect
                        id="mainCategory"
                        name="mainCategory"
                        v-model="form.categories.selected"
                        :options="form.categories.options"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :group-select="false"
                        track-by="id"
                        label="title"
                        @input="onChangeCategory($event)"
                        v-validate="{ required: true }">
                </multiselect>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('mainCategory')">The field is
                    required</p>
            </b-form-group>
            <b-form-group id="sub" class="mt-3">
                <label for="subCategory">{{ $t('notifications.subcategory') }}</label>
                <multiselect
                        id="subCategory"
                        name="subCategory"
                        v-model="form.subCategories.selected"
                        :options="form.subCategories.options"
                        :searchable="false"
                        :close-on-select="true"
                        :show-labels="false"
                        track-by="id"
                        label="title"
                        @input="onChangeSubCategory($event)"
                        v-validate="{ required: true }"/>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('subCategory')">The field is
                    required</p>
            </b-form-group>
            <b-form-group id="tem" class="mt-3" v-if="this.$props.email_templates">
                <label for="template">{{ $t('notifications.emailtemplate') }}</label>
                <multiselect
                        id="template"
                        name="template"
                        v-model="form.templates.selected"
                        :options="form.templates.options"
                        :searchable="false"
                        :close-on-select="true"
                        :show-labels="false"
                        :group-select="false"
                        track-by="id"
                        label="title"
                        v-validate="{ required: true }"
                        @input="onChangeTemplate($event)"/>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('template')">The email template
                    field is required</p>
            </b-form-group>
            <b-form-group class="mt-3">
                <label for="title">{{ $t('notifications.templatetitle') }}</label>
                <b-form-input
                        name="title"
                        id="title"
                        type="text"
                        v-model="form.title"
                        placeholder="Notification Title"
                        v-validate="{ required: true }"
                />
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('title')">The field is
                    required</p>
            </b-form-group>
            <b-form-group class="mt-3">
                <label for="content">{{ $t('notifications.templatecontent') }}</label>
                <vue-editor id="content" v-model="form.content" :editor-toolbar="customToolbar" name="content"
                            v-validate="{ required: true }"/>
                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors.has('content')">The field is
                    required</p>
            </b-form-group>
            <b-form-group>
                <b-col xl="6" lg="6" md="6" sm="6" offset="3">
                    <button type="submit" v-if="!this.$props.email_templates"
                            class="btn btn-success btn-block" :disabled="is_disabled">{{ $t('notifications.create') }}
                    </button>
                    <button type="submit" class="btn btn-success btn-block" :disabled="is_disabled" v-else>
                        {{ $t('notifications.update') }}
                    </button>
                </b-col>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {VueEditor} from "vue2-editor";

    export default {
        name: "NotificationTemplate",
        props: ['email_templates'],
        components: {
            Multiselect,
            VueEditor
        },
        data: () => {
            return {
                loading: false,
                is_disabled: false,
                customToolbar: [
                    [{'font': []}],
                    [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
                    [{'size': ['small', false, 'large', 'huge']}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                    [{'header': 1}, {'header': 2}],
                    ['blockquote', 'code-block'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                    [{'script': 'sub'}, {'script': 'super'}],
                    [{'indent': '-1'}, {'indent': '+1'}],
                    [{'color': []}, {'background': []}],
                    ['link', 'formula'],
                    [{'direction': 'rtl'}],
                    ['clean'],
                ],
                form: {
                    title: '',
                    content: '',
                    category_id: '',
                    sub_category_id: '',
                    categories: {
                        selected: null,
                        options: []
                    },
                    subCategories: {
                        selected: null,
                        options: []
                    },
                    templates: {
                        selected: null,
                        options: []
                    },
                    selected: null,
                    is_reply: 0
                },
            }
        },
        mounted() {
            if (this.$route.query['type'] === 'default') {
                this.getCategories(this.$route.query['main_id']);
            } else {
                this.getCustomCategories(this.$route.query['main_id']);
            }

            setTimeout(() => {
                this.loading = false
            }, 1000)
        },
        methods: {
            // default main categories
            getCategories(mainCategory) {
                this.$http.get('/notifcationcategories')
                    .then((response) => {
                        if (response.data.data.length > 0) {
                            this.form.categories.options = response.data.data;
                            this.form.categories.selected = this.$_.find(this.form.categories.options, {'id': mainCategory})

                            this.getSubCategories(mainCategory);
                        }
                    });
            },
            getCustomCategories(mainCategory) {
                this.$http.get('/notifcationcategories/custom')
                    .then((response) => {
                        if (response.data.data.length > 0) {
                            this.form.categories.options = response.data.data;
                            this.form.categories.selected = this.$_.find(this.form.categories.options, {'id': mainCategory})

                            this.getSubCategories(mainCategory);
                        }
                    });
            },
            getSubCategories(category) {
                this.form.subCategories.options = this.$_.find(this.form.categories.options, {'id': category}).sub_categories
                this.form.subCategories.selected = this.$_.find(this.form.subCategories.options, {'id': this.$route.query['sub_id']});

                if (this.$props.email_templates) {
                    this.getEmailTemplates(category, this.form.subCategories.selected.id);
                }
            },
            getEmailTemplates(mainCategory, subCategory) {
                if (this.$route.query['type'] === 'default') {
                    this.$http.get('/emailtemplates')
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                // email templates
                                this.form.templates.options = this.$_.filter(response.data.data, {
                                    'category_id': mainCategory,
                                    'sub_category_id': subCategory
                                });
                                this.form.templates.selected = this.$_.find(response.data.data, {
                                    id: this.$route.query['email'],
                                    'category_id': mainCategory,
                                    'sub_category_id': subCategory
                                });
                            }
                        });
                } else {
                    this.$http.get('/customtemplates', this.form)
                        .then((response) => {
                            if (response.data.data.length > 0) {
                                // custom email templates
                                this.form.templates.options = this.$_.filter(response.data.data, {
                                    'category_id': mainCategory,
                                    'sub_category_id': subCategory
                                });
                                this.form.templates.selected = this.$_.find(response.data.data, {
                                    id: this.$route.query['email'],
                                    'category_id': mainCategory,
                                    'sub_category_id': subCategory
                                });

                                this.form.title = this.form.templates.selected.title;
                                this.form.content = this.form.templates.selected.content;
                            }
                        });
                }
                this.form.category_id = this.form.categories.selected.id;
                this.form.sub_category_id = this.form.subCategories.selected.id;
            },
            onChangeCategory(event) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    if (event) {
                        // changing sub categories
                        this.getSubCategories(event.id);

                        // changing email templates
                        if (this.form.subCategories.selected) {
                            this.getEmailTemplates(event.id, this.form.subCategories.selected)
                        } else {
                            this.form.templates.options = this.form.templates.selected = []
                        }
                    } else {
                        this.form.subCategories.options = this.form.subCategories.selected = []
                    }
                }, 1000)
            },
            onChangeSubCategory(event) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    if (this.$props.email_templates) {
                        if (event) {
                            // changing email templates
                            this.getEmailTemplates(this.form.categories.selected.id, event.id)
                        } else {
                            this.form.templates.options = this.form.templates.selected = []
                        }
                    }
                }, 1000)
            },
            onChangeTemplate(event) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    if (event) {
                        // changing title and content
                        this.form.title = this.form.templates.selected.title;
                        this.form.content = this.form.templates.selected.content;
                    } else {
                        this.form.title = this.form.content = ''
                    }
                }, 1000)
            },
            onSubmit(evt) {
                evt.preventDefault()
                this.loading = true;
                this.is_disabled = true;
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.form.category_id = this.form.categories.selected.id;
                        this.form.sub_category_id = this.form.subCategories.selected.id;
                        if (this.$props.email_templates) {
                            this.form.id = this.form.templates.selected.id
                        } else {
                            this.form.id = '';
                        }
                        this.$http.post('/customtemplates', {
                            id: this.form.id,
                            title: this.form.title,
                            content: this.form.content,
                            category_id: this.form.category_id,
                            sub_category_id: this.form.sub_category_id,
                        })
                            .then(() => {
                                this.loading = false;
                                this.is_disabled = false;

                                if (this.$props.email_templates) {
                                    this.$message.success('Successfully updated the email template')
                                } else {
                                    this.$message.success('Successfully created the email template')
                                }
                            })
                    } else {
                        this.loading = false
                    }
                })
            }
        }
    }
</script>