<template>
    <div>
        <b-row>
            <b-col xl="6" lg="6" md="6" v-for="patient in patients" :key="patient.id">
                <b-card class="mb-3">
                    <b-row>
                        <b-col xl="3" lg="3" md="3">
                            <b-card-img :src="patient.avatar"/>
                        </b-col>
                        <b-col xl="8" lg="8" md="8">
                            <b-card-title>
                                <router-link onclick="return" to="#">{{ patient.name }}</router-link>
                            </b-card-title>
                            <b-card-text>
                                <span>{{ patient.phone_number }}</span><br/>
                                <span class="address">
                                <span class="location">{{ patient.address }}</span>
                            </span>
                            </b-card-text>
                        </b-col>
                        <b-col xl="1" lg="1" md="1" class="message">
                            <router-link v-if="patient.user_id" :to="'/message-center/new?user_id=' + patient.user_id"><i class="fa fa-envelope"/></router-link>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

        </b-row>
        <div class="text-center" v-if="!patients.length">
            <h4>No records matched</h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PatientItem",
        props: ['patients'],
    }
</script>