<template>
    <div class="section review">
        <div class="view">
            <b-container v-if="!thanksContent">
                <h3 class="text-left title">{{ title }}</h3>
                <h6 class="mb-5" v-if="indexContent">{{ subtitle }}</h6>
                <h6 class="mb-5" v-if="professionalContent">{{ subtitle }}</h6>

                <b-card class="card-shadow main">
                    <div class="alert alert-danger" :key="'error-'+key" v-for="(error, key) in errors_data"
                         role="alert">
                        {{error[0]}}
                    </div>
                    <div v-if="indexContent">
                        <ValidationObserver ref="form">
                            <b-form @submit="onSubmit">
                                <b-row>
                                    <b-col xl="6" lg="6" md="6" sm="12">
                                        <ValidationProvider name="first_name" rules="required" v-slot="{errors}">
                                            <b-form-group>
                                                <b-form-input
                                                        id="first"
                                                        type="text"
                                                        name="first_name"
                                                        v-model="formIndex.first_name"
                                                        :placeholder="$t('form.firstname')"
                                                />
                                                <p class="mt-2" style="color: #ff0000;font-size: 13px"
                                                v-if="errors[0]">{{ errors[0] }}</p>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" md="6" sm="12">
                                        <ValidationProvider name="last_name" rules="required" v-slot="{errors}">
                                            <b-form-group>
                                                <b-form-input
                                                        id="last"
                                                        type="text"
                                                        name="last_name"
                                                        v-model="formIndex.last_name"
                                                        :placeholder="$t('form.lastname')"
                                                />
                                                <p class="mt-2" style="color: #ff0000;font-size: 13px"
                                                v-if="errors[0]">{{ errors[0] }}</p>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-4">
                                    <b-col xl="6" lg="6" md="6" sm="12">
                                        <ValidationProvider name="email" rules="required|email" v-slot="{errors}">
                                            <b-form-group>
                                                <b-form-input
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        v-model="formIndex.email"
                                                        :placeholder="$t('form.email')"
                                                />
                                                <p class="mt-2" style="color: #ff0000;font-size: 13px"
                                                    v-if="errors[0]">{{ errors[0] }}</p>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" md="6" sm="12">
                                        <ValidationProvider name="phone_number" rules="required" v-slot="{errors}">
                                            <b-form-group>
                                                <b-form-input
                                                        id="phone"
                                                        type="text"
                                                        name="phone_number"
                                                        v-mask="'(###) ###-####'"
                                                        v-model="formIndex.phone_number"
                                                        :placeholder="$t('form.phone')"
                                                />
                                                <p class="mt-2" style="color: #ff0000;font-size: 13px"
                                                v-if="errors[0]">{{ errors[0] }}</p>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-form-group>
                                    <b-col xl="4" lg="4" md="4" sm="12" offset="4" class="mt-5">
                                        <button type="submit"
                                                class="btn btn-success btn-block" :disabled="disableBtn">{{ $t('form.submit') }}
                                        </button>
                                        <br>
                                        <button type="button" v-if="continueBtn"
                                                v-on:click="$router.push(routePath + routeParams).catch(err => {})"
                                                class="btn btn-success btn-block">{{ $t('form.continue') }}
                                        </button>
                                    </b-col>
                                </b-form-group>
                            </b-form>
                        </ValidationObserver>
                    </div>

                    <div v-if="officeContent" class="offices">
                        <office-items :offices="offices" :route_params="routeParams"/>
                        <div class="text-center">
                            <router-link
                                    :to="'/review/professional/' + this.user.professional.id + '/write?' + routeParams"
                                    class="btn btn-success">{{ $t('form.next') }}
                            </router-link>
                        </div>
                    </div>

                    <div v-if="professionalContent" class="offices">
                        <professional-items :selectable="true" :office="office"  :professionals="professionals" :route_params="routeParams"
                                            :page_type="true"/>
                    </div>

                    <div v-if="officeWriteContent" class="write">
                        <b-col xl="10" lg="10" md="10" offset="1">
                            <review-office :form.sync="this.formOfficeReview" :route_params="routeParams"/>
                        </b-col>
                    </div>

                    <div v-if="professionalWriteContent" class="write">
                        <b-col xl="10" lg="10" md="10" offset="1">
                            <review-professional :professionals="professionals" :form.sync="this.formOfficeReview"/>
                        </b-col>
                    </div>
                </b-card>
            </b-container>
            <div class="container thanks mt-5 mb-5" v-if="thanksContent">
                <b-col xl="6" lg="6" md="6" offset="3">
                    <b-card class="card-shadow pt-3">
                        <h3 class="text-center title">{{ title }}</h3>
                        <thanks/>
                    </b-card>
                </b-col>
            </div>
        </div>
    </div>
</template>

<script>
    import Offices from "../components/Review/Offices";
    import ReviewOffice from "../components/Review/ReviewOffice";
    import ProfessionalItem from "../components/Review/ProfessionalItem";
    import ReviewProfessional from "../components/Review/ReviewProfessional";
    import Thanks from "../components/Review/Thanks";
    import {VueMaskDirective} from 'v-mask'
    import _ from 'lodash'
import { ValidationObserver, ValidationProvider } from "vee-validate";

    // const imgPro = require('../assets/images/profile.jpg');

    export default {
        name: "Review",
        components: {
            "office-items": Offices,
            "review-office": ReviewOffice,
            "professional-items": ProfessionalItem,
            "review-professional": ReviewProfessional,
            "thanks": Thanks,
            ValidationProvider,
            ValidationObserver
        },
        data: () => {
            return {
                title: "",
                subtitle: "Fill the fields below to proceed with the survey",
                indexContent: true,
                officeContent: false,
                officeWriteContent: false,
                professionalContent: false,
                professionalWriteContent: false,
                thanksContent: false,
                continueBtn: false,
                routeParams: '',
                routePath: '/review/offices?',
                disableBtn: false,
                formIndex: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: ''
                },
                formOfficeReview: {
                    cleanliness: 0,
                    communication: 0,
                    waiting_time: 0,
                    text: '',
                    is_hidden: false,
                    patient_id: '',
                    office_id: '',
                    professional_id: null,
                    existing_patient: false,
                    type: '',
                    anonymous: 0
                },
                office: [],
                offices: [],
                professionals: [],
                errors_data: []
            }
        },
        created() {
            this.routeMethods();
        },
        computed: {
            user() {
                return this.$store.getters.user;
            }
        },
        mounted() {
            // this.title = this.$t('review.startyourreview');
            // this.subtitle = this.$t('review.description');
        },
        watch: {
            $route: function () {
                this.routeMethods();
            }
        },
        methods: {
            routeMethods() {
                // office page
                if (this.$route.name === 'review.offices') {
                    this.officePage();
                }

                // office Write page
                else if (this.$route.name === 'review.offices.write') {
                    this.officeWritePage();
                }

                // professional page
                else if (this.$route.name === 'review.professionals') {
                    this.professionalPage();
                }

                // professional Write page
                else if (this.$route.name === 'review.professionals.write') {
                    this.professionalWritePage();
                }

                // thanks page
                else if (this.$route.name === 'review.thanks') {
                    this.thanksPage();
                }

                // index page
                else {
                    this.indexPage();
                }
            },
            indexPage() {
                this.indexContent = true;
                this.officeContent = false;
                this.officeWriteContent = false;
                this.professionalContent = false;
                this.professionalWriteContent = false;
                this.thanksContent = false;

                setTimeout(() => {
                    this.myProfessional()
                },1000);


                this.title = this.$t('review.startyourreview');
                this.subtitle = this.$t('review.description');
            },
            officePage() {
                this.indexContent = false;
                this.officeContent = true;
                this.officeWriteContent = false;
                this.professionalContent = false;
                this.professionalWriteContent = false;
                this.thanksContent = false;

                //this.getProfessional()

                this.$http.get('/professionals/me')
                    .then((response) => {
                        this.offices = response.data.data.offices
                    });

                // changing title
                this.title = this.$t('review.selectoffice')

                this.routeParams = '&patient_id=' + this.$route.query.patient_id + '&existing_patient=' + this.$route.query.existing_patient
            },
            officeWritePage() {
                this.indexContent = false;
                this.officeContent = false;
                this.officeWriteContent = true;
                this.professionalContent = false;
                this.professionalWriteContent = false;
                this.thanksContent = false;

                // Setting the page title
                this.title = this.$t('review.officetitle')

                this.routeParams = '&patient_id=' + this.$route.query.patient_id + '&existing_patient=' + this.$route.query.existing_patient + '&professional_id=' + this.$route.query.professional_id
            },
            professionalPage() {
                this.indexContent = false;
                this.officeContent = false;
                this.officeWriteContent = false;
                this.professionalContent = true;
                this.professionalWriteContent = false;
                this.thanksContent = false;

                this.getOffice();

                // changing titles
                this.title = this.$t('review.professionals');
                this.subtitle = this.$t('review.professionaldescription');

                this.routeParams = '&patient_id=' + this.$route.query.patient_id + '&existing_patient=' + this.$route.query.existing_patient + '&office_id=' + this.$route.query.office_id
            },
            professionalWritePage() {
                this.indexContent = false;
                this.officeContent = false;
                this.officeWriteContent = false;
                this.professionalContent = false;
                this.professionalWriteContent = true;
                this.thanksContent = false;

                // this.getOffice();

                // changing titles
                this.title = this.$t('review.professionaltitle')
                // this.subtitle = "Please select the professional(s) that provided today’s treatment(s):"

                var pro_ids = _.split(this.$route.params.name,',');
                this.getProfessional(pro_ids[0])
            },
            thanksPage() {
                this.indexContent = false;
                this.officeContent = false;
                this.officeWriteContent = false;
                this.professionalContent = false;
                this.professionalWriteContent = false;
                this.thanksContent = true;

                // changing titles
                this.title = this.$t('review.thankyou');
            },
            getProfessional(id) {
                this.$http.get('/professionals/' + id)
                    .then((response) => {
                        this.professionals = response.data.data;

                        if (response.data.data.offices.length > 0) {
                            this.offices = response.data.data.offices
                        } else {
                            this.routePath = 'review/professional/' + this.user.professional.id + '/write?' + this.routeParams;
                            this.offices = [];
                        }
                    })
            },
            myProfessional(){
                this.$http.get('/professionals/me?lang=en')
                    .then((response) => {
                        this.professionals = response.data.data;

                        if (response.data.data.offices.length > 0) {
                            this.offices = response.data.data.offices
                        } else {
                            this.routePath = 'review/professional/' + this.user.professional.id + '/write?' + this.routeParams;
                            this.offices = [];
                        }
                    })
            },
            getOffice() {
                // setInterval(() => {
                    this.$http.get('/offices/' + this.$route.query.office_id)
                        .then((response) => {
                            this.office = response.data.data;
                            // this.title = this.office.name;
                            this.professionals = response.data.data.professionals;
                            this.routePath = 'review/professional/' + this.user.professional.id + '/write?' + this.routeParams;
                        })
                // }, 2000, this.professionals !== null || this.office !== null)
            },
            onSubmit(evt) {
                evt.preventDefault();
                this.disableBtn = true;
                this.$refs.form.validate().then(result => {
                    if (result) {
                        setTimeout(() => {
                            this.$http.post('/patients/find-or-new/', this.formIndex)
                                .then((response) => {
                                    if (response.data.data.existing_patient) {
                                        this.routeParams = "&existing_patient=true"
                                    } else {
                                        this.routeParams = "&existing_patient=false"
                                    }

                                    this.routeParams += "&patient_id=" + response.data.data.id
                                    if (this.offices.length === 0) {
                                        this.$router.push('review/professional/' + this.user.professional.id + '/write?' + this.routeParams);
                                    } else {
                                        this.$router.push(this.routePath + this.routeParams);
                                    }
                                })
                                .catch((errors) => {
                                    this.errors_data = errors.response.data.errors
                                    this.disableBtn = false;
                                })
                        }, 1000);
                    }
                    else{
                        this.disableBtn = false;
                    }
                })
            }
        },
        directives: {
            mask: VueMaskDirective
        }
    }
</script>