<template>
    <div class="section bgimg">
        <div class="mask"></div>
        <div class="close-mark" v-on:click="$router.push('/').catch(err => {})">
            <i class="fa fa-times"/>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-6 col-lg-6  col-md-6">
                    <div class="left-panel">
                        <h1>
                            {{ $t('home.title') }}
                        </h1>
                        <p class="col-md-10">
                            {{ $t('home.subtitle') }}
                        </p>
                    </div>
                </div>
                <div class="offset-xl-2 offset-lg-2" style="z-index: 999!important;"></div>
                <div class="col-xl-4 col-lg-4 col-md-6" v-loading="loading" style="z-index: 999!important;">
                    <div class="reg-panel">
                        <router-link to="/"><img class="mb-2 img-fluid" src="../assets/logos/DentaSpark.svg" alt/>
                        </router-link>
                        <p class="mt-5 mb-4">{{ $t('forgotpassword.text') }}</p>
                        <div v-if="form.msg" class="alert alert-warning">
                            {{form.msg}}
                        </div>
                        <div v-if="!$_.isEmpty(form.error_response)" class="alert alert-danger">
                            <ul>
                                <li v-for="(error, index) in form.error_response" :key="index">
                                    {{error[0]}}
                                </li>
                            </ul>
                        </div>

                        <ValidationObserver ref="form">
                            <form @submit.prevent="submit()">
                                <div class="form-group">
                                    <ValidationProvider name="email" rules="required|email" v-slot="{errors}">
                                        <input name="email" type="email" v-model="form.email"
                                            :placeholder="$t('form.emailplaceholder')"/>
                                        <span style="color: red" v-if="errors[0]">{{ errors[0]}}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="row justify-content-center">
                                    <div class="col-xl-8 col-lg-8 col-sm-12 text-left padding-top-sm">
                                        <button type="submit" class="btn btn-success btn-block">
                                            {{$t('forgotpassword.send')}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
    export default {
        name: "fogetpassword",
        components: {
    ValidationProvider,
    ValidationObserver
},
        data: () => {
            return {
                form: {
                    email: '',
                    msg: '',
                    error_response: []
                },
                loading: false
            }
        },
        methods: {
            submit() {
                this.loading = true
                this.form.msg = ''
                this.form.error_response = []
                this.$refs.form.validate().then(result => {
                    if (result) {
                        //
                        this.$http.post('/forgot-password',{
                            email : this.form.email
                        })
                        .then((response) => {
                            this.form.msg = response.data
                            this.form.email = ''
                            this.$refs.form.reset()
                            this.loading = false
                        })
                        .catch((error)=> {
                          this.form.error_response = error.response.data.errors
                            this.loading = false
                        })
                    }
                    else{
                        this.loading = false
                    }
                });
            }
        }
    };
</script>

<style scoped>
    * {
        font-family: "Poppins" !important;
    }

    .fa {
        font-family: "FontAwesome" !important;
    }

    h1 {
        color: white;
        font-size: 56px;
        font-weight: bold;
    }

    p {
        color: white;
        font-size: 16px;
        padding: 10px 0px;
    }

    .reg-panel p {
        color: #3a3a3a;
        font-weight: 500;
    }

    .bgimg {
        background: url("../assets/images/bg-imag.png") no-repeat -430px 0 fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: fixed;
        padding-top: 120px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .bgimg .mask {
        position: fixed; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.2); /* Black background with opacity */
    }

    .close-mark {
        text-align: center;
        position: absolute;
        top: 20px;
        left: 80px;
        cursor: pointer;
    }

    .close-mark i {
        font-size: 30px;
        color: #6b6b6b;
        background-color: rgba(126, 126, 126, 0.1);
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        transition: ease-out 0.3s;
    }

    .close-mark:hover > i {
        background-color: rgba(126, 126, 126, 0.2);
    }

    .login p {
        color: black;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #e9e9f0;
        width: 100%;
        background-color: transparent;
        font-family: Poppins;
        margin: 1rem 0;
    }

    input:focus {
        border-color: black;
    }

    .reg-panel .btn-success {
        padding: 10px 5px !important;
    }

    @media only screen and (max-width: 768px) {
        .bgimg {
            padding-top: 70px;
        }

        .bgimg .mask {
            background-color: rgba(255, 255, 255, 0.1); /* Black background with opacity */
        }

        .close-mark {
            top: 2px;
            left: 50px;
        }

        .reg-panel {
            text-align: center;
        }

        .img-wrap img {
            width: 100px;
            height: 100px;
        }

        .form-group span {
            color: red !important;
        }
    }

    @media only screen and (max-width: 480px) {
        .bgimg {
            background: none;
        }

        .close-mark {
            display: none;
        }

        .left-panel {
            display: none;
        }

        .col-sm-6 {
            width: 50%;
        }

        form .reg-panel img {
            max-width: 100%;
        }

        form .texts, form .texts a {
            font-size: 11px !important;
            font-weight: 500;
            line-height: 20px;
        }

        form input::placeholder {
            font-size: 12px;
        }

    }
</style>