<template>
    <div class="section bgimg">
        <div class="mask"></div>
        <div class="close-mark" v-on:click="$router.push('/').catch(err => {})">
            <i class="fa fa-times"/>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="left-panel">
                        <h1>
                            {{ $t('home.title') }}
                        </h1>
                        <p class="col-md-10">
                            {{ $t('home.subtitle') }}
                        </p>
                    </div>
                </div>
                <div class="offset-xl-2 offset-lg-2"></div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="reg-panel">
                        <router-link to="/">
                            <img class="mb-2" src="../assets/logos/DentaSpark.svg" alt/>
                        </router-link>
                        <form-wizard shape="square" @on-complete="onComplete" :nextButtonText="$t('signup.next')"
                                     :backButtonText="$t('signup.back')" :finishButtonText="$t('signup.finish')">
                            <tab-content title="Sign Up Type" :before-change="()=>validateStep('signup-type')">
                                <signup-type
                                        ref="signup-type"
                                        @on-validation="mergePartialModels"
                                        :form.sync="form"
                                />
                            </tab-content>
                            <tab-content
                                    title="Basic Form"
                                    :before-change="()=>validateStep('signup-basic-info')"
                            >
                              <div class="alert alert-danger" v-if="errorsdata">
                                <ul>
                                  <li v-for="(error, index) in errorsdata" :key="'error'+index">{{ error[0] }}</li>
                                </ul>
                              </div>
                                <signup-basic-info
                                        ref="signup-basic-info"
                                        @on-validation="mergePartialModels"
                                        :form.sync="form"
                                />
                            </tab-content>
                            <tab-content
                                    v-if="!formType"
                                    title="Office Form"
                                    :before-change="()=>validateStep('signup-office-info')"
                            >
                                <signup-office-info
                                        ref="signup-office-info"
                                        @on-validation="mergePartialModels"
                                        :form.sync="form"
                                />
                            </tab-content>
                            <!--
                        <tab-content
                          title="Address Form"
                          v-if="form.userType != 'user'"
                          :before-change="()=>validateStep('signup-address-info')"
                        >
                          <signup-address-info
                            ref="signup-address-info"
                            @on-validation="mergePartialModels"
                            :form.sync="form"
                          />
                        </tab-content>
                        -->
                            <tab-content
                                    v-if="!formType"
                                    title="Professional Form"
                                    :before-change="()=>validateStep('signup-professional-info')"
                            >
                                <signup-professional-info
                                        ref="signup-professional-info"
                                        @on-validation="mergePartialModels"
                                        :form.sync="form"
                                />
                            </tab-content>
                            <tab-content
                                    v-if="!formType && form.is_professional_available"
                                    title="Professional Search"
                                    :before-change="()=>validateStep('signup-professional-search')"
                                    :after-change="()=>searchProfessionals('signup-professional-search')"
                            >
                                <signup-professional-search
                                        ref="signup-professional-search"
                                        @on-validation="mergePartialModels"
                                        :form.sync="form"
                                />
                            </tab-content>
                            <tab-content
                                    v-if="(!formType && form.professional_data) && form.claim"
                                    title="Claim"
                                    :before-change="()=>validateStep('claim-profile')"
                            >
                                <claim-profile
                                        ref="claim-profile"
                                        @on-validation="mergePartialModels"
                                        :form.sync="form"
                                />
                            </tab-content>
                            <tab-content
                                    v-if="!formType"
                                    title="Confirmation"
                                    :before-change="()=>validateStep('account-confirmation')"
                            >
                                <account-confirmation
                                        ref="account-confirmation"
                                        @on-validation="mergePartialModels"
                                        :form.sync="form"
                                        :responseErrors="errorsdata"
                                />
                            </tab-content>
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {FormWizard, TabContent} from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";

    import SignupType from "../components/Signup/SignupType";
    import SignupBasicInfo from "../components/Signup/BasicInformation";
    import SignupProfessionalInfo from "../components/Signup/ProfessionalInfo";
    import SignupProfessionalSearch from "../components/Signup/professionalSearch";
    import SignupOfficeInfo from "../components/Signup/OfficeInfo";
    import SignupAddressInfo from "../components/Signup/AddressInfo";
    import ClaimProfile from "../components/Signup/ClaimProfile";
    import AccountConfirmation from "../components/Signup/AccountConfirmation";
    import ThankYou from "../components/Signup/ThankYou.vue";

    export default {
        name: "signup",
        components: {
            /* eslint-disable vue/no-unused-components */
            FormWizard,
            TabContent,
            "signup-type": SignupType,
            "signup-basic-info": SignupBasicInfo,
            "signup-professional-info": SignupProfessionalInfo,
            "signup-professional-search": SignupProfessionalSearch,
            "signup-office-info": SignupOfficeInfo,
            "signup-address-info": SignupAddressInfo,
            "claim-profile": ClaimProfile,
            "account-confirmation": AccountConfirmation,
            "thank-you": ThankYou
        },
        data() {
            return {
                formType: false,
                selected: "",
                finalModel: {},
                errorsdata: null,
                form: {
                    userType: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    password: "",
                    password_confirmation: "",
                    officeName: "",
                    service: "",
                    speaking_languages: "",
                    profession: "",
                    address: "",
                    apt_number: "",
                    professional_id: "",
                    services: [],
                    professions: [],
                    professionalExists: false,
                    office: [],
                    search: {
                        firstName: "",
                        lastName: "",
                        city: ""
                    },
                    claim: "",
                    claimProfile: {
                        proof: "",
                        work_email: "",
                        work_phone: "",
                        model_id: "",
                        contact_time: ""
                    },
                    contact_time: "",
                    professional_data: {},
                    is_professional_available: true
                }
            };
        },
        mounted() {
            console.log("process", process.env.VUE_APP_API_URL);
            if (this.form.userType === "user") {
                this.formType = true;
            } else if (this.form.userType === "professional") {
                this.formType = false;
            } else {
                this.formType = false;
            }
        },
        methods: {
            onComplete: function () {
                console.log(this.validateStep('signup-basic-info'));
                if (this.form.userType === "user") {
                    var loading = this.$loading();
                    this.$http
                        .post(process.env.VUE_APP_API_URL + "/patients", this.form, {
                            headers: {
                                Accept: "application/json"
                            }
                        })
                        .then(
                            () => {
                                loading.close()
                              this.$router.push({
                                name: 'thankyou',
                                query: { usertype : 'user' }
                              });
                            },
                            error => {
                                this.errorsdata = error.response.data.errors;
                                loading.close()
                            }
                        );
                } else {
                    this.form.claimProfile.model_id = this.form.professional_id;
                    this.$http
                        .post(
                            process.env.VUE_APP_API_URL + "/registerAsProfessional",
                            this.form,
                            {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        )
                        .then(
                            response => {
                                if (response.status == 201 || response.status == 200) {
                                  this.$router.push({
                                    name: 'thankyou',
                                    query: { usertype : 'professional' }
                                  });
                                }
                            },
                            error => {
                                this.errorsdata = error.response.data.errors;
                            }
                        );
                }
            },
            validateStep(name) {
                let refToValidate = this.$refs[name];
                return refToValidate.validation();
            },
            mergePartialModels(model, isValid) {
                if (isValid) {
                    if (this.form.userType === "user") {
                        this.formType = true;
                    } else if (this.form.userType === "professional") {
                        this.formType = false;
                    } else {
                        this.formType = false;
                    }
                    // merging each step model into the final model
                    this.finalModel = Object.assign({}, this.finalModel, model);
                }
            },
            searchProfessionals(name) {
                this.$refs[name].getProfessionals();
            }
        }
    };
</script>

<style scoped>
    * {
        font-family: "Poppins" !important;
    }

    .fa {
        font-family: "FontAwesome" !important;
    }

    h1 {
        color: white;
        font-size: 56px;
        font-weight: bold;
    }

    p {
        color: white;
        font-size: 16px;
        padding: 10px 0px;
    }

    .bgimg {
        background: url("../assets/images/bg-imag.png") no-repeat -430px 0 fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        padding-top: 120px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .bgimg .mask {
        position: fixed; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(
                255,
                255,
                255,
                0.2
        ); /* Black background with opacity */
    }

    .close-mark {
        text-align: center;
        position: absolute;
        top: 20px;
        left: 80px;
        cursor: pointer;
    }

    .close-mark i {
        font-size: 30px;
        color: #6b6b6b;
        background-color: rgba(126, 126, 126, 0.1);
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        transition: ease-out 0.3s;
    }

    .close-mark:hover > i {
        background-color: rgba(126, 126, 126, 0.2);
    }

    @media only screen and (max-width: 768px) {
        .bgimg {
            padding-top: 70px;
        }

        .bgimg .mask {
            background-color: rgba(
                    255,
                    255,
                    255,
                    0.1
            ); /* Black background with opacity */
        }

        .close-mark {
            top: 2px;
            left: 50px;
        }

        .reg-panel {
            text-align: center;
        }

        .img-wrap img {
            width: 100px;
            height: 100px;
        }
    }

    @media only screen and (max-width: 480px) {
        .bgimg {
            background: none;
        }

        .close-mark {
            display: none;
        }

        .left-panel {
            display: none;
        }

        .reg-panel img {
            max-width: 100%;
        }

        form input::placeholder {
            font-size: 12px !important;
        }
    }
</style>
