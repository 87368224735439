<template>
    <div>
        <div class="mb-5 profile">
            <img class="img-fluid" v-if="form_professional.avatar" :src="form_professional.avatar" id="profileImage"
                 alt="avatar">
            <img class="img-fluid" v-else src="/storage/placeholder-avatar.png" id="profileImage" alt="avatar">
            <span class="text-center" @click="chooseFiles()">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
        </div>
        <div class="row">
            <div class="col-8">

            </div>
            <div class="col-4">
                <b-form-group  :label="$t('form.profile_language')" label-for="profile_language">
                    <b-form-select @change="onLangChange" value-field="key" text-field="name" v-model="selected_langauge" :options="supported_languages"></b-form-select>
                </b-form-group>
            </div>
        </div>
        <br/>
        <br/>
        <b-form @submit.prevent="updateProfessional">
            <b-form-group id="input-group-1" :label="$t('form.firstname')" label-for="first_name">
                <b-form-input
                        id="first_name"
                        v-model="form_professional.first_name"
                        required
                />
            </b-form-group>

            <input id="fileUpload" ref="fileInput" type="file" hidden @change="setUserAvatar">

            <b-form-group id="input-group-2" :label="$t('form.lastname')" label-for="last_name">
                <b-form-input
                        id="last_name"
                        v-model="form_professional.last_name"
                        required
                />
            </b-form-group>
            <b-form-group
                    id="input-group-3"
                    label="Email address"
                    label-for="email"
            >
                <b-form-input
                        id="email"
                        v-model="form_professional.email"
                        type="email"
                        required
                />
            </b-form-group>
            <b-form-group id="input_phone" :label="$t('form.phone')" label-for="phone">
                <b-form-input
                        id="phone"
                        v-model="form_professional.phone_number"
                        required
                />
            </b-form-group>

            <b-form-group id="input_bio" :label="$t('form.bio')" label-for="bio">
                <b-form-textarea
                        id="bio"
                        rows="5"
                        v-model="form_professional.bio"
                />
            </b-form-group>

            <b-form-group id="input_school" :label="$t('form.school')" label-for="school">
                <b-form-input
                        id="school"
                        v-model="form_professional.school"
                />
            </b-form-group>

            <b-form-group id="input_year" :label="$t('form.graduationyear')" label-for="g_year">
                <multiselect
                        v-model="form_professional.graduation_year"
                        :options="getNumbers(1930)"
                        :searchable="false"
                        :close-on-select="false"
                        :show-labels="false"
                        placeholder="Pick a value"/>
            </b-form-group>

            <b-form-group id="input_lang" :label="$t('form.selectlanguages')" label-for="pro_lang">
                <multiselect
                        v-model="form_professional.speaking_languages"
                        :options="form.options.languages"
                        :multiple="true"
                        :searchable="false"
                        :placeholder="$t('form.selectlanguagesplaceholder')"/>
            </b-form-group>

            <b-form-group id="input_gender" :label="$t('form.gender')" label-for="gender">
                <multiselect
                        v-model="form_professional.gender"
                        :options="form.options.genders"
                        :searchable="false"
                        :close-on-select="false"
                        :show-labels="false"
                        :custom-label="customGenderLabel"
                        placeholder="Select Gender"
                >
                </multiselect>
            </b-form-group>

            <b-form-group id="input_services" :label="$t('form.selectservices')" label-for="services">
                <multiselect
                        v-if="selected_langauge === 'en'"
                        v-model="form_professional.services"
                        :options="form.options.services"
                        :searchable="false"
                        :close-on-select="false"
                        :show-labels="false"
                        track-by="id"
                        label="title"
                        :multiple="true"
                        :placeholder="$t('form.selectservicesplaceholder')"/>

                <span v-else>
                     <multiselect
                             v-if="!loading"
                             v-model="form_professional.services"
                             :options="form.options.services"
                             :searchable="false"
                             :taggable="true"
                             :close-on-select="false"
                             :clear-on-select="false"
                             :show-labels="true"
                             track-by="id"
                             label="title"
                             :multiple="true"
                             :custom-label="customServiceLabel"
                             :placeholder="$t('form.selectservicesplaceholder')">

                </multiselect>
                </span>


            </b-form-group>
            <b-form-group id="input_professionalcategories" :label="$t('form.selectprofessions')"
                          label-for="Professions">
                <multiselect
                        v-if="selected_langauge === 'en'"
                        v-model="form_professional.professionalcategories"
                        :options="form.options.ProfessionalServices"
                        :searchable="false"
                        :close-on-select="false"
                        :show-labels="false"
                        track-by="id"
                        label="title"
                        :multiple="true"
                        :placeholder="$t('form.selectprofessionsplaceholder')"/>
                <span v-else>
                     <multiselect
                             v-if="!loading"
                             v-model="form_professional.professionalcategories"
                             :options="form.options.ProfessionalServices"
                             :searchable="false"
                             :taggable="true"
                             :close-on-select="false"
                             :clear-on-select="false"
                             :show-labels="true"
                             track-by="id"
                             label="title"
                             :multiple="true"
                             :custom-label="customProfessionalCategoryLabel"
                             :placeholder="$t('form.selectservicesplaceholder')">

                </multiselect>
                </span>
            </b-form-group>

            <b-form-group class="mt-5">
                <b-button type="submit" variant="success">{{ $t('profile.save') }}</b-button>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
    import Multiselect from "vue-multiselect";

    const imgLink = require('../../assets/images/profile.jpg');

    export default {
        name: "Professional",
        components: {
            Multiselect
        },
        data: () => {
            return {
                proImage: imgLink,
                images: [],
                maxImage: 20,
                loading: false,
                form: {
                    options: {
                        languages: [
                            'English', 'Chinese'
                        ],
                        genders: [
                            'Male', 'Female', 'Others', 'Prefer Not to Say'
                        ],
                        services: [],
                        ProfessionalServices: []
                    }
                },
                form_professional: {
                    avatar: "../../assets/images/profile.jpg"
                },
                supported_languages: [],
                selected_langauge: 'en'
            }
        },
        mounted() {
            this.loading = true;

            this.getServices();
            this.getProfessionalServices();

            this.getProfessional();
            this.getLanguagesList();
        },
        computed: {
            user() {
                return this.$store.getters.user;
            },
        },
        methods: {
            customGenderLabel(item){
                return this.$t('profile.gendar.'+item.split(' ').join('_'));
            },
            customServiceLabel(item){
                console.log(item);
                if(item.multi_language_service){
                    if(item.multi_language_service.length){
                        return `${item.multi_language_service[0].title}`
                    }
                    return `${item.title}`
                }
                return `${item.title}`
            },
            customProfessionalCategoryLabel(item){
                if(item.multi_language_professional_category){
                    if(item.multi_language_professional_category.length){
                        return `${item.multi_language_professional_category[0].title}`
                    }
                    return `${item.title}`
                }
                return `${item.title}`
            },
            getLanguagesList(){
                this.$http.get('supportedlanguages')
                    .then((response) => {
                        this.supported_languages = response.data.data
                    })
            },
            getNumbers: function (start) {
                return new Array((parseInt(this.moment().format('YYYY')) + 1) - start).fill(start).map((n, i) => n + i);
            },
            setUserAvatar() {
                let file = this.$refs.fileInput.files[0];
                let reader = new FileReader();
                let vm = this;
                vm.form_professional.avatar = ""

                setTimeout(() => {
                    reader.onload = (e) => {
                        vm.form_professional.avatar = e.target.result;
                    };
                    reader.readAsDataURL(file);
                }, 1000)

            },
            updateProfessional() {
                this.loading = true;
                this.form_professional.services = this.$_.map(this.form_professional.services, 'id');
                this.form_professional.professionalcategories = this.$_.map(this.form_professional.professionalcategories, 'id');
                this.$http.post('/professionals/' + this.form_professional.id+'?lang='+this.selected_langauge, this.form_professional)
                    .then((response) => {
                        this.form_professional = response.data.data

                        if(response.data.data.multi_language_profile && response.data.data.multi_language_profile.length){
                            if(this.selected_langauge !== 'en'){
                                var langProf = response.data.data.multi_language_profile[0];
                                this.form_professional.first_name = langProf.first_name;
                                this.form_professional.last_name = langProf.last_name;
                                this.form_professional.bio = langProf.bio;
                                this.form_professional.phone_number = langProf.phone_number;
                                this.form_professional.school = langProf.school;
                            }
                        }else{
                            if(this.selected_langauge !== 'en'){

                                this.form_professional.first_name = '';
                                this.form_professional.last_name = '';
                                this.form_professional.bio = '';
                                this.form_professional.phone_number = '';
                                this.form_professional.school = '';

                            }
                        }

                        this.loading = false;
                        this.$message.success('Saved!')
                        this.errors = [];
                    })
                    .catch((errors) => {
                        this.loading = false;
                        this.errors = errors.response.data.errors
                        this.$message.error('Please clear errors!')
                    })
            },
            getServices() {
                this.$http.get('/services'+'?lang='+this.selected_langauge+'&limit=100')
                    .then((response) => {
                        const sorted = this.$_.orderBy(response.data.data, ['title'] ,['asc']);
                        this.form.options.services = sorted
                    })
            },
            getProfessionalServices() {
                this.$http.get('/professionalcategories'+'?lang='+this.selected_langauge+'&limit=100')
                    .then((response) => {
                        const sorted = this.$_.orderBy(response.data.data, ['title'] ,['asc']);
                        this.form.options.ProfessionalServices = sorted
                    })
            },
            getProfessional() {
                this.loading = true;
                this.$http.get('/professionals/me?lang='+this.selected_langauge)
                    .then((response) => {
                        this.form_professional = response.data.data

                        if(response.data.data.multi_language_profile && response.data.data.multi_language_profile.length){
                            if(this.selected_langauge !== 'en'){
                                var langProf = response.data.data.multi_language_profile[0];
                                this.form_professional.first_name = langProf.first_name;
                                this.form_professional.last_name = langProf.last_name;
                                this.form_professional.bio = langProf.bio;
                                this.form_professional.phone_number = langProf.phone_number;
                                this.form_professional.school = langProf.school;
                            }
                        }else{
                            if(this.selected_langauge !== 'en'){

                                this.form_professional.first_name = '';
                                this.form_professional.last_name = '';
                                this.form_professional.bio = '';
                                this.form_professional.phone_number = '';
                                this.form_professional.school = '';

                            }
                        }
                       this.loading = false;
                    })
            },
            onLangChange(){
                this.getServices();
                this.getProfessionalServices()
                this.getProfessional();
            },
            onSubmit(evt) {
                evt.preventDefault()
                alert(JSON.stringify(this.form))
            },
            chooseFiles() {
                document.getElementById("fileUpload").click()
            },
            onFileChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0]);
            },
            createImage(file) {
                /*eslint-disable no-unused-vars*/
                const proImage = new Image();
                const reader = new FileReader();
                const vm = this;

                reader.onload = (e) => {
                    vm.proImage = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            uploadImageSuccess(formData, index, fileList) {
                //console.log('data', formData, index, fileList)
                // Upload image api
                // axios.post('http://your-url-upload', formData).then(response => {
                //   console.log(response)
                // })
            },
            beforeRemove(index, done, fileList) {
                //console.log('index', index, fileList)
                var r = confirm("remove image")
                if (r == true) {
                    done()
                } else {
                    //
                }
            },
            editImage(formData, index, fileList) {
                //console.log('edit data', formData, index, fileList)
            }
        }
    }
</script>