<template>
  <div class="container">
    <div v-loading="loading">
      <h4 class="mb-5">{{ $t('home.professionalsnearme') }}</h4>
      <div v-if="hasLocation">
        <div class="row mt-3">
          <div
              class="col-xl-6 col-lg-6 col-md-6 col-sm-12"
              v-for="professional in professionals"
              v-bind:key="professional.id"
          >
            <div class="pro">
              <div class="img-container">
                <img :src="professional.avatar" class="rounded"/>
              </div>
              <div class="text-wrap">
                <h2>
                  <router-link :to="'/profile?professional_id='+professional.id">
                    {{ professionalName(professional) }}
                  </router-link>
                </h2>
                <div class="ratings">
                  <span class="stars">
                    <i v-for="n in 5"
                       :key="'stars'+n"
                       :class="{'active' : professional.rating >= n}"
                       class="fa fa-star"></i>
                  </span>
                  <span class="count">({{ professional.reviews.length }})</span>
                </div>
                <div class="clearfix"></div>
                <h6 v-if="professional.offices && professional.offices.length">
                  <span v-for="(office, index) in professional.offices"
                        :key="'office-'+office.id">
                    {{ professionalOffice(office) }}
                    <span v-if="index !== professional.offices.length - 1">, </span>
                  </span>
                </h6>
<!--                <h6>{{ officeName(professional) }}</h6>-->
                <div class="clearfix"></div>
                <h6 class="small"
                    v-if="professional.offices.length">
                  {{ professional.offices[0].address }}{{ professional.offices[0].address ? ' - ' : '' }}{{ professional.offices[0].city }}
                </h6>
                <div class="clearfix"></div>
                <h6 v-if="professional.professionalcategories && professional.professionalcategories.length">
                  <span v-for="(category, index) in professional.professionalcategories"
                        :key="'cat-'+category.id">
                    {{ professionalCategory(category) }}
                    <span v-if="index !== professional.professionalcategories.length - 1">, </span>
                  </span>
                </h6>
                <div class="clearfix"></div>
                <h5 v-if="professional.distance">{{ professional.distance.toFixed(1) }}km</h5>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>

        </div>
        <div class="clearfix"></div>
        <div class="text-center">
          <button v-on:click="$router.push('/search?sort=nearest').catch(err => {})"
                  v-if="professionals.length"
                  class="mt-5 link">
            {{ $t('home.viewmore') }}
          </button>
        </div>
      </div>
      <div v-else class="text-center">
        <h5><i class="fa fa-location-arrow"></i> {{ $t('home.oopsforprofessionals') }}</h5>
        {{ $t('home.locationturnon') }}
      </div>

    </div>


  </div>

</template>
<script>
import i18n from '@/lang'

export default {
  name: 'ProfessionalDetailCard',
  data() {
    return {
      hasLocation  : false,
      coordinates  : {},
      professionals: [],
      loading      : true,
      lang : i18n,
    }
  },
  computed: {
    DrImg() {
      return require('../../assets/images/doctor.jpg')
    },
    StarImg() {
      return require('../../assets/images/stars.png')
    },
  },
  props   : {},
  mounted() {
    this.$getLocation()
        .then(coordinates => {
          this.hasLocation = true
          this.coordinates = coordinates
          this.get()
        })
        .catch(() => {
          this.hasLocation = false
          this.loading     = false
        })

  },
  methods: {
    get() {
      this.$http.get('/professionals/near-by', {
        params: this.coordinates,
      })
          .then((response) => {
            this.professionals = response.data.data
            console.log(this.professionals)
            this.loading = false
          })
    },

    professionalName: function (professional) {
      if (this.lang.locale !== 'en' && professional.multi_language_profile.length) {
        const languageData = professional.multi_language_profile[0]
        if (this.lang.locale === languageData.language) {
          return languageData.first_name + ' ' + languageData.last_name
        }
      }

      return professional.name ?? ''
    },

    officeName: function (professional) {
      // if (this.lang.locale !== 'en' && professional.professionalcategories.length) {
      //   const category = professional.professionalcategories[0]
      //   if (category && professional.professionalcategories[0].multi_language_professional_category.length) {
      //     const languageData = professional.professionalcategories[0].multi_language_professional_category[0]
      //     if (this.lang.locale === languageData.language) {
      //       return languageData.title
      //     }
      //   }
      // }

      if (professional.offices.length && professional.offices.name) {
        return professional.offices[0].name
      }
      return ''
    },

    professionalOffice: function (office) {
      if (this.lang.locale !== 'en') {
        if (office && office.multi_language_office && office.multi_language_office.length) {
          const languageData = office.multi_language_office[0]
          if (this.lang.locale === languageData.language) {
            return languageData.name
          }
        }
      }

      return office.name ? office.name : ''
    },

    professionalCategory: function (category) {
      if (this.lang.locale !== 'en') {
        if (category && category.multi_language_professional_category && category.multi_language_professional_category.length) {
          const languageData = category.multi_language_professional_category[0]
          if (this.lang.locale === languageData.language) {
            return languageData.title
          }
        }
      }

      return category.title ? category.title : ''
    },
  },
}
</script>
<style>
.link {
  text-decoration: none !important;
  color: #3498db;
  width: 250px;
  margin: 0 auto;
  padding: 5px;
  border: 2px solid #3498db;
  text-align: center;
  border-radius: 35px;
}
</style>
