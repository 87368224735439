// https://vuex.vuejs.org/en/mutations.html
import * as TYPES from "./types";
/* eslint-disable no-param-reassign */
export default {
  [TYPES.MAIN_SET_FETCHING](state, obj) {
    state.fetching = obj.fetching;
  },
  [TYPES.MAIN_SET_MESSAGE](state, obj) {
    state.messages[obj.type] = obj.message;
  },
  [TYPES.SET_TOKEN](state, token) {
    state.token = token;
  },

  [TYPES.SET_PERMISSIONS](state, payload) {
    state.permissions = payload;
  },

  [TYPES.SET_USER](state, payload) {
    state.user = { ...state.user, ...payload };
  },

  [TYPES.SET_LANG](state, payload) {
    state.lang = payload;
  },
};
