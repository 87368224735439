<template>
    <div class="section dr-profile" v-loading="loading">
        <div class="view">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-3">
                        <div class="sidebar">
                            <div class="img-container">
                                <img class="img-fluid" :src="entity.avatar ? entity.avatar : entity.thumbnail"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-9">
                        <div class="details">
                            <div class="row">
                                <div class="col-xl-9 col-lg-9">
                                    <h2>{{entity.name}}</h2>
                                    <h6 v-if="entity.professionals">{{entity.professionals.length}} Doctors
                                        Associated</h6>
                                  <span v-if="entity.professionalcategories">
                                    <h6 v-if="entity.professionalcategories.length">
                                        <span v-for="(category, index) in entity.professionalcategories"
                                              :key="'cat-'+category.id">

                                            <span v-if="lang === 'en'">
                                              {{category.title}}
                                            </span>
                                            <span v-else>
                                              <span v-if="category.multi_language_professional_category.length">
                                                {{category.multi_language_professional_category[0].title}}
                                              </span>
                                              <span v-else>
                                                {{category.title}}
                                              </span>
                                             </span>
                                            <span
                                                v-if="index !== entity.professionalcategories.length - 1">, </span>
                                        </span>
                                    </h6>
                                  </span>


                                    <table>
                                        <tr v-if="entity.gender">
                                            <td>
                                                <i class="fa fa-user"></i>
                                            </td>
                                            <td>{{entity.gender}}</td>
                                        </tr>
                                        <tr v-if="entity.school">
                                            <td>
                                                <i class="fa fa-graduation-cap"></i>
                                            </td>
                                            <td>{{entity.school}}</td>
                                        </tr>
                                        <tr v-if="entity.speaking_languages">
                                            <td>
                                                <i class="fa fa-globe"></i>
                                            </td>
                                            <td>
                                                <span v-for="(language, index) in entity.speaking_languages"
                                                      :key="'lang-'+language">
                                                    {{language}}<span
                                                        v-if="index !== entity.speaking_languages.length - 1">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr v-if="entity.languages">
                                            <td>
                                                <i class="fa fa-globe"></i>
                                            </td>
                                            <td>
                                                <span v-for="(language, index) in entity.languages"
                                                      :key="'lang-'+language">
                                                    {{language}}<span
                                                        v-if="index !== entity.languages.length - 1">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr v-if="entity.phone_number">
                                            <td>
                                                <i class="fa fa-phone"></i>
                                            </td>
                                            <td>{{entity.phone_number}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-xl-3 col-lg-3">
                                    <div class="ratings">
                                        <h3>
                                            {{entity.rating || '0'}}
                                            <span>/5</span>
                                        </h3>
                                        <span class="stars">
                                          <i v-for="n in 5" :key="'stars'+n" :class="{'active fa-star' : entity.rating >= n,'fa-star-o': entity.rating < n}"
                                             class="fa "/>
                                          <br>
                    </span>
                                        <sub>{{entity.reviews.length}} {{$t('general.reviews')}}</sub>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="reviews">
                            <h3>Reviews</h3>
                            <div class="row">
                                <div class="col-xl-3 col-lg-3">
                                    <div class="ratings">
                                        <h3>
                                            {{entity.rating || '0'}}
                                            <span>/5</span>
                                        </h3>
                                        <span class="stars">
                                          <i v-for="n in 5" :key="'stars'+n" :class="{'active fa-star' : entity.rating >= n,'fa-star-o': entity.rating < n}"
                                             class="fa "/>
                                            <br>
                    </span>
                                        <sub>{{entity.reviews.length}} {{$t('general.reviews')}}</sub>
                                    </div>
                                </div>
                                <div class="col-xl-9 col-lg-9 mb-5">
                                    <div class="rating-details">
                                        <ul>
                                            <li class="five" :key="'detail_star'+n" v-for="n in 5">
                                                  <span class="star">
                                                        <i class="fa fa-star active"/>
                                                    <span>{{6-n}}</span>
                                                  </span>
                                                <b-progress
                                                        :value="(detail['sum_'+(6-n)+'_star'] * 100) / detail.total"/>
                                                <span class="total">
                                                    {{detail['sum_'+(6-n)+'_star']}}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="col-xl-12 col-lg-12 mb-2">
                                    <div class="comment-box">
                                        <div class="row">
                                            <div class="col-xl-11 col-lg-11">
                                                <div class="text-wrap">
                                                    <h2>{{$t('general.cleanliness')}}</h2>
                                                    <span class="stars">

                                                      <i v-for="n in 5" :key="'stars'+n" :class="{'active fa-star' : entity.cleanliness_rating >= n,'fa-star-o': entity.cleanliness_rating < n}"
                                                         class="fa "/>
                                                      <br>
                                                      </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-xl-12 col-lg-12 mb-2">
                                    <div class="comment-box">
                                        <div class="row">
                                            <div class="col-xl-11 col-lg-11">
                                                <div class="text-wrap">
                                                    <h2 class="cat_rate">{{$t('general.reasonable_waiting_time')}}</h2>
                                                    <span class="stars">
                                                      <i v-for="n in 5" :key="'stars'+n" :class="{'active fa-star' : entity.waiting_time_rating >= n,'fa-star-o': entity.waiting_time_rating < n}"
                                                         class="fa "/>
                                                      <br>
                                                      </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-xl-12 col-lg-12 mb-5">
                                    <div class="comment-box">
                                        <div class="row">
                                            <div class="col-xl-11 col-lg-11">
                                                <div class="text-wrap">
                                                    <h2>{{$t('general.goodcommunication')}}</h2>
                                                    <span class="stars">
                                                      <i v-for="n in 5" :key="'stars'+n" :class="{'active fa-star' : entity.communication_rating >= n,'fa-star-o': entity.communication_rating < n}"
                                                         class="fa "/>
                                                      <br>
                                                      </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="col-xl-12 col-lg-12" :key="'review'+review.id" v-for="review in reviews">
                                    <div class="comment-box">
                                        <div class="row">
                                            <div class="col-xl-1 col-lg-1">
                                                <div class="img-container">
                                                    <img :src="review.patient.avatar" alt/>
                                                </div>
                                            </div>
                                            <div class="col-xl-11 col-lg-11">
                                                <div class="text-wrap">
                                                    <h2 class="text-capitalize">{{review.anonymous ? '' : review.patient.first_name+' '+review.patient.last_name[0]}}</h2>
                                                    <span class="stars">
                                                    <i v-for="n in 5" :key="'stars'+n" :class="{'active fa-star' : review.average >= n,'fa-star-o': review.average < n}"
                                                       class="fa "/>
                                                      <br>
                          </span>
                                                    <p>
                                                        <span v-if="config">
                                                            <span v-if="config.value === '1'">
                                                                {{review.text}}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import i18n from "../lang";
    import {EventBus} from "../event-bus";
    import _ from 'lodash'

    export default {
        name: "drprofilereview",
        components: {},
        data: function () {
            return {
                reviews: [],
                loading: true,
                entity: {},
                detail: {},
                config: {}
            };
        },
        computed: {
            user: function () {
                return this.$store.getters.user
            }
        },
        mounted() {
            this.getConfig()
            this.getEntities();
            this.get();

            EventBus.$on('langChanged', () => {
                this.getEntities();
            })
        },
        methods: {
            getConfig(){
                this.$http.get('/configs/configurations')
                    .then((response) => {
                        this.config = response.data
                        this.config = _.find(this.config,{key: 'app.reviews_text'})
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((error) => {

                    })
            },
            getEntities() {
                this.$http.get('/' + this.$route.query.type + 's/' + this.$route.query[this.$route.query.type + '_id']+'?lang='+i18n.locale)
                    .then((response) => {
                        this.entity = response.data.data
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((error) => {

                    })
            },
            get() {
                this.loading = true;
                this.$http.get('/reviews?type=' + this.$route.query.type + '&' + this.$route.query.type + '_id=' + this.$route.query[this.$route.query.type + '_id'])
                    .then((response) => {
                        this.loading = false;
                        this.reviews = response.data.data
                        this.detail = response.data.review_detail
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((error) => {
                        this.loading = false;
                        this.$message.error('Oooops something wrong')
                    })
            },
        },
        filters: {

            reverse: function (arr) {
                return arr.reverse();
            }

        }
    };
</script>
<style scoped>
    #modal-center {
        display: none;
    }

    .stars .fa.fa-star.active {
        color: #FDCC28;
    }

    .cat_rate{
        color: #7e7e7e !important;
        font_size: 14px;
    }
</style>