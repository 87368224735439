<template>
    <div class="offices">
        <div class="all" v-if="$route.query.tab === 'office' && !$route.query.office_id">
          <multiselect
              :options-limit="300"
              @select="addmeInOffice"
              :internal-search="false"
              :clear-on-select="false"
              @search-change="debounceSearch"
              v-model="search.offices"
              :options="options.offices"
              :placeholder="$t('profile.serachOfficeClaim')" label="name" track-by="name">

            <template slot="option" slot-scope="props">
              <div class="media">
                <img class="mr-3" width="50" :src="props.option.thumbnail" alt="Generic placeholder image">
                <div class="media-body">
                  <h5 class="mt-0">{{ props.option.name }}</h5>
                  {{ props.option.address }}
                </div>
              </div>
            </template>

            <template slot="noResult" >
              <router-link to="/office/add?attachment=workspace">{{$t('profile.serachRequestnewOffice')}}</router-link>
            </template>
          </multiselect>
          <div v-loading="loading">
                <offices route="manage_office" :offices="offices"/>
                <div data-v-670ddd10="" class="row">
                    <div data-v-670ddd10="" class="mb-3 col-md-6 col-lg-6 col-xl-6">
                        <div v-if="admin_offices.length"  class="card"><!----><!----><div data-v-670ddd10="" class="card-body"><!----><!----><div data-v-670ddd10="" class="row"><div data-v-670ddd10="" class="col-sm-3 col-md-3 col-lg-3 col-xl-3"><img data-v-670ddd10="" src="http://healthy-dental-backend.test/storage/office-placeholder.png" class="card-img"></div><div data-v-670ddd10="" class="col-sm-9 col-md-9 col-lg-9 col-xl-9"><span data-v-670ddd10="" class="h4"><a data-v-670ddd10="" href="#/account-settings/professional?tab=work&amp;office_id=567b2b5b-09da-4d14-a2e5-9b07f38f1fe9" class="">{{admin_offices[0].office['name']}}</a></span><p data-v-670ddd10="" class="card-text mt-1"><span data-v-670ddd10="" class="location">{{admin_offices[0].office['address']}}</span></p></div></div></div><!----><!----></div>
                    </div>

                </div>
                <!--<offices   :offices="admin_offices[0].office"/>-->
                <span v-if="pending_offices.length">
                <hr>
                <h6 class="mb-5 text-muted">Pending Requests</h6>
                <offices :route="false"  :offices="pending_offices"/>
            </span>
                <div class="text-muted mt-4 mb-4">{{$t('manageoffice.info')}}</div>
                <div class="row">

                    <b-col xl="6" lg="6" md="6">
                        <router-link to="/office/add?attachment=ownership"
                                     class="btn btn-success btn-block"><i class="fa fa-plus"></i>&nbsp;{{ $t('profile.addnewoffice') }}
                        </router-link>
                    </b-col>
                </div>
            </div>
        </div>
        <div class="one" v-else>
            <office-item/>
        </div>

      <b-modal id="modal-1">
        <template v-slot:modal-header >
          <h5 class="text-center">{{$t('office.claimoffice')}}</h5>
        </template>
        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="outline-secondary" @click="cancel()">
            Cancel
          </b-button>
          <b-button variant="info" @click="onClick">
            Submit
          </b-button>
        </template>
        <form class="claim-form">
          <div class="alert alert-danger" role="alert" :key="'errors'+index" v-for="(error,index) in response_errors">
            {{error[0]}}
          </div>
          <h6 class="text-center pt-1 pb-2">To Claim this Office please provide below details</h6>
          <ValidationObserver ref="form">
              <div class="form-group">
                <label for="proof_1">Identity Proof 1</label>
                <b-form-file
                    id="proof_1"
                    v-model="form.proof_1"
                    ref="proof_1"
                    placeholder="Choose a file or drop here..."
                    drop-placeholder="Drop file here..."
                />
              </div>
              <div class="form-group">
                <label for="proof_2">Identity Proof 2 <small>(Optional)</small></label>
                <b-form-file
                    id="proof_2"
                    name="proof_2"
                    ref="proof_2"
                    v-model="form.proof_2"
                    placeholder="Choose a file or drop here..."
                    drop-placeholder="Drop file here..."
                />
              </div>
              <ValidationProvider name="work_email" rules="required" v-slot="{errors}">
                <div class="form-group">
                  <label for="email">Work Email</label>
                  <input name="email" v-model="form.work_email"  id="email"
                          type="email" class="form-control"
                          placeholder="Work email address">
                  <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">
                    {{errors[0]}}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider name="work_phone" rules="required" v-slot="{errors}">
                <div class="form-group">
                  <label for="number">Work Phone Number</label>
                  <input name="number" v-model="form.work_phone" id="number"
                          type="text"
                          class="form-control"
                          placeholder="Work's Main reception phone number">
                  <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{errors[0]}}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider name="contact_person" rules="required" v-slot="{errors}">
                <div class="form-group">
                  <label for="number">Contact Person Name</label>
                  <input name="number" v-model="form.contact_person" id="contact_person"
                          type="text"
                          class="form-control"
                          placeholder="Full name">
                  <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider name="contact_time" rules="required" v-slot="{errors}">
                <div class="form-group">
                  <label for="contact_time">Time</label>
                  <input name="contact_time" id="contact_time" v-model="form.contact_time" type="text"
                          class="form-control"
                          placeholder="Thu Aug 12,2019 between 3 PM and 5 PM ">
                  <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
          </ValidationObserver>
        </form>
      </b-modal>
    </div>
</template>

<script>
    import OfficeItem from "./OfficeItem";
    import Offices from "./Offices";
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import {debounce} from 'lodash';

    export default {
        name: "ManageOffices",
        components: {
            'office-item': OfficeItem,
            'offices': Offices,
            ValidationObserver,
            ValidationProvider
        },
        computed: {
            user: function () {
                return this.$store.getters.user;
            }
        },
        data: () => {
            return {
                response_errors: [],
                tabType: false,
                offices: [],
                pending_offices: [],
                loading: true,
                admin_offices: [],
              search: {
                offices: []
              },
              options: {
                offices: []
              },
              form: {
                file: null,
                email: '',
                phone_number: '',
                contact_time: '',
                proof_1: null,
                proof_2: null,
                claim_type: 'office',
                model_id: '',
                professional_id: '',
                contact_person: ''
              },
            }
        },
        created() {
            this.tabType = this.$route.query.tab === "office";
            this.debounceSearch = debounce(this.searchOffices, 500);
        },
        mounted() {
              this.getOwnedOffices()
              this. getPendingOffices()
            this.getAdminOffices()
        },
        methods:{
          getOwnedOffices() {
              this.loading = true
            this.$http.get('/offices/owned')
              .then((response) => {
                  this.offices = response.data,
                      this.loading = false
              })
          },
          getPendingOffices() {
              this.loading = true
                    this.$http.get('/officerequests/pending?attachment=ownership')
                        .then((response) => {
                            this.pending_offices = response.data.data;
                            this.loading = false
                        })


          },
            getAdminOffices() {
              this.loading = true
                    this.$http.get('/offices/get-office-administrators')
                        .then((response) => {
                            this.admin_offices = response.data.data;
                            this.loading = false
                        })


          },
          addmeInOffice(office){
            this.form.model_id = office.id;
            this.$bvModal.show('modal-1')
          },
          searchOffices(searchQuery){
            this.$http.get('/offices/search/selection?q='+searchQuery+'&unownd=true')
                .then((response) => {
                  this.options.offices = response.data
                  this.loading = false;
                })
          },
          onClick() {
            this.form.professional_id = this.user.professional.id;
            const formData = new FormData();
            this.$_.forEach(this.form,(val, key) => {
              formData.append(key, val);
            });
            this.$refs.form.validate()
                .then((result) => {
                  if (result) {
                    // this.$refs['modal-1'].hide()
                    this.$http.post('/claims', formData,
                        {
                          headers: {
                            'Content-Type': 'multipart/form-data'
                          }
                        })
                        .then(() => {
                          this.$bvModal.hide('modal-1')
                          this.office.claimed = true
                          this.$message.success('your claim has been submited.! we will contact you back soon.')
                        })
                        .catch((errors) => {
                          this.response_errors = errors.response.data.errors
                        })
                  }
                });


          },
        },
        watch: {
            $route: function () {
                this.tabType = this.$route.query.tab === "office";
            }
        }
    }
</script>
