<template>
    <div class="text-center pt-5">
        <span><i class="fa fa-check"/></span><br/>
        <h6 class="mt-5">Review Submitted</h6><br/>

        <b-row>
            <b-col xl="8" lg="8" md="8" sm="12" offset="2" class="mt-4 mb-5">
                <button type="button" v-on:click="$router.push('/review').catch(err => {})"
                        class="btn btn-success btn-block">Done
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "Thanks"
    }
</script>