// https://vuex.vuejs.org/en/state.html
import { CONFIG } from '../config'
export default {
  token: null,
  user: {},
  permissions: [],
  messages: {
    success: "",
    error: [],
    warning: "",
    validation: {}
  },
  fetching: false,
  sidebar: {
    show: true,
    clipped: true,
    mini_variant: false
  },
  app_name: CONFIG.APP.NAME,

  configs: {},

  lang: "En",

  breadcrumbs: ["dashboard.index"]
};
