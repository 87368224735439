<template>
    <div>
        <div class="row">
            <b-col xl="6" lg="6" md="6" class="mb-3" v-for="office in offices" :key="office.id">
                <b-card>
                    <b-row>
                        <b-col xl="3" lg="3" md="3" sm="3">
                            <b-card-img :src="office.thumbnail"/>
                        </b-col>
                        <b-col xl="9" lg="9" md="9" sm="9">
                                <span class="h4">
                                     <router-link
                                             :to="'/review/professionals?office_id=' + office.id + route_params">{{ office.name }}</router-link>
                                </span>
                            <b-card-text class="mt-1">
                                <span class="location">{{office.address}}</span>
                            </b-card-text>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Offices",
        props: {
            offices: {
                type: Array,
            },
            route_params: String
        }
    }
</script>