<template>
    <div>
        <b-container>
            <ValidationObserver ref="form">
                <b-form @submit.prevent="onSubmitForm">
                    <div class="row mb-5">
                        <b-col xl="8" lg="8" md="8" >
                        <b-card>
                            <b-row>
                            <b-col xl="3" lg="3" md="3" sm="3">
                                <b-card-img class="card-img" :src="office.thumbnail"/>
                            </b-col>
                            <b-col xl="9" lg="9" md="9" sm="9">
                                        <span class="h4">
                                        {{office.name }}
                                        </span>
                                <b-card-text class="mt-1">
                                <span class="location">{{office.address}}</span>
                                </b-card-text>
                            </b-col>
                            </b-row>
                        </b-card>
                        </b-col>
                    </div>
                    <b-row>
                            <b-col xl="6" lg="6" md="6" sm="12">
                                <ValidationProvider name="cleanliness" rules="required" v-slot="{errors}">
                                    <div class="content">
                                        <span class="text">{{ $t('review.cleanliness') }}</span><br/>
                                        <div class="stars">
                                            <input class="star star-5"
                                                id="cleanliness-5" type="radio" name="cleanliness" value="5"
                                                v-model="form.cleanliness"/>
                                            <label class="star star-5" for="cleanliness-5"/>
                                            <input class="star star-4" id="cleanliness-4" type="radio" name="cleanliness" value="4"
                                                v-model="form.cleanliness"/>
                                            <label class="star star-4" for="cleanliness-4"/>
                                            <input class="star star-3" id="cleanliness-3" type="radio" name="cleanliness" value="3"
                                                v-model="form.cleanliness"/>
                                            <label class="star star-3" for="cleanliness-3"/>
                                            <input class="star star-2" id="cleanliness-2" type="radio" name="cleanliness" value="2"
                                                v-model="form.cleanliness"/>
                                            <label class="star star-2" for="cleanliness-2"/>
                                            <input class="star star-1" id="cleanliness-1" type="radio" name="cleanliness" value="1"
                                                v-model="form.cleanliness"/>
                                            <label class="star star-1" for="cleanliness-1"/>
                                        </div>
                                        <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col xl="6" lg="6" md="6" sm="12">
                                <ValidationProvider name="communication" rules="required" v-slot="{errors}">
                                    <div class="content">
                                        <span class="text">{{ $t('review.communication') }}</span><br/>
                                        <div class="stars">
                                            <input class="star star-5"
                                                id="communication-5" type="radio" name="communication"
                                                value="5"
                                                v-model="form.communication"/>
                                            <label class="star star-5" for="communication-5"/>
                                            <input class="star star-4" id="communication-4" type="radio" name="communication"
                                                value="4"
                                                v-model="form.communication"/>
                                            <label class="star star-4" for="communication-4"/>
                                            <input class="star star-3" id="communication-3" type="radio" name="communication"
                                                value="3"
                                                v-model="form.communication"/>
                                            <label class="star star-3" for="communication-3"/>
                                            <input class="star star-2" id="communication-2" type="radio" name="communication"
                                                value="2"
                                                v-model="form.communication"/>
                                            <label class="star star-2" for="communication-2"/>
                                            <input class="star star-1" id="communication-1" type="radio" name="communication"
                                                value="1"
                                                v-model="form.communication"/>
                                            <label class="star star-1" for="communication-1"/>
                                        </div>
                                        <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col xl="12" lg="12" md="12" sm="12">
                                <ValidationProvider name="waiting_time" rules="required" v-slot="{errors}">
                                    <div class="content">
                                        <span class="text">{{ $t('review.reasonabletime') }}</span><br/>
                                        <div class="stars">
                                            <input class="star star-5"
                                                id="waiting_time-5" type="radio" name="waiting_time"
                                                value="5"
                                                v-model="form.waiting_time"/>
                                            <label class="star star-5" for="waiting_time-5"/>
                                            <input class="star star-4" id="waiting_time-4" type="radio" name="waiting_time"
                                                value="4"
                                                v-model="form.waiting_time"/>
                                            <label class="star star-4" for="waiting_time-4"/>
                                            <input class="star star-3" id="waiting_time-3" type="radio" name="waiting_time"
                                                value="3"
                                                v-model="form.waiting_time"/>
                                            <label class="star star-3" for="waiting_time-3"/>
                                            <input class="star star-2" id="waiting_time-2" type="radio" name="waiting_time"
                                                value="2"
                                                v-model="form.waiting_time"/>
                                            <label class="star star-2" for="waiting_time-2"/>
                                            <input class="star star-1" id="waiting_time-1" type="radio" name="waiting_time"
                                                value="1"
                                                v-model="form.waiting_time"/>
                                            <label class="star star-1" for="waiting_time-1"/>
                                        </div>
                                        <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col xl="12" lg="12" md="12" sm="12" class="mt-3">
                                <div>
                                    <b-form-textarea
                                            id="textarea"
                                            :placeholder="$t('review.commentplaceholder')"
                                            rows="4"
                                            max-rows="5"
                                            v-model="form.text"
                                            name="text"
                                    />
                                </div>
                            </b-col>
                            <b-col xl="12" lg="12" md="12" sm="12" class="mt-3 mb-5">
                                <b-form-checkbox
                                        id="check"
                                        name="checkbox-1"
                                        value="1"
                                        v-model="form.anonymous"
                                >{{ $t('review.postcommentanonymously') }}
                                </b-form-checkbox>
                            </b-col>
                            <b-col xl="6" lg="6" md="6" sm="12" offset="3">
                                <button type="submit"
                                        class="btn btn-success btn-block">{{ $t('form.submit') }}
                                </button>
                            </b-col>
                        </b-row>
                    </b-form>
            </ValidationObserver>
        </b-container>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

    export default {
        name: "reviewOffice",
        props: {
            form: {
                type: Object
            }
        },
        components: {
            ValidationObserver,
            ValidationProvider
        },
      data(){
        return {
          office: {

          }
        }
      },
        mounted() {
            // console.log(this.$props);
          this.getoffice()
        },
        methods: {
            onSubmitForm() {
                this.$refs.form.validate().then(result => {
                    if (result) {
                        this.$props.form.type = 'office'
                        this.$props.form.office_id = this.$route.params.name
                        this.$props.form.patient_id = this.$route.query.patient_id
                        //this.$props.form.professional_id = this.$route.query.professional_id
                        this.$props.form.existing_patient = this.$route.query.existing_patient
                
                        this.$http.post('/reviews/', this.$props.form)
                            .then((response) => {
                                console.log(response)
                                this.$router.push('/review/professional/' + this.$route.query.professional_id + '/write?patient_id=' + this.$route.query.patient_id + '&existing_patient=' + this.$route.query.existing_patient + '&office_id=' + this.$route.params.name);
                            })
                            .catch(() => {
                                console.log('got error');
                                this.$message.error('Something went wrong!');
                            })
                    }
                })
            },
          getoffice(){
              this.$http.get('offices/'+this.$route.params.name)
            .then((response) => {
              this.office = response.data.data
            })
          }
        }
    }
</script>

<style scoped>
    @import url('//netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');

    div.stars {
        width: 150px;
        display: inline-block;
    }

    input.star {
        display: none;
    }

    label.star {
        float: right;
        padding: 3px;
        font-size: 24px;
        color: #444;
        transition: all .2s;
        font-family: FontAwesome !important;
        cursor: pointer;
    }

    input.star:checked ~ label.star:before {
        font-family: FontAwesome !important;
        content: '\f005';
        color: #FD4;
        transition: all .25s;
    }

    input.star-5:checked ~ label.star:before {
        font-family: FontAwesome !important;
        color: #FE7;
        text-shadow: 0 0 3px #952;
    }

    input.star-1:checked ~ label.star:before {
        font-family: FontAwesome !important;
        color: #F62;
    }

    label.star:hover {
        transform: rotate(-15deg) scale(1.3);
    }

    label.star:before {
        content: '\f006';
        font-family: FontAwesome !important;
    }

    h4{
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 600;
      color: #2C3E50;
    }
</style>