<template>
  <div class="photos" v-if="photos.length">
    <h3>Office Photos</h3>
    <div id="carousel-custom" class="carousel slide" data-ride="carousel">
      <div class="carousel-outer">
        <!-- Wrapper for slides -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <a :href="photos[0].url" data-fancybox="photos" >
              <img :src="photos[0].url" alt />
            </a>
          </div>
          <div class="carousel-item" :key="photo.id" v-for="photo in photos">
            <a :href="photos[0].url" data-fancybox="photos" >
            <img :src="photo.url" alt />
            </a>
          </div>
        </div>

        <!-- Controls -->
        <a class="left carousel-control" href="#carousel-custom" data-slide="prev">
          <span class="glyphicon glyphicon-chevron-left"></span>
        </a>
        <a class="right carousel-control" href="#carousel-custom" data-slide="next">
          <span class="glyphicon glyphicon-chevron-right"></span>
        </a>
      </div>

      <!-- Indicators -->
      <ol class="carousel-indicators mCustomScrollbar">
        <li :key="'indi-'+photo.id"  v-for="photo in photos" data-target="#carousel-custom" data-slide-to="0" class="active">
          <a :href="photo.url" data-fancybox="photos" >
            <img :src="photo.url" alt />
          </a>
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
  import {CONFIG} from "../../config";
export default {
  name: "photos",
  props:{
    photos: {
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      type: Array
    }
  },
  data() {
    return {
      config: CONFIG
    }
  }
};
</script>
<style scoped>
  #carousel-custom .carousel-indicators li img{
    width: auto !important;
    height: auto !important;
  }
</style>