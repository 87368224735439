<template>
    <div>
        <p class="pb-3">{{ $t('signup.type') }}</p>
        <div class="signup-form">
            <div
                    id="user"
                    v-on:click="onSelect($event)"
                    class="img-container"
                    :class="{ 'user-selected': user_selected == 1,}"
            >
                <img v-if="user_selected == 1" src="../../assets/logos/selected_user.png" alt/>
                <img v-else src="../../assets/logos/unselected_user.png" alt/>
                <p :class="{ 'user-selected_txt': user_selected == 1,}">{{ $t('signup.user') }}</p>
            </div>

            <div
                    id="professional"
                    v-on:click="onSelect($event)"
                    class="img-container"
                    :class="{ 'user-selected': user_selected == 2,}"
            >
                <img v-if="user_selected == 2" src="../../assets/logos/unselected_professional.png" alt/>
                <img v-else src="../../assets/logos/selected_professional.png" alt/>
                <p :class="{ 'user-selected_txt': user_selected == 2,}">{{ $t('signup.professional') }}</p>
            </div>
        </div>
        <p class="errors" v-if="selected">Select User Type</p>
        <div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "signuptype",
        props: {
            form: {
                type: Object
            }
        },
        data: () => {
            return {
                selected: false,
                user_selected: 0,
                signup_type: "professional",
            }
        },
        methods: {
            onSelect: function (event) {
                this.signup_type = event.currentTarget.id;
                if (this.signup_type === "user") {
                    this.user_selected = 1;
                    this.selected = false;
                    this.$props.form.userType = "user";

                } else if (this.signup_type === "professional") {
                    this.user_selected = 2;
                    this.selected = false;

                    this.$props.form.userType = "professional";
                }
            },
            validation() {
                let isValid = this.signup_type !== '' && this.user_selected !== 0;
                this.selected = isValid === false
                this.$emit('on-validation', this.$props.form, isValid);
                // return isValid
                return isValid;
            }
        },
    };
</script>
<style scoped>
    p {
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
    }

    .signup-form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .signup-form .img-container {
        -webkit-box-shadow: 0px 16px 18px -11px rgba(207, 207, 207, 1);
        -moz-box-shadow: 0px 16px 18px -11px rgba(207, 207, 207, 1);
        box-shadow: 0px 16px 18px -11px rgba(207, 207, 207, 1);
        cursor: pointer;
        background-color: #fff;
        height: 140px;
        width: 140px;
        padding: 16px;
        color: black;
        text-align: center;
        border-radius: 10px;
        margin: 20px;
    }

    .signup-form .img-container p {
        padding-top: 10px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #3297DB;
    }

    .user-selected {
        background-color: #3498db !important;
    }

    .user-selected_txt {
        color: white !important;
    }

    /* .btn-size {
      width: 100%;
      background-color: #0fbc9c;
      border-color: #0fbc9c;
    } */

    .btn-wrap {
        padding-bottom: 30px;
    }

    .errors {
        text-align: center;
        color: red;
        font-weight: 400;
        font-size: 12px;
    }

    .btn-signup {
        color: #ffffff;
        background-color: #0fbc9c;
        border-color: #0fbc9c;
        font-weight: bold;
        border-radius: 1px;
        width: 100%;
    }

    .btn-signup:hover,
    .btn-signup:active,
    .btn-signup:focus,
    .btn-signup.active {
        background: #169e85;
        color: #ffffff;
        border-color: #0fbc9c;
    }
</style>
