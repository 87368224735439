<template>
    <div class="posts">
        <b-row class="mt-5">
            <b-col xl="6" lg="6" sm="12" v-for="post in posts" :key="post.id">
                <b-card
                        class="mb-5"
                        :title="post.title"
                        :img-src="Base_URL+'/storage/blogs/'+post.thumbnail"
                        :img-alt="post.title"
                >
                    <div class="labels mb-3">
                        <!--span>
                            {{ post.author }}
                        </span-->
                        <span>
                            <i class="fa fa-circle" aria-expanded="true"/>
                        </span>
                        <span>
                           {{ moment(post.created_at).format('MMM D, YYYY')}}
                        </span>
                    </div>
                    <b-card-text>
                        {{ post.description }}
                    </b-card-text>
                    <!--b-button :to="'/blog/' + post.title.toLowerCase().replace(' ', '-')" variant="success">Read More</b-button-->
                    <b-button :to="'/blog/' + post.id + '?lang='+ lang.locale" variant="success">Read More</b-button>
                </b-card>
            </b-col>
        </b-row>
        <div class="text-center" v-if="!posts.length && searching">
            <h4>No Matched Records Found</h4>
        </div>
        <div class="text-center m-5" v-if="!posts.length && !searching">
            <h4>There is no blogs/news/videos at the Moment.</h4>
        </div>
    </div>
</template>

<script>
    import i18n from "../../lang";

    export default {
        name: "BlogItem",
        props: {
            posts: {
                type: Array,
                default() {
                    return [];
                }
            },
            searching: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        mounted() {
          console.log(process.env.VUE_APP_Base_URL)
        },
        data(){
            return {
                Base_URL: process.env.VUE_APP_Base_URL,
                lang: i18n
            }
        },
    }
</script>