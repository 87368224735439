export const MAIN_SET_MESSAGE = "MAIN_SET_MESSAGE";
export const MAIN_SET_FETCHING = "MAIN_SET_FETCHING";

/*
 * Side Bar
 */
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_SIDEBAR_CLIPPED = "TOGGLE_SIDEBAR_CLIPPED";
export const TOGGLE_SIDEBAR_MINI_VARIANT = "TOGGLE_SIDEBAR_MINI_VARIANT";

export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
export const SET_CONFIGS = "SET_CONFIGS";

/*
 * Auth
 */
export const SET_USER = "SET_USER";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_TOKEN = "SET_TOKEN";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_LANG = "SET_LANG";
