<template>
    <div class="hours" v-if="$_.isObject(working_hours)">
        <h6>Working Hours</h6>
        <table class="working-times-table">
            <tr>
                <th>Mon</th>
                <td v-if="working_hours.monday[0].isOpen">{{moment(working_hours.monday[0].open, "hhmm").format('LT')}}
                    - {{moment(working_hours.monday[0].close, "hhmm").format('LT')}}
                </td>
                <td v-else>Closed</td>
            </tr>
            <tr>
                <th>Tue</th>
                <td v-if="working_hours.tuesday[0].isOpen">{{moment(working_hours.tuesday[0].open,
                    "hhmm").format('LT')}} - {{moment(working_hours.tuesday[0].close, "hhmm").format('LT')}}
                </td>
                <td v-else>Closed</td>
            </tr>
            <tr>
                <th>Wed</th>
                <td v-if="working_hours.wednesday[0].isOpen">{{moment(working_hours.wednesday[0].open,
                    "hhmm").format('LT')}} - {{moment(working_hours.wednesday[0].close, "hhmm").format('LT')}}
                </td>
                <td v-else>Closed</td>
            </tr>
            <tr>
                <th>Thu</th>
                <td v-if="working_hours.thursday[0].isOpen">{{moment(working_hours.thursday[0].open,
                    "hhmm").format('LT')}} - {{moment(working_hours.thursday[0].close, "hhmm").format('LT')}}
                </td>
                <td v-else>Closed</td>
            </tr>
            <tr>
                <th>Fri</th>
                <td v-if="working_hours.friday[0].isOpen">{{moment(working_hours.friday[0].open, "hhmm").format('LT')}}
                    - {{moment(working_hours.friday[0].close, "hhmm").format('LT')}}
                </td>
                <td v-else>Closed</td>
            </tr>
            <tr>
                <th>Sat</th>
                <td v-if="working_hours.saturday[0].isOpen">{{moment(working_hours.saturday[0].open,
                    "hhmm").format('LT')}} - {{moment(working_hours.saturday[0].close, "hhmm").format('LT')}}
                </td>
                <td v-else>Closed</td>
            </tr>
            <tr>
                <th>Sun</th>
                <td v-if="working_hours.sunday[0].isOpen">{{moment(working_hours.sunday[0].open, "hhmm").format('LT')}}
                    - {{moment(working_hours.sunday[0].close, "hhmm").format('LT')}}
                </td>
                <td v-else>Closed</td>
            </tr>
        </table>
    </div>
</template>
<script>
    export default {
        name: "workinghours",
        props: {
            working_hours: {
                type: Object,
                default() {
                    return [];
                }
            }
        },
        mounted() {
        }
    };
</script>