<template>
    <div class="section account-settings">
        <div class="view">
            <div class="container">
                <h3 class=" text-left title">{{ $t('profile.accountsettings') }}</h3>
                <!--                :class="Object.keys($route.query).length === 0 ? 'active' : ''"-->
                <b-card class="card-shadow main" no-body>
                    <b-tabs pills vertical v-model="tabIndex">
                        <b-tab :title="$t('profile.profile')">
                            <b-card-text>
                                <patient v-if="profile_type"/>
                                <professional v-else></professional>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="!profile_type" :title="$t('profile.managemywork')"
                               :class="{'active': $route.query.tab === 'work'}"
                               v-on:click="$router.push('/account-settings/professional?tab=work')"
                               >
                            <manage-work/>
                        </b-tab>
                        <b-tab
                               v-if="!profile_type" :title="$t('profile.manageoffices')"
                               :class="{'active': $route.query.tab === 'office'}"
                               v-on:click="$router.push('/account-settings/professional?tab=office')"
                        >
                            <manage-offices v-if="officeType"/>

                            <div v-else>
                                <h6>{{ $t('profile.addnewoffice') }}</h6>
                                <hr>
                                <office-form/>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('profile.changepassword')">
                            <b-card-text v-loading="loading">
                                <div v-for="(error,index) in response_errors" :key="index" class="alert alert-danger" role="alert">
                                    {{error[0]}}
                                </div>
                                <b-form @submit.prevent="changePassword">
                                    <b-form-group id="input-group-63" :label="$t('profile.currentpassword')" label-for="old-password">
                                        <b-form-input
                                                id="oldpassword"
                                                type="password"
                                                v-model="form_password.old_password"
                                                required
                                        />
                                    </b-form-group>
                                    <b-form-group id="input-group-61" :label="$t('profile.newpassword')" label-for="password">
                                        <b-form-input
                                                id="password"
                                                type="password"
                                                v-model="form_password.password"
                                                required
                                        />
                                    </b-form-group>
                                    <b-form-group id="input-group-7" :label="$t('profile.verifypassword')"
                                                  label-for="password_confirmation">
                                        <b-form-input
                                                id="password_confirmation"
                                                type="password"
                                                v-model="form_password.password_confirmation"
                                                required
                                        />
                                    </b-form-group>

                                    <b-form-group class="mt-5">
                                        <b-button type="submit" variant="success">{{ $t('profile.save') }}</b-button>
                                    </b-form-group>
                                </b-form>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('profile.changelanguage')">
                            <b-card-text>
                                <b-form @submit="onSubmit">
                                    <b-form-group>
                                        <b-form-radio-group
                                                v-model="languages.selected"
                                                :options="languages.options"
                                                name="radio-inline"
                                        />
                                    </b-form-group>

                                    <b-form-group class="mt-5">
                                        <b-button type="submit" variant="success">{{ $t('profile.save') }}</b-button>
                                    </b-form-group>

                                </b-form>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    /*eslint-env jquery*/
    import Patient from "../components/AccountSettings/Patient";
    import Professional from "../components/AccountSettings/Professional";
    import ManageOffices from "../components/AccountSettings/ManageOffices";
    import OfficeForm from "../components/AccountSettings/OfficeForm";
    import ManageWork from "../components/AccountSettings/ManageWork";
    import i18n from "../lang";
    import {http} from "../plugins/http";

    export default {
        name: "AccountSettings",
        components: {
            "patient": Patient,
            "professional": Professional,
            "manage-offices": ManageOffices,
            "office-form": OfficeForm,
            "manage-work": ManageWork
        },
        data() {
            return {
                tabIndex: 0,
                profile_type: this.$route.params.profileType === "profile",
                officeType: true,
                form_password: {
                    old_password: '',
                    password: '',
                    password_confirmation: ''
                },
                response_errors: [],
                loading: false,
                languages: {
                    selected: 'english',
                    options: [
                        {text: 'English', value: 'english'},
                        {text: 'Chinese', value: 'chinese'}
                    ]
                }
            }
        },
        computed: {
            user() {
                return this.$store.getters.user;
            }
        },
        created() {
            if (this.$route.query.tab === 'work') {
                this.tabIndex = 1;
            } else if (this.$route.query.tab === 'office') {
                this.tabIndex = 2;
            } else {
                this.tabIndex = 0;
            }

           setTimeout(() => {
               this.languages.selected = this.user.lang_preference;
           },2000)
        },
        watch: {
            $route: function () {
                if (this.$route.query.tab === "office" && this.$route.query.type === 'new') {
                    this.officeType = false;
                } else {
                    this.officeType = true;
                }

                this.profile_type = this.$route.params.profileType === "profile";
            }
        },
        methods: {
            changePassword(){
                this.loading = true;
                this.$http.post('/user/update-password', this.form_password)
                .then(() => {
                    this.loading = false;
                    this.$message.success('Saved!')
                    this.response_errors = [];
                    // console.log(response);
                    this.form_password = {
                        old_password: '',
                            password: '',
                            password_confirmation: ''
                    }
                })
                .catch((errors) => {
                    this.loading = false;
                    this.response_errors = errors.response.data.errors
                    this.$message.error('Please fix the errors!')
                    // console.log(errors);
                })
            },
            onSubmit(evt) {
                evt.preventDefault()
                // alert(JSON.stringify(this.form))
                this.$http.post('/user/lang-preference',{lang_preference: this.languages.selected})
                .then(() => {
                    this.$message.success('preference has been saved!')
                    if(this.languages.selected == 'chinese'){
                        this.$store.dispatch('setLang', 'CH');
                        i18n.locale = 'zh';
                        http.defaults.headers.common['Accept-Language'] = 'zh';
                        document.querySelector('html').setAttribute('lang', 'zh');

                    }else{
                        this.$store.dispatch('setLang', 'EN');
                        i18n.locale = 'en';
                        http.defaults.headers.common['Accept-Language'] = 'en';
                        document.querySelector('html').setAttribute('lang', 'en');
                    }
                })
            },
            chooseFiles() {
                document.getElementById("fileUpload").click()
            },
            onFileChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0]);
            },
            createImage(file) {
                /*eslint-disable no-unused-vars*/
                const proImage = new Image();
                const reader = new FileReader();
                const vm = this;

                reader.onload = (e) => {
                    vm.proImage = e.target.result;
                };
                reader.readAsDataURL(file);
            },
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
