<template>
  <div>
    <p style="margin: 20px 0;">please fill the information below</p>
    <ValidationObserver ref="form">
      <form>
        <div class="form-group">
          <ValidationProvider name="address" rules="required" v-slot="{errors}">
            <input
              v-model="form.address"
              name="address"
              type="text"
              placeholder="Type your address"
            />
            <p class="errors" v-if="errors[0]">{{ errors[0]}}</p>
          </ValidationProvider>
        </div>
        <div>
        
        </div>
        <div class="form-group">
          <ValidationProvider name="Apt Number" rules="required" v-slot="{errors}">
            <input
              v-model="form.apt_number"
              name="Apt Number"
              type="text"
              placeholder="Suite/Unit"
            />
            <p class="errors" v-if="errors[0]">{{ errors[0]}}</p>
          </ValidationProvider>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
export default {
  name: "addressinfo",
  components: {
    ValidationProvider,
    ValidationObserver
},
  props: {
    form: {
      type: Object
    }
  },
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: {},
      places: [],
      countries: [
        { id: 1, country: "pakistan" },
        { id: 2, country: "USA" },
        { id: 3, country: "UK" },
        { id: 4, country: "india" }
      ],
      cities: [
        { id: 1, city: "multan" },
        { id: 2, city: "karachi" },
        { id: 3, city: "Lahore" },
        { id: 4, city: "peshawar" }
      ]
    };
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    async validation() {
     let isValid = await this.$refs.form.validate();
      this.$emit("on-validation", this.$props.form, isValid);
      // return isValid
      return isValid;
    }
  }
};
</script>
<style scoped>
p {
  text-align: center;
  font-family: poppine;
  font-size: 16px;
}

input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #e9e9f0;
  width: 100%;
  background-color: transparent;
  font-family: Poppins;
  margin: 0.2rem 0;
}

input:focus {
  border-color: black;
}

.btn-signup {
  color: #ffffff;
  background-color: #0fbc9c;
  border-color: #0fbc9c;
  font-weight: bold;
  border-radius: 1px;
  width: 100%;
}

.btn-signup:hover,
.btn-signup:active,
.btn-signup:focus,
.btn-signup.active {
  background: #169e85;
  color: #ffffff;
  border-color: #0fbc9c;
}

select {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #e9e9f0;
  width: 100%;
  background-color: transparent;
  font-family: Poppins;
  margin: 0.2rem 0;
}

select:focus {
  border-color: black;
}

.btn-wrap {
  padding-bottom: 30px;
}

.errors {
  text-align: left;
  color: red;
  font-weight: 400;
  font-size: 12px;
}

@media only screen and (max-width: 480px) {
  form input,
  form select {
    font-size: 12px !important;
  }

  form input::placeholder {
    font-size: 12px !important;
  }
}
</style>
