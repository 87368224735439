<template>
  <div class="section dr-profile" v-loading="loading">
    <div class="view" v-if="professional">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3">
            <div class="sidebar">
              <div class="img-container">
                <img class="img-fluid" :src="professional.avatar"/>
              </div>
              <div class="btn-set mt-3">
                <button class="message"
                        v-on:click="$router.push('/message-center/new?user_id=' + professional.user_id)">
                  <i class="fa fa-envelope"></i>
                  <span>{{ $t('drprofile.sendmessage') }}</span>
                </button>
                <b-button v-if="professional.offices && professional.offices.length > 0"
                          v-b-modal.modal-1
                          variant="default"
                          class="location btn-block">
                  <span class="pr-4">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                  </span> {{ $t('drprofile.officelocation') }}
                </b-button>

                <!-- office locations map -->
                <b-modal id="modal-1" ref="my-modal" size="xl" :hide-footer="true" :hide-header="true">
                  <button type="button" class="close" @click="hideModal">X</button>

                  <div class="gmap">
                    <g-map :center="{ lat: 10, lng: 10 }"
                           :search_items="professional.offices"/>
                  </div>
                </b-modal>

              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9">
            <div class="details">
              <div class="row">
                <div class="col-xl-9 col-lg-9">
                  <h2>{{ professionalName(professional) }}</h2>

                  <h6 v-if="professional.professionalcategories && professional.professionalcategories.length">
                    <span v-for="(category, index) in professional.professionalcategories"
                          :key="'cat-'+category.id">
                      {{ professionalCategory(category) }}
                      <span v-if="index !== professional.professionalcategories.length - 1">, </span>
                    </span>
                  </h6>
                  <table>
                    <tr v-if="professional.gender">
                      <td>
                        <i class="fa fa-user"></i>
                      </td>
                      <td>{{ professional.gender }}</td>
                    </tr>
                    <tr v-if="professional.school">
                      <td>
                        <i class="fa fa-graduation-cap"></i>
                      </td>
                      <td>
                        <span>{{ professionalSchool(professional) }}</span>
                        <!--                        <span v-if="lang === 'en'">-->
                        <!--                          {{ professional.school }}-->
                        <!--                        </span>-->
                        <!--                        <span v-else>-->
                        <!--                          {{ professional.multi_language_profile[0].school }}-->
                        <!--                        </span>-->
                      </td>
                    </tr>
                    <tr v-if="professional.speaking_languages">
                      <td>
                        <i class="fa fa-globe"></i>
                      </td>
                      <td>
                        <span v-for="(language, index) in professional.speaking_languages"
                              :key="'lang-'+language">
                          {{ language }}<span
                            v-if="index !== professional.speaking_languages.length - 1">, </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="lang === 'en' &&  professional.phone_number">
                      <td>
                        <i class="fa fa-phone"></i>
                      </td>
                      <td>
                        {{ professional.phone_number ? professional.phone_number : '' }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-3 col-lg-3">
                  <div class="ratings text-center"
                       v-on:click="$router.push('/dr-profile-review?type=professional&professional_id='+professional.id).catch(err => {})">
                    <h3>
                      {{ professional.rating }}
                      <span>/5</span>
                    </h3>
                    <span class="stars">
                      <i v-for="n in 5"
                         :key="'stars'+n"
                         :class="{'active fa-star' : professional.rating >= n,'fa-star-o': professional.rating < n}"
                         class="fa "/>
                      <br>
                    </span>
                    <sub>{{ professional.reviews ? professional.reviews.length : 0 }} {{ $t('general.reviews') }}</sub>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="professional.bio" class="overview">
              <h3>{{ $t('drprofile.overview') }}</h3>
              <p class="text-justify">
                <span v-if="lang !== 'en' && professional.multi_language_profile && professional.multi_language_profile.length">
                  {{ professional.multi_language_profile[0].bio }}
                </span>
                <span v-else>
                  {{ professional.bio }}
                </span>

              </p>
            </div>

            <services v-if="professional.services && professional.services.length"
                      :lang="lang"
                      :services="professional.services"
                      requester="professional"
            ></services>
            <!--            <workinghours v-if=" professional.offices && professional.offices.length"-->
            <!--                          :lang="lang"-->
            <!--                          :offices="professional.offices"></workinghours>-->
            <offices-card v-if=" professional.offices && professional.offices.length"
                          :lang="lang"
                          :offices="professional.offices"></offices-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Services from '../components/OfficeProfile/Services'
import GmapMap from '../components/Search/GmapMap'
import i18n from '../lang'
import { EventBus } from '../event-bus'
import OfficesCard from '@/components/OfficeProfile/OfficesCard'

export default {
  name      : 'drprofile',
  components: {
    OfficesCard,
    services    : Services,
    'g-map'     : GmapMap,
  },
  data() {
    return {
      professional: {},
      loading     : false,
      lang        : this.$route.query.lang ? this.$route.query.lang : i18n.locale,
    }
  },
  mounted() {
    // setTimeout(function(){
    //   if(this.$route.query.lang ? this.$route.query.lang : i18n.locale === 'zh'){
    //     this.lang = 'zh';
    //   }
    // }, 3000);

    this.get()
    console.log(this)
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      console.log('Modal is about to be shown', bvEvent, modalId)
    })

    EventBus.$on('langChanged', () => {
      this.loading = true
      this.lang    = i18n.locale
      this.get()
    })
  },
  methods: {
    get() {
      this.loading = true
      this.$http.get('/professionals/' + this.$route.query.professional_id)
          .then((response) => {
            this.professional = response.data.data
            this.loading      = false
          })
          .catch(() => {
            this.loading = false
          })
    },

    hideModal() {
      this.$refs['my-modal'].hide()
    },

    professionalName: function (professional) {
      if (this.lang !== 'en' && professional.multi_language_profile && professional.multi_language_profile.length) {
        const languageData = professional.multi_language_profile[0]
        if (this.lang === languageData.language) {
          return languageData.first_name + ' ' + languageData.last_name
        }
      }

      return professional.name ? professional.name : ''
    },

    professionalSchool: function (professional) {
      if (this.lang !== 'en' && professional.multi_language_profile && professional.multi_language_profile.length) {
        const languageData = professional.multi_language_profile[0]
        if (this.lang === languageData.language) {
          return languageData.school ? languageData.school : professional.school
        }
      }

      return professional.school ? professional.school : ''
    },

    professionalCategory: function (category) {
      if (this.lang !== 'en') {
        if (category && category.multi_language_professional_category && category.multi_language_professional_category.length) {
          const languageData = category.multi_language_professional_category[0]
          if (this.lang === languageData.language) {
            return languageData.title
          }
        }
      }

      return category.title ? category.title : ''
    },

    professionalOffice: function (office) {
      if (this.lang !== 'en') {
        if (office && office.multi_language_office && office.multi_language_office.length) {
          const languageData = office.multi_language_category[0]
          if (this.lang === languageData.language) {
            return languageData.name
          }
        }
      }

      return office.title ? office.title : ''
    },
  },
}
</script>
<style scoped>
#modal-center {
  display: none;
}

.stars .fa.fa-star {
  font-size: 25px;
}

.stars .fa.fa-star.active {
  color: #FDCC28;
}


/deep/ #modal-1___BV_modal_content_ {
  /*border: none;*/
  background-color: transparent !important;
  -webkit-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.1);
  margin-top: 100px !important;
  border-radius: 10px !important;
}

/deep/ #modal-1___BV_modal_content_ .modal-body {
  padding: 0;
}

/deep/ #modal-1___BV_modal_content_ .modal-body .gmap {
  border-radius: 10px !important;
}

@media (min-width: 992px) {
  .modal-lg {
    display: none;
  }
}
</style>
