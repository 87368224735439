<template>
    <div>
        <div class="alert alert-danger" v-if="responseErrors">
            <ul>
                <li v-for="(error, index) in responseErrors" :key="'error'+index">{{ error[0] }}</li>
            </ul>
        </div>
        <p>
            {{ $t('signup.contacttext') }}
        </p>
        <ValidationObserver ref="form">
            <form>
                <div class="form-group">
                    <ValidationProvider name="contact_person" rules="required" v-slot="{errors}">
                        <input
                            name="contact_person"
                            v-model="form.claimProfile.contact_person"
                            type="text"
                            :placeholder="$t('form.contactname')"
                        />
                        <p class="errors" v-if="errors[0]">Contact person name is required</p>
                    </ValidationProvider>
                    <div class="form-group">
                        <ValidationProvider name="phoneNumber" rules="required" v-slot="{errors}">
                            <input
                                v-mask="'(###) ###-####'"
                                v-model="form.contact_phone_number"
                                type="text"
                                name="phoneNumber"
                                :placeholder="$t('form.phoneplaceholder')"
                            />
                            <p class="errors" v-if="errors[0]">Phone number field is required</p>
                        </ValidationProvider>
                    </div>
                    <div class="form-group">
                        <ValidationProvider name="emailAddress" rules="required" v-slot="{errors}">
                            <input
                                v-model="form.contact_email"
                                type="email"
                                name="emailAddress"
                                :placeholder="$t('form.emailplaceholder')"
                        />
                        <p class="errors" v-if="errors[0]">Email Address field is required</p>
                        </ValidationProvider>
                    </div>

                    <ValidationProvider name="AccountConfirmationTime" rules="required" v-slot="{errors}">
                        <input
                            name="AccountConfirmationTime"
                            v-model="form.contact_time"
                            type="text"
                            :placeholder="$t('form.contacttime')"
                        />
                        <p class="errors" v-if="errors[0]">Account confirmation time is required</p>
                    </ValidationProvider>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
    import {VueMaskDirective} from 'v-mask'
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    export default {
        name: "accountconfirmation",
        components: {
    ValidationProvider,
    ValidationObserver
},
        props: {
            form: {
                type: Object
            },
            responseErrors: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            async validation() {
                let isValid = await this.$refs.form.validate();
                this.$emit('on-validation', this.$props.form, isValid);

                return isValid;
            }
        },
        directives: {
            mask: VueMaskDirective
        }
    };
</script>
<style scoped>
    p {
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #e9e9f0;
        width: 100%;
        background-color: transparent;
        font-family: Poppins;
        margin: 0.5rem 0;
        font-size: 15px;
    }

    input:focus {
        border-color: black;
    }

    .btn-signup {
        color: #ffffff;
        background-color: #0fbc9c;
        border-color: #0fbc9c;
        font-weight: bold;
        border-radius: 1px;
        width: 100%;
    }

    .btn-signup:hover,
    .btn-signup:active,
    .btn-signup:focus,
    .btn-signup.active {
        background: #169e85;
        color: #ffffff;
        border-color: #0fbc9c;
    }

    .btn-wrap {
        padding-bottom: 30px;
    }

    .errors {
        text-align: left;
        color: red;
        font-weight: 400;
        font-size: 12px;
    }
</style>
