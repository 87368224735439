<template>
    <div class="section blog">
        <div class="view">
            <b-container v-loading="loading">
                <h3 class="title">{{ $t('blog.blog') }}</h3>
                <b-row class="mt-5 ">
                    <b-col xl="8" lg="8">
                        <b-form-group>
                            <b-form-radio-group
                                    id="categories"
                                    v-model="selected"
                                    :options="options"
                                    buttons
                                    value-field="id"
                                    text-field="title"
                                    @change="get()"

                                    button-variant="default"
                                    name="categories"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col xl="4" lg="4 menubar">
                        <b-input-group class="search-bar" size="sm">
                            <template v-slot:append>
                                <b-input-group-text ><strong><i class="fa fa-search" aria-expanded="true"/></strong>
                                </b-input-group-text >
                            </template>
                            <b-form-input @keyup="search" v-model="q" placeholder="SEARCH"/>
                        </b-input-group>
                    </b-col>
                </b-row>
                <blog-item :searching="searching" :posts="posts"/>

                <div class="b-pagination">
                    <div class="">
                        <div class="mt-3">
                            <b-pagination v-model="currentPage" pills :total-rows="posts.total"/>
                        </div>
                    </div>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
    import BlogItem from "../components/Blog/BlogItem";
    import _ from 'lodash'
    import i18n from "../lang";
    import {EventBus} from "../event-bus";
    export default {
        name: "blog",
        components: {
            'blog-item': BlogItem
        },
        data: () => {
            return {
                selected: '49179431-8661-4ddf-adb5-a2b3b0931d4e',
                options: [],
                posts: [],
                rows: 100,
                currentPage: 1,
                loading: false,
                q: "",
                searching: false
            }
        },
        mounted() {
            this.get();
            this.getCategories();

            EventBus.$on('langChanged', () => {
                this.get();
                this.getCategories();
            })
        },
        methods:{
            search: _.debounce(function () {
                console.log('rec')
                if(this.q === ''){
                    this.searching = false
                }else{
                    this.searching = true
                }

                this.get()
            }, 700),
            getCategories(){
                this.$http.get('/categories?lang='+i18n.locale)
                .then((response) => {
                    this.options = response.data.data

                    _.forEach(this.options, (option, key) => {
                        if(option.multi_language_category && option.multi_language_category.length){
                            var translation = option.multi_language_category[0];
                            this.options[key].title = translation.title;
                            this.options[key].description = translation.description;
                        }
                    });

                })
            },
            get(){
                this.loading = true
                setTimeout(() => {
                    this.$http.get('/categories/'+this.selected+'/blogs?q='+this.q+'&lang='+i18n.locale)
                        .then((response) => {
                            this.posts = response.data.data
                            _.forEach(this.posts, (post, key) => {
                                if(post.multi_language_blog && post.multi_language_blog.length){
                                    var translation = post.multi_language_blog[0];
                                    this.posts[key].title = translation.title;
                                    this.posts[key].description = translation.description;
                                    this.posts[key].content = translation.content;
                                }
                            });
                            this.loading = false
                        })
                },1000)
                console.log(this.selected);
            }
        }
    };
</script>