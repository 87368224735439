<template>
    <div>
        <div class="search">
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-md-2 no-padding">
                        <div class="form-group bar">
                            <label>Search For</label>
                            <b-form-select
                                v-model="type"
                                :options="[
                                    {text: 'Professionals', value: 'professionals'},
                                    {text: 'Offices', value: 'offices'}
                                ]"
                            />
                        </div>
                    </div>
                    <div class="col-md-3 no-padding">
                        <div class="form-group bar">
                            <label>{{ $t('home.search') }}</label>
                            <input type="text" v-model="q" class="name" name="search"
                                   :placeholder="$t('home.placeholder1')"/>
                        </div>
                    </div>
                    <div class="col-md-3 no-padding">
                        <div class="form-group bar">
                            <label>{{ $t('home.search_city') }}</label>
                            <input ref="autocomplete" type="text" v-model="city" class="name" name="search"
                                   :placeholder="$t('home.search_city')"/>
                        </div>
                    </div>
                    <div class="col-md-2 no-padding">
                        <div class="form-group bar">
                            <label>{{ $t('home.search_zip_code') }}</label>
                            <input type="text" v-model="zip_code" class="name" name="search"
                                   :placeholder="$t('home.search_zip_code')"/>
                        </div>
                    </div>
                    <!--<div class="col-xl-3 col-lg-3 col-md-3 no-padding">
                        <div class="form-group">
                            <label>{{ $t('home.location') }}</label>
                            <input type="text" v-model="location" name="search" ref="autocomplete"
                                   @focus="geolocate" :placeholder="$t('home.placeholder2')"/>
                        </div>
                    </div>-->
                    <div class="col-xl-2 col-lg-2 col-md-2 text-center">
                        <button class="btn btn-success btn-search" style="text-transform: uppercase" type="submit">{{ $t('home.search') }}</button>
                    </div>
                </div>
            </form>
            <br>

        </div>

        <div class="search-home">
            <form @submit.prevent="submit">
                <div class="form-group">
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text v-model="q" name="q"><i class="fa fa-search"></i></b-input-group-text>
                        </template>
                        <b-form-input class="form-control" v-model="q" name="q"
                                      :placeholder="$t('home.mobile.placeholder1')"/>
                    </b-input-group>
                </div>
                <div class="form-group">
                    <b-input-group>
                        <b-form-input ref="autocompletemobile" class="form-control" v-model="city" name="city"
                                      :placeholder="$t('home.search_city')"/>
                    </b-input-group>

                </div>
                <div class="form-group">
                    <b-input-group>
                        <b-form-input class="form-control" v-model="zip_code" name="zip_code"
                                      :placeholder="$t('home.search_zip_code')"/>
                    </b-input-group>

                </div>

                <!--<div class="form-group">
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text><i class="fa fa-map-marker"></i></b-input-group-text>
                        </template>
                        <input v-model="location" name="location" class="form-control"
                               :placeholder="$t('home.mobile.placeholder2')" ref="autocompletemobile"/>
                    </b-input-group>

                </div>-->
                <div class="form-group text-center">
                    <button class="btn btn-success btn-search btn-block" type="submit">{{ $t('home.search') }}</button>
                </div>
            </form>
        </div>
        <div class="search-native">
            <form @submit.prevent="submit">
                <div class="form-group">
                    <b-input-group>
                        <template v-slot:append>
                            <b-input-group-text v-model="q" name="q"><i class="fa fa-search"></i></b-input-group-text>
                        </template>
                        <b-form-input class="form-control" v-model="q" name="q"
                                      :placeholder="$t('home.mobile.placeholder1')"/>
                    </b-input-group>
                </div>
                <div class="form-group">
                    <b-input-group>
                        <b-form-input class="form-control" v-model="city" name="city"
                                      :placeholder="$t('home.search_city')"/>
                    </b-input-group>

                </div>
                <div class="form-group">
                    <b-input-group>
                        <b-form-input class="form-control" v-model="zip_code" name="zip_code"
                                      :placeholder="$t('home.search_zip_code')"/>
                    </b-input-group>

                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import gmapsInit from '../../../src/utils/gmaps';

    export default {
        name: "search",
        props: {
            autofill: {
                type: Object
            },
            selected: {
                type: String,
            }
        },
        data() {
            return {
                type: this.$route.query.selected || 'professionals',
                page: this.$route.query.page || 1,
                q: this.$route.query.q || "",
                location: this.$route.query.location || "",
                city: this.$route.query.city || "",
                zip_code: this.$route.query.zip_code || "",
                show: false,
                google: null,
                lat: null,
                lng: null,
                placeSearch: null,
                autocomplete: null,
                autocompletemobile: null,
                componentForm: {
                    street_number: 'short_name',
                    route: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'short_name',
                    country: 'long_name',
                    postal_code: 'short_name'
                }
            }
        },
        async mounted() {
           this.page = this.$route.query.page || 1;
            try {
                const google = await gmapsInit();

                this.autocomplete = new google.maps.places.Autocomplete(
                    this.$refs.autocomplete, {types: ['(cities)'], componentRestrictions: {country: ["ca"]}});

                // Avoid paying for data that you don't need by restricting the set of
                // place fields that are returned to just the address components.
                this.autocomplete.setFields(['address_component', 'geometry']);

                const fillInAddress = () => {
                    this.fillInAddress()
                }

                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                this.autocomplete.addListener('place_changed', fillInAddress);

                const google2 = await gmapsInit();

                // autocomplete mobile
                this.autocompletemobile = new google2.maps.places.Autocomplete(
                    this.$refs.autocompletemobile, {types: ['(cities)'], componentRestrictions: {country: ["ca"]}});

                this.autocompletemobile.setFields(['address_component', 'geometry']);
                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                this.autocompletemobile.addListener('place_changed', fillInAddressMobile);
            } catch (error) {
                // console.error(error);
            }
        },
        methods: {
            fillInAddress() {
                // Get the place details from the autocomplete object.
                const place = this.autocomplete.getPlace();
                place.address_components.map((component) => {
                    if(component.types.includes('locality')) {
                        this.city = component.long_name.split(',')[0];
                    }
                })
                this.lat = place.geometry.location.lat()
                this.lng = place.geometry.location.lng()
                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // for (var i = 0; i < place.address_components.length; i++) {
                //     var addressType = place.address_components[i].types[0];
                //     if (this.componentForm[addressType]) {
                //         var val = place.address_components[i][this.componentForm[addressType]];
                //         this.$props.autofill[addressType] = val
                //     } else {
                //         this.$props.autofill[addressType] = ''
                //     }
                // }
                // this.location = this.$refs.autocomplete.value;
                // this.$emit('fillInAddress', this.$props.autofill);
            },
            fillInAddressMobile() {
                // Get the place details from the autocomplete object.
                const place = this.autocomplete.getPlace();
                place.address_components.map((component) => {
                    if(component.types.includes('locality')) {
                        this.city = component.long_name.split(',')[0];
                    }
                })
                this.lat = place.geometry.location.lat()
                this.lng = place.geometry.location.lng()
                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // for (var i = 0; i < place.address_components.length; i++) {
                //     var addressType = place.address_components[i].types[0];
                //     if (this.componentForm[addressType]) {
                //         var val = place.address_components[i][this.componentForm[addressType]];
                //         this.$props.autofill[addressType] = val
                //     } else {
                //         this.$props.autofill[addressType] = ''
                //     }
                // }
                // this.location = this.$refs.autocompletemobile.value;
                // this.$emit('fillInAddressMobile', this.$props.autofill);
            },
            // Bias the autocomplete object to the user's geographical location,
            // as supplied by the browser's 'navigator.geolocation' object.
            async geolocate() {
                const google = await gmapsInit();
                const AutoComplete = this.autocomplete;

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        // get current location lat and long
                        var geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        // set search boundary based on current location
                        var circle = new google.maps.Circle(
                            {center: geolocation, radius: position.coords.accuracy});
                        AutoComplete.setBounds(circle.getBounds());
                    });
                }
            },
            submit() {
                //if ((this.q !== this.$route.query.q || this.location !== this.$route.query.location) || this.selected !== this.$route.query.selected) {
                if ((this.$route.query.selected !== this.type || this.q !== this.$route.query.q || this.city !== this.$route.query.city) || (this.selected !== this.$route.query.selected || this.zip_code !== this.$route.query.zip_code)) {
                    this.$router.replace({
                        name: 'searchpage',
                        query: {
                            page: this.page,
                            q: this.q,
                            location: this.location,
                            street_number: this.autofill.street_number,
                            route: this.autofill.route,
                            locality: this.autofill.locality,
                            administrative_area_level_1: this.autofill.administrative_area_level_1,
                            country: this.autofill.country,
                            postal_code: this.autofill.postal_code,
                            zip_code: this.zip_code,
                            city: this.city,
                            selected: this.type,
                            sort: this.autofill.sort,
                            lat: this.$route.query.lat ?? this.lat,
                            lng: this.$route.query.lng ?? this.lng
                        }
                    });
                    this.$emit('submit', this.$data);
                }
            }
        }
    };
</script>