<template>
    <div>
        <div class="row">
            <div class="col-8">

            </div>
            <div class="col-4">
                <b-form-group  :label="$t('form.office_language_selection')" label-for="profile_language">
                    <b-form-select @change="onLangChange" value-field="key" text-field="name" v-model="selected_langauge" :options="supported_languages"></b-form-select>
                </b-form-group>
            </div>
        </div>
        <br/>
        <br/>
        <b-form @submit.prevent="save">
            <b-form-group id="input-group-1" :label="$t('form.officename')" label-for="office_name">
                <b-form-input
                        id="input_office_name"
                        v-model="form_professional.name"
                        required
                />
            </b-form-group>

            <b-form-group id="input_office_address" :label="$t('form.offficeaddress')" label-for="office_address">
                <b-form-input
                        id="office_address"
                        v-model="form_professional.address"
                        required
                />
            </b-form-group>
            <b-form-group id="input_office__phone" :label="$t('form.phone')" label-for="office_phone">
                <b-form-input
                        id="office_phone"
                        v-model="form_professional.phone_number"
                        required
                />
            </b-form-group>


            <b-form-group id="office_about" :label="$t('form.aboutoffice')" label-for="about_office">
                <b-form-textarea
                        id="about_office"
                        rows="5"
                        v-model="form_professional.bio"
                        required
                />
            </b-form-group>

            <b-form-group id="input_lang" :label="$t('form.selectlanguages')" label-for="pro_lang">
                <multiselect
                        v-model="form_professional.languages"
                        :options="options.languages"
                        :multiple="true"
                        :placeholder="$t('form.selectlanguagesplaceholder')"/>
            </b-form-group>

            <b-form-group id="input_office_images" :label="$t('form.officeimages')" label-for="office_images">
                <vue-upload-multiple-image
                        @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove"
                        @edit-image="editImage"
                        :data-images="images"
                        idUpload="myIdUpload"
                        editUpload="myIdEdit"
                        :dragText="$t('profile.dragimages')"
                        :browseText="$t('profile.browseimages')"
                        :maxImage="maxImage"
                />
            </b-form-group>
            <b-form-group class="mt-5">
                <b-button type="submit" variant="success">{{ $t('profile.save') }}</b-button>
                <!--                <b-button type="reset" variant="default">Cancel</b-button>-->
                <router-link style="border: 1px solid #343a40;margin-top: 10px;" class="btn ml-4"   to="/account-settings/professional?tab=office" >{{ $t('profile.manageofficebackbutton') }}</router-link>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
    import VueUploadMultipleImage from 'vue-upload-multiple-image'
    import Multiselect from 'vue-multiselect'

    export default {
        name: "OfficeForm",
        props: {
            form_professional: {
                type: Object,
                default: () => {
                }
            }
        },
        data: () => {
            return {
                images: [],
                maxImage: 20,
                options: {
                    languages: [
                        'English', 'Chines', 'French'
                    ],
                    genders: [
                        'Male', 'Female', 'Others'
                    ],
                    services: [],
                    ProfessionalServices: []
                },
                selected_langauge: 'en',
                supported_languages: []
            }
        },
        components: {
            VueUploadMultipleImage,
            Multiselect
        },
        created() {
            this.$_.forEach(this.form_professional.photos, (value) => {
                this.images.push({
                    id: value.id,
                    path: value.url,
                    default: 1,
                    highlight: 1,
                });
            })

            this.$c
            console.log("created", this.form_professional);
            this.getLanguagesList();
        },
        methods: {
            onLangChange(){
              this.$emit('change',this.selected_langauge)
            },
            getLanguagesList(){
                this.$http.get('supportedlanguages')
                    .then((response) => {
                        this.supported_languages = response.data.data
                    })
            },
            save() {
                this.$http.post('/offices/' + this.$route.query.office_id+'?lang='+this.selected_langauge, this.form_professional)
                    .then(() => {
                        this.$message.success('changes has been saved!')
                    })
            },
            onSubmit(evt) {
                evt.preventDefault()
                alert(JSON.stringify(this.form))
            },
            chooseFiles() {
                document.getElementById("fileUpload").click()
            },
            onFileChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0]);
            },
            createImage(file) {
                /*eslint-disable no-unused-vars*/
                const proImage = new Image();
                const reader = new FileReader();
                const vm = this;

                reader.onload = (e) => {
                    vm.proImage = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            uploadImageSuccess(formData, index, fileList) {
                //console.log('data', formData, index, fileList)
                // Upload image api
                this.$http.post('/offices/' + this.form_professional.id + '/photos', formData).then(response => {
                    console.log(response)
                })
                console.log(formData);
            },
            beforeRemove(index, done, fileList) {
                //console.log('index', index, fileList)
                var r = confirm("remove image")
                if (r == true) {
                    this.$http.delete('/offices/' + this.form_professional.id + '/photos/' + fileList[index].id).then(response => {
                        console.log(response)
                    })
                    done()
                } else {
                    //
                }
            },
            editImage(formData, index, fileList) {
                //console.log('edit data', formData, index, fileList)
            },
        }
    }
</script>