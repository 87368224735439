<template>
    <div class="section my-patients">
        <div class="view">
            <b-container>
                <h3 class="mb-5 text-left title">{{ $t('mypatients.index') }}</h3>

                <b-row class="mb-5">
                    <b-col xl="4" lg="4" md="4">
                        <b-input-group class="search-bar" size="sm">
                            <template v-slot:append>
                                <b-input-group-text><strong><i class="fa fa-search" aria-expanded="true"/></strong>
                                </b-input-group-text>
                            </template>
                            <b-form-input @keypress.enter="get" v-model="q" :placeholder="$t('home.search')"/>
                        </b-input-group>
                    </b-col>
                </b-row>

                <patient-item :patients="patients"/>
            </b-container>
        </div>
    </div>
</template>

<script>
    import PatientItem from "../components/MyPatient/PatientItem";

    export default {
        name: "MyPatients",
        components: {
            "patient-item": PatientItem
        },
        data: () => {
            return {
                select_selected: 'nearest',
                select_options: [
                    {text: 'Nearest', value: 'nearest'},
                    {text: 'Top Rated', value: 'top_rated'},
                    {text: 'Recent', value: 'recent'}
                ],
                patients: [],
                q: ""
            }
        },
        computed: {
            user: function () {
                return this.$store.getters.user;
            }
        },
        mounted() {
            setTimeout(() => {
                this.get()
            }, 1000)
        },
        methods: {
            get() {
                this.$http.get('/professionals/' + this.user.professional.id + '/patients?q=' + this.q)
                    .then((response) => {
                        this.patients = response.data.data
                    })
            }
        }
    }
</script>