<template>
    <div class="home">
        <div class="banner">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-6 col-lg-6">
                        <div class="text pt-1">
                            <h2 class="pt-5">
                                {{ $t('home.title') }}
                            </h2>
                            <p class="mb-3">{{ $t('home.subtitle') }}</p>
                            <div class="mt-5 mb-5">
                                <router-link to="/blog/1b882533-826e-4030-98db-560c1fdd659a">{{ $t('home.learnmore') }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <serch-component :autofill="autofill" class="mt-5"></serch-component>
            </div>
        </div>
        <div class="near mt-5 mb-5">
            <professional-detail-card :professionals="professionals_details"></professional-detail-card>
        </div>
        <div class="categories">
            <div class="container">
                <h4 class="mb-5">{{ $t('home.browsebycategory') }}</h4>
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#services">{{ $t('home.services') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#professionals">{{ $t('home.professionals') }}</a>
                    </li>
                </ul>
                <div class="tab-content pt-5">
                    <div class="tab-pane container active" id="services">
                        <category-card :categories="categories"></category-card>
                    </div>
                    <div class="tab-pane container fade" id="professionals">
                        <category-card category-type="professionals" :categories="professionals"></category-card>
                    </div>
                </div>
            </div>
        </div>
        <helthy-dentaly-works-component class="mt-5 pt-5 mb-5" :workingsteps="workingsteps"></helthy-dentaly-works-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ProfessionalDetailsCard from "@/components/Dashboard/ProfessionalDetailsCard.vue";
    import CategoryCard from "@/components/Dashboard/CategoryCard.vue";
    import SearchComponent from "@/components/Dashboard/Search.vue";
    import HowWorks from "@/components/Dashboard/HowWorks.vue";
    import { EventBus } from '../event-bus.js';
    // import Apiservice from '../../apis/auth'
    // const apiService = new Apiservice();
    import i18n from "../lang";
    export default {
        name: "home",
        components: {
            "professional-detail-card": ProfessionalDetailsCard,
            "category-card": CategoryCard,
            "serch-component": SearchComponent,
            "helthy-dentaly-works-component": HowWorks
        },
        data: function () {
            return {
                autofill: {
                    street_number: "",
                    route:  "",
                    locality: "",
                    administrative_area_level_1: "",
                    country: "",
                    postal_code: "",
                },
                professionals_details: [
                    {
                        id: 1,
                        name: "Dr. Muhammad Sarhan",
                        rating: "27",
                        office: "Office Name Associate",
                        profession: "Dentist",
                        location: "1906 Kennedy Blvd Ste-Toronto",
                        distance: "2.1 km"
                    },
                    {
                        id: 2,
                        name: "Dr. Muhammad Sarhan",
                        rating: "27",
                        office: "Office Name Associate",
                        profession: "Dentist",
                        location: "1906 Kennedy Blvd Ste-Toronto",
                        distance: "2.1 km"
                    },
                    {
                        id: 3,
                        name: "Dr. Muhammad Sarhan",
                        rating: "27",
                        office: "Office Name Associate",
                        profession: "Dentist",
                        location: "1906 Kennedy Blvd Ste-Toronto",
                        distance: "2.1 km"
                    },
                    {
                        id: 4,
                        name: "Dr. Muhammad Sarhan",
                        rating: "27",
                        office: "Office Name Associate",
                        profession: "Dentist",
                        location: "1906 Kennedy Blvd Ste-Toronto",
                        distance: "2.1 km"
                    }
                ],
                categories: [],
                professionals: [],
                workingsteps: [
                    {
                        id: 1,
                        description:
                            "Register or claim your professional profile. We recommend you fill in all aspects of information to help your profile to be more attractive to new clients."
                    },
                    {
                        id: 2,
                        description:
                            "Register or claim your dental office account. if you are an office owner, our dental office profile offers everything that’s needed for an office website without the start-up fee and a monthly annual maintenance fee."
                    },
                    {
                        id: 3,
                        description:
                            "Explore our free features including our easy to use client reviews <a href='/'>click here for demo</a> and our 2-way messaging to your client from your professional account or from the main office account."
                    }
                ]
            };
        },
        computed: {},
        mounted() {
            this.getServices()
            this.getProfessionalCategories()
            EventBus.$on('langChanged', () => {
                this.getServices()
                this.getProfessionalCategories()
            })
        },
        methods: {
            getServices(){
                this.$http.get('/services?limit=6'+'&lang='+i18n.locale)
                .then((response) => {
                    this.categories = response.data.data
                })
            },
            getProfessionalCategories(){
                this.$http.get('/professionalcategories?limit=6'+'&lang='+i18n.locale)
                    .then((response) => {
                        this.professionals = response.data.data
                    })
            }
        }
    };
</script>
