<template>
    <div class="section message-center">
        <div class="view">
            <b-container>
                <div class="row">
                    <div class="col-md-10">
                        <h3 class="text-left title">{{ $t('messagecenter.index') }}</h3>
                    </div>
                    <!--                    <div class="col-md-2">-->
                    <!--                        <router-link to="/message-center/new" class="btn btn-success btn-block">Compose New-->
                    <!--                        </router-link>-->
                    <!--                    </div>-->
                </div>
                <div class="messaging card-shadow">
                    <div class="inbox_msg row">
                        <!-- Message navigation -->
                        <div class="col-xl-2 col-lg-2 col-md-2 navigation">
                            <div class="">
                                <div class="menu">
                                    <ul class="list-unstyled">
                                        <li>
                                            <router-link :class="{ 'active': this.$route.params.type === 'inbox' }"
                                                         to="/message-center/inbox">{{ $t('messagecenter.inbox') }}
                                                <label class="badge">{{
                                                    inboxCount }}</label>
                                            </router-link>
                                        </li>
                                        <!--                                        <li>-->
                                        <!--                                            <router-link-->
                                        <!--                                                    v-bind:class="[ type.status === true ? type.value === 'sent' ? 'active' : '' : '' ]"-->
                                        <!--                                                    to="/message-center/sent">Sent <label class="badge">{{ trashCount }}</label>-->
                                        <!--                                            </router-link>-->
                                        <!--                                        </li>-->
                                        <li>
                                            <router-link :class="{ 'active': this.$route.params.type === 'trash' }"
                                                         to="/message-center/trash">{{ $t('messagecenter.trash') }}
                                                <label class="badge">{{
                                                    trashCount }}</label>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!--/ Message navigation -->

                        <!-- Message History -->
                        <div class="col-xl-10 col-lg-10 col-md-10" v-if="content" v-loading="loading">
                            <div class="row">
                                <!-- Message items -->
                                <div class="col-xl-4 col-lg-4 col-md-4 inbox_people">
                                    <div class="">
                                        <div class="headind_srch">
                                            <div class="srch_bar">
                                                <div class="">
                                            <span class="input-group-addon">
                                                <button type="button">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                            </span>
                                                    <input type="text" class=""
                                                           :placeholder="$t('messagecenter.search')"
                                                           v-model="search">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inbox_chat" v-loading="chat_box">
                                            <div class="chat_list"
                                                 v-bind:key="thread.id"
                                                 v-for="(thread, index) in filteredThreads"
                                                 :class="{ 'active_chat': activeIndex === index }"
                                                 v-on:click="onClickThread(thread, index)">
                                                <div class="chat_people">
                                                    <div class="row">
                                                        <div class="col-md-2">
                                                            <div class="chat_img">
                                                                <img v-if="thread.participant[0].user.avatar"
                                                                     :src="thread.participant[0].user.avatar"
                                                                     :alt="thread.participant[0].user.first_name">
                                                                <img v-else
                                                                     src="../assets/images/user-profile.png"
                                                                     alt="thread.user.first_name">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <div class="chat_ib">
                                                                <h5>{{ thread.participant[0].user.first_name + ' ' +
                                                                    thread.participant[0].user.last_name }}
                                                                </h5>
                                                                <p>{{ thread.message.substring(0,30)+"..." }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="chat_people" v-if="filteredThreads.length === 0">
                                                <div class="row">
                                                    <div class="container">
                                                        <h6 class="text-center pt-2">{{ noResult }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/ Message items -->

                                <!-- Message Body -->
                                <div class="col-xl-8 col-lg-8 col-md-8">
                                    <div class="mesgs" v-loading="msg_box" v-if="messageBox">
                                        <div class="msg_header">
                                            <h3>{{threadItem.title}}</h3>
                                            <div class="chat_list">
                                                <div class="chat_people">
                                                    <div class="row">
                                                        <div class="col-xl-1 col-lg-1">
                                                            <div class="chat_img">
                                                                <img
                                                                        :src="threadItem.avatar"
                                                                        :alt="threadItem.user">
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-5 col-lg-5">
                                                            <div class="chat_ib">
                                                                <h5>{{ threadItem.user }}</h5>
                                                                <!--                                                                to <span class="h5">me</span>-->
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6">
                                                            <div class="settings">
                                                                <div class="dropdown">
                                                                    <i class="fa fa-gear dropdown-toggle"
                                                                       id="dropdownMenuButton"
                                                                       data-toggle="dropdown" aria-haspopup="true"
                                                                       aria-expanded="false"/>
                                                                    <div class="dropdown-menu dropdown-menu-left"
                                                                         aria-labelledby="dropdownMenuButton">
                                                                        <a v-if="this.$route.params.type === 'inbox'"
                                                                           class="dropdown-item"
                                                                           @click="moveThread(threadItem.thread.id)">{{
                                                                            $t('messagecenter.move') }}</a>
                                                                        <a v-else-if="this.$route.params.type === 'trash'"
                                                                           class="dropdown-item"
                                                                           @click="deleteThread(threadItem.thread.id)">{{
                                                                            $t('messagecenter.delete') }}</a>
                                                                        <div class="dropdown-divider"></div>
                                                                        <a class="dropdown-item"
                                                                           @click="banUser(threadItem.thread)">{{
                                                                            $t('messagecenter.block') }}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="msg_history" id="scroll">
                                            <div v-for="message in threadItem.messages" :key="message.id">
                                                <div class="left-bubble"
                                                     v-if="message.from === threadItem.thread.participant[0].user.id">
                                                    <div class="message" v-html="message.content">
                                                    </div>
                                                    <p class="date">{{
                                                        moment(String(message.created_at)).format("MM/DD/YYYY HH:mm:ss")
                                                        }}</p>
                                                </div>
                                                <div class="right-bubble" v-else>
                                                    <div class="message" v-html="message.content">
                                                    </div>
                                                    <p class="date">{{
                                                        moment(String(message.created_at)).format("MM/DD/YYYY HH:mm:ss")
                                                        }}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="type_msg mt-4">
                                            <div class="input_msg_write" v-if="this.$route.params.type === 'inbox'">
                                                <form @submit="onSubmitSend">
                                                    <div class="form-group">
                                                    <textarea class="form-control" name="reply" id="reply" cols="30"
                                                              rows="10"
                                                              :placeholder="$t('messagecenter.reply')"
                                                              v-model="new_message.form.content"
                                                              v-validate="{ required: true }"/>
                                                        <p class="mt-2" style="color: #ff0000;font-size: 13px"
                                                           v-if="errors.has('reply')">This
                                                            field is required</p>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="row justify-content-end">
                                                            <div class="col-md-4">
                                                                <button type="submit" class="btn btn-success btn-block"
                                                                        :disabled="is_disabled">
                                                                    {{ $t('messagecenter.send') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center pt-5" v-else>
                                        <i style="font-size: 48px" class="fa fa-comments"></i>
                                        <h5 style="font-weight: 700; font-size: 36px">{{ $t('messagecenter.select')
                                            }}</h5>
                                    </div>
                                </div>
                                <!--/ Message Body -->
                            </div>
                        </div>
                        <!--/ Message History -->

                        <!-- New Message -->
                        <div class="col-xl-10 col-lg-10 col-md-10" v-else>
                            <div class="new-message" v-loading="loading">
                                <form @submit="onSubmit">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="name"
                                               aria-describedby="emailHelp" :placeholder="$t('messagecenter.name')"
                                               readonly
                                               v-model="new_message.name">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="subject"
                                               aria-describedby="emailHelp" name="subject"
                                               :placeholder="$t('messagecenter.subject')"
                                               v-model="new_message.form.subject
" v-validate="{ required: true }">
                                        <p class="mt-2" style="color: #ff0000;font-size: 13px"
                                           v-if="errors.has('subject')">This
                                            field is required</p>
                                    </div>
                                    <div class="form-group">
                    <textarea name="message" class="form-control" id="message" cols="30"
                              rows="10" :placeholder="$t('messagecenter.message')" v-validate="{ required: true }"
                              v-model="new_message.form.content"/>
                                        <p class="mt-2" style="color: #ff0000;font-size: 13px"
                                           v-if="errors.has('message')">This
                                            field is required</p>
                                    </div>
                                    <button type="submit" class="btn-success fa-pull-right mb-5"
                                            :disabled="is_disabled">
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                        <!--/ New Message -->
                    </div>
                </div>
            </b-container>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "MessageCenter",
        props: ['messageType'],
        data: () => {
            let content = true;
            return {
                inboxCount: 0,
                trashCount: 0,
                search: '',
                noResult: 'No any conversation found',
                content: content,
                activeIndex: undefined,
                loading: false,
                chat_box: false,
                msg_box: false,
                messageBox: false,
                is_disabled: false,
                new_message: {
                    name: '',
                    form: {
                        to: '',
                        subject: '',
                        content: '',
                        is_repliable: 1,
                        thread_id: '',
                    },
                },
                type: {
                    status: false,
                    value: ''
                },
                threads: [],
                threadItem: {
                    thread: null,
                    avatar: '',
                    title: '',
                    user: '',
                    to: '',
                    messages: []
                }
            };
        },
        mounted() {
            this.getThreads(this.$route.params.type === 'inbox' ? '' : '/trash')
            if (typeof this.$route.query.user_id !== 'undefined') {
                this.loading = true
                this.getUser();
            }
            this.loading = true
            this.activeIndex = this.$route.query.index_id;
            setTimeout(() => {
                this.loading = false;
            }, 1000)
            if (this.$route.params.type !== "new") {
                this.content = true;
                this.type.status = true;
                this.type.value = this.$route.params.type;
            } else {
                this.content = false;
                this.type.status = false;
            }

            this.setCount();

            this.noResult = this.$t('messagecenter.noresult');
        },
        watch: {
            $route: function () {
                this.loading = true
                setTimeout(() => {
                    this.loading = false;
                }, 1000)
                if (this.$route.params.type !== "new") {
                    this.content = false;
                    this.type.status = true;
                    this.type.value = this.$route.params.type;
                } else {
                    this.content = true;
                    this.type.status = false;
                }

                if (this.$route.params.type === 'inbox') {
                    this.getThreads('trash')
                }

                // set thread active class
                this.activeIndex = this.$route.query.index_id;
            }
        },
        computed: {
            user() {
                return this.$store.getters.user;
            },
            filteredThreads() {
                if (this.search) {
                    return this.threads.filter(thread => {
                        // console.log(thread.participant[0].user.first_name.toLowerCase());
                        return thread.participant[0].user.first_name.toLowerCase().includes(this.search.toLowerCase()) || thread.participant[0].user.last_name.toLowerCase().includes(this.search.toLowerCase()) || thread.message.toLowerCase().includes(this.search.toLowerCase())
                    })
                } else {
                    return this.threads;
                }
            },
        },
        methods: {
            onClickThread(thread, index) {
                // console.log(this.user)
                this.activeIndex = index;
                this.msg_box = true
                this.messageBox = true
                this.activeIndex = index;
                setTimeout(() => {
                    this.msg_box = false
                    // this.$nextTick(() => this.$el.querySelector("#scroll").lastElementChild.offsetTop)
                }, 1000)

                setTimeout(() => {
                    this.$http.get('/threads/' + thread.id + '/messages')
                        .then((response) => {
                            this.threadItem.thread = thread;
                            this.threadItem.messages = response.data.data;
                            this.threadItem.avatar = thread.participant[0].user.avatar
                            this.threadItem.title = thread.title;

                            // set form data
                            this.new_message.form.to = thread.participant[0].user.id;
                            this.new_message.form.subject = thread.title;
                            this.new_message.form.thread_id = thread.id;
                        })
                }, 1000)
                // let container = document.getElementById('scroll')

                // container.scrollBottom = container.scrollHeight;
            },
            getThreads(param) {
                this.loading = true
                this.$http.get('/threads' + param)
                    .then((response) => {
                        this.loading = false;

                        this.threads = [];
                        response.data.data.filter((thread) => {

                            if (thread.participants.length > 0) {
                                let participant = thread.participants.filter((participant) => {
                                    return participant.user.id !== this.user.id;
                                });
                                this.threads.push({
                                    id: thread.id,
                                    title: thread.title,
                                    user_id: thread.user_id,
                                    created_at: thread.created_at,
                                    updated_at: thread.updated_at,
                                    participant: participant,
                                    message: this.strippedContent(thread.messages[0].content),
                                })
                            }
                        })
                    });

            },
            strippedContent(string) {
                return string.replace(/<\/?[^>]+>/ig, " ");
            },
            setCount() {
                // set thread inbox count
                this.$http.get('/threads')
                    .then((response) => {
                        this.inboxCount = response.data.data.length;
                    })

                // set thread trash count
                this.$http.get('/threads/trash')
                    .then((response) => {
                        this.trashCount = response.data.data.length;
                    })
            },
            getUser() {
                this.$http.get('/users/' + this.$route.query.user_id)
                    .then((response) => {
                        this.new_message.name = response.data.data.first_name + ' ' + response.data.data.last_name
                    })
                    .catch(() => {
                        this.is_disabled = true;
                        this.$message.error('Oops, Something went wrong please try again later')
                    })
            },
            onSubmit($evt) {
                $evt.preventDefault();
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // console.log(this.form)
                        this.new_message.form.to = this.$route.query.user_id;
                        this.$http.post('/messages/send/', this.new_message.form)
                            .then(() => {
                                this.$message.success('Message successfully sent')
                                this.$router.push('/message-center/inbox');
                                this.new_message.form.to = '';
                                this.new_message.form.subject = '';
                                this.new_message.form.content = '';
                            })
                            .catch(() => {
                                this.messageBox = false;
                                this.$message.warning('Unfortunately you cannot send message to this user anymore, Thank you!');
                            })
                    }
                });
            },
            onSubmitSend($evt) {
                $evt.preventDefault();
                // if(this.$_.trim(this.new_message.form.content)){
                //     return
                // }

                this.is_disabled = true;
                this.msg_box = true;
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // console.log(this.form)
                        this.$http.post('/messages/send/', this.new_message.form)
                            .then(() => {
                                this.new_message.form.content = '';
                                this.$message.success('Message successfully sent to the user');
                                this.is_disabled = false;
                                this.msg_box = false;
                                this.onClickThread(this.threadItem.thread, this.activeIndex)
                            })
                            .catch(() => {
                                this.msg_box = false;
                                this.is_disabled = false;
                                this.messageBox = false;
                                this.$message.warning('Unfortunately you cannot reply to this conversation anymore, Thank you!');
                            })
                    } else {
                        this.msg_box = false;
                        this.is_disabled = false;
                    }
                });
            },
            moment,
            moveThread(thread) {
                this.$http.put('/threads/markAsDeleted/' + thread)
                    .then(() => {
                        this.messageBox = false;
                        this.setCount()
                        this.getThreads('')
                        this.$message.success('Conversation successfully moved to the trash');
                    });
            },
            deleteThread(thread) {
                if (confirm("Do you really want to delete this thread?")) {
                    this.msg_box = true
                    this.messageBox = true
                    this.$http.post('/threads/' + thread + '/permanently-delete/')
                        .then(() => {
                            // console.log(result)
                            this.messageBox = false;
                            this.setCount()
                            this.getThreads('/trash')
                            this.$message.success('Conversation successfully deleted');
                        })
                }
            },
            banUser(thread) {
                this.$http.post('/bans', {
                    user_id: thread.participant[0].user.id,
                    thread_id: thread.id
                })
                    .then(() => {
                        this.messageBox = false;
                        this.$message.success('User successfully banned from sending message!');
                    });
            }
        }

    }
</script>