<template>
    <div class="section blog-post">
        <div class="view" v-loading="loading">
            <b-container>
                <h3 class="title">{{ post.title }}</h3>
                <div class="labels mb-3">
                        <span>
                            {{ post.author }}
                        </span>
                    <span>
                            <i class="fa fa-circle" aria-expanded="true"/>
                        </span>
                    <span>
                            {{ moment(post.created_at).format('MMM D, YYYY')}}
                        </span>
                </div>
                <b-card
                        class="mb-5"
                        :img-src="post.thumbnail ? Base_URL+'/storage/blogs/'+post.thumbnail : null"
                        :img-alt="post.title"
                >
                    <b-card-text v-html="post.content">
                    </b-card-text>
                    <b-button to="/blog" variant="success">Go Back
                    </b-button>
                </b-card>
            </b-container>
        </div>
    </div>
</template>

<script>
    import i18n from "../lang";
    import {EventBus} from "../event-bus";

    export default {
        name: "BlogArticle",
        data() {
            return {
                post: {},
                Base_URL: process.env.VUE_APP_Base_URL,
                loading: true
            }
        },
        mounted() {
          this.get()

            EventBus.$on('langChanged', () => {
                this.get();
            })
        },
        methods:{
            get(){
                this.loading = true
                setTimeout(() => {
                    this.$http.get('/blogs/'+this.$route.params.id+'?lang='+(this.$route.query.lang ? this.$route.query.lang : i18n.locale))
                        .then((response) => {
                            this.post = response.data.data

                        if(this.post.multi_language_blog && this.post.multi_language_blog.length){
                            var translation = this.post.multi_language_blog[0];
                            this.post.title = translation.title;
                            this.post.description = translation.description;
                            this.post.content = translation.content;
                        }
                            this.loading = false
                        })
                },1000)
            }
        }
    }
</script>