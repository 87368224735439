<template>
    <div id="app">
        <div v-if="!NavigationView">
            <header-component></header-component>
        </div>
        <router-view :key="$route.fullPath"/>
        <div v-if="!NavigationView">
            <footer-component></footer-component>
        </div>
    </div>
</template>

<script>
    import Header from "../src/components/Header";
    import Footer from "../src/components/Footer";

    export default {
        components: {
            "header-component": Header,
            "footer-component": Footer
        },
        data() {
            return {};
        },

        methods: {},
        computed: {
            NavigationView() {
                return !(

                    ["login", "signuptype", "register", "signup", "forget-password", "thankyou", "resetpassword"].indexOf(
                        this.$route.name
                    ) === -1
                );
            }
        }
    };
</script>
<style>
    @import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
    /* @import "./assets/styles/all.min.css"; */
    /* @import "./assets/styles/owl.carousel.min.css"; */
    @import "./assets/styles/owl.theme.default.min.css";
    @import "./assets/styles/owl.theme.green.min.css";
    @import "./assets/styles/style.css";
</style>
