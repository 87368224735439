<template>
    <div class="g-map"/>
</template>

<script>
    import gmapsInit from '../../../src/utils/gmaps';

    // set locations


    export default {
        name: 'GmapMap',
        props: ['search_items', 'search_type'],
        async mounted() {
            try {
                const google = await gmapsInit();
                const geocoder = new google.maps.Geocoder();
                const map = new google.maps.Map(this.$el);

                /*eslint-disable no-unused-vars*/
                const locations = [];

                if (this.$props.search_type === 'pro') {
                    const searchItems = this.$props.search_items.map((search_item) => {

                        let ratings = [];
                        ratings = '<span class="stars">';
                        for (let n = 1; n <= 5; n++) {
                            ratings += search_item.rating >= n ? '<i class="fa fa-star active"></i>' : '<i class="fa fa-star-o"></i>'
                        }
                      ratings += '</span>';
                        // let distance = search_item.distance !== null ? search_item.distance.toFixed(1) : 0;
                        // console.log(search_item.distance)
                        locations.push({
                            position: {
                                lat: parseFloat(search_item.latitude),
                                lng: parseFloat(search_item.longitude),
                            },
                            contentData: '<div class="map-container-fluid">' +
                                '<div class="row">' +
                                '<div class="left">' +
                                '<img class="image" src="' + search_item.avatar + '"/>' +
                                '</div>' +
                                '<div class="middle">' +
                                '<a href="#/profile?professional_id='+search_item.id+'"><span class="h4">' + search_item.first_name + " " + search_item.last_name + '</span></a>' +
                                '<div class="clearfix"></div>' +
                                '<span class="reviews">' +
                                ratings +
                                '</span>' +
                                '<span class="count">(' + search_item.reviews_count + ')</span>' +
                                '<p>' +
                                '<span class="address">' +
                                '<span class="location">' + search_item.address + '</span>' +
                                // '<span class="distance">' + distance.toFixed(1) + '</span>' +
                                '</span>' +
                                '</p>' +
                                '</div>' +
                                '</div>' +
                                '</div>'
                        })
                    });
                } else {
                    const searchItems = this.$props.search_items.map((search_item) => {
                        let ratings = [];
                        ratings = '<span class="stars">';
                        for (let n = 1; n <= 5; n++) {
                            ratings += search_item.rating >= n ? '<i class="fa fa-star active"></i>' : '<i class="fa fa-star-o"></i>'
                        }

                      ratings += '</span>';


                        // let distance = search_item.distance !== null ? search_item.distance : 0;

                        locations.push({
                            position: {
                                lat: parseFloat(search_item.latitude),
                                lng: parseFloat(search_item.longitude),
                            },
                            contentData: '<div class="map-container-fluid">' +
                                '<div class="row">' +
                                '<div class="left">' +
                                '<img class="image" src="' + search_item.thumbnail + '"/>' +
                                '</div>' +
                                '<div class="middle">' +
                                '<a href="/#/office-profile?office_id='+search_item.id+'"><span class="h4">' + search_item.name + '</span></a>' +
                                '<div class="clearfix"></div>' +
                                '<span class="reviews">' +
                                ratings +
                                '</span>' +
                                '<span class="count">(' + search_item.reviews_count + ')</span>' +
                                '<p>' +
                                '<span class="address">' +
                                '<span class="location">' + search_item.address + '</span>' +
                                // '<span class="distance">' + search_item.distance.toFixed(1) + '</span>' +
                                '</span>' +
                                '</p>' +
                                '</div>' +
                                '</div>' +
                                '</div>'
                        })
                    });
                }

                // Geo Location
                let region = this.$props.search_items[0].zip_code !== null ? this.$props.search_items[0].zip_code : 'Toronto, Canada';

                geocoder.geocode({address: region}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }

                    map.setCenter(results[0].geometry.location);
                    map.fitBounds(results[0].geometry.viewport);
                    map.setZoom(11);
                });

                // Marker click event
                const markerClickHandler = (marker) => {
                    map.setZoom(13);
                    map.setCenter(marker.getPosition());
                };

                // Info window content
                const imgLink = require('../../assets/images/asset-13.png');


                // Info window init
                const infowindow = new google.maps.InfoWindow({
                    maxWidth: 350
                });

                // Adding markers to map
                /*eslint-disable no-unused-vars*/
                const markers = locations.map((location) => {
                    const marker = new google.maps.Marker({...location, map});
                    marker.addListener('click', () => {
                        markerClickHandler(marker);
                        infowindow.setContent(location.contentData);
                        infowindow.open(map, marker);
                    });

                    return marker;
                });
            } catch (error) {
                // console.error(error);
            }
        },
    };
</script>

<style>
    .g-map {
        width: 100%;
        min-height: 600px;
        border-radius: 10px!important;
    }
    .map-container-fluid .image{
      width: 60px;
      height: 60px;
    }
    .reviews{
      padding: 0px;
    }
</style>