<template>
  <div class="search-page">
    <div class="view">
      <b-container>
        <serch-component :selected="selected" @submit="get" class="mb-4" :autofill.sync="autofill"/>
        <h4 class="title" v-if="total">
          {{ $t('search.found') }}
          <span>{{ total }}
            <span v-if="$_.find(find_by,'q')">{{ queries.q }}</span>
            <span v-else>{{ selected == 'professionals' ? $t('search.professionals') : $t('search.office') | pluralize(total) }}</span>
          </span>
          <span v-if="$_.find(find_by,'location')">
            <span class="text-dark"> {{ $t('search.in') }} </span>
            <span>{{ queries.location }}</span>
          </span>
        </h4>
        <h4 class="title native">{{ $t('search.found') }}
          <span class="text-capitalize">{{ total }}
            <span v-if="$_.find(find_by,'q')">{{ queries.q }}</span>
            <span v-else>{{ selected | pluralize(total) }}</span>
          </span>
          <span v-if="$_.find(find_by,'location')">
            <span class="text-dark"> {{ $t('search.in') }} </span>
            <span>{{ queries.location }}</span>
          </span>
        </h4>
        <p class="description">
          {{ $t('search.description') }}
        </p>

        <!-- filters -->
        <b-row class="filters-pc">
          <b-col xl="8" lg="8" md="8">
            <!-- <b-form-group>
              <b-form-radio-group
                  id="categories"
                  v-model="selected"
                  :options="[
                                      {text: $t('search.professional'), value: 'professionals'},
                                      {text: $t('search.office'), value: 'offices'},
                                    ]"
                  buttons
                  @change="selectionHandler"
                  button-variant="default"
                  name="radios-btn-default"
              >
              </b-form-radio-group>
            </b-form-group> -->
          </b-col>
          <b-col xl="4" lg="4" md="4">
            <div class="sort text-right">
              <span>{{ $t('search.sortby') }}:</span>
              <b-form-select v-model="select_selected" @change="sortHandler" :options=" [
                    {text: $t('search.nearest'), value: 'nearest'},
                    {text: $t('search.toprated'), value: 'top_rated'},
                    {text: $t('search.recent'), value: 'recent'},
                    {text: $t('search.mostreviewed'), value: 'most_reviews'}
                ]"/>
            </div>
          </b-col>
        </b-row>

        <div class="filters-native">
          <b-row class="mt-4">
            <b-col xl="5" lg="5" md="5" sm="5" class="text-left">
              <button v-b-modal.modal-center class="btn btn-filter">{{ $t('search.filters') }}</button>

              <b-modal id="modal-center" :hide-footer="true" centered>
                <template v-slot:modal-header>
                  <b-row style="width: 100%">
                    <b-col sm="6" style="width: 60%" class="text-right">
                      <strong>{{ $t('search.filters') }}</strong>
                    </b-col>
                    <b-col sm="6" style="width: 40%" class="text-right">
                      <span>{{ $t('search.reset') }}</span>
                    </b-col>
                  </b-row>
                </template>

                <div class="text-left">
                  <b-form-group :label="$t('search.searchby')">
                    <b-form-radio v-model="selected" @change="selectionHandler" name="search_by"
                                  value="professionals">
                      {{ $t('search.professional') }}
                    </b-form-radio>
                    <b-form-radio v-model="selected" @change="selectionHandler" name="search_by"
                                  value="offices">{{ $t('search.office') }}
                    </b-form-radio>
                  </b-form-group>
                  <hr>

                  <b-form-group label="Sort By">
                    <b-form-radio v-if="hasLocation" @change="sortHandler" v-model="select_selected"
                                  name="sort_by" value="nearest">
                      {{ $t('search.nearest') }}
                    </b-form-radio>
                    <b-form-radio v-model="select_selected" @change="sortHandler" name="sort_by"
                                  value="top_rated">{{ $t('search.toprated') }}
                    </b-form-radio>
                    <b-form-radio v-model="select_selected" @change="sortHandler" name="sort_by"
                                  value="recent">{{ $t('search.recent') }}
                    </b-form-radio>
                  </b-form-group>
                </div>
                <template v-slot:modal-footer="{ ok, cancel }">
                  <!--<button type="button" class="btn btn-secondary" @click="cancel()">Cancel</button>
                  <button type="button" class="btn btn-info" @click="ok()">show</button>-->
                </template>
              </b-modal>
            </b-col>
            <b-col xl="7" lg="7" md="7" sm="7" class="text-right">
              <b-form-group>
                <b-form-radio-group
                    id="filters"
                    v-model="filters.selected"
                    :options="filters.options"
                    buttons
                    button-variant="filter-radio"
                    name="radios-btn-default"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- search list and map -->
        <b-row class="mt-4">
          <b-col xl="7" v-loading="loading" lg="7" md="7" class="search-items search-list-pc">
            <search-items v-if="selected === 'professionals'" :search_items="$data[selected]" :hasLocation="hasLocation"/>
            <search-office-items v-else :search_items="$data[selected]" :hasLocation="hasLocation"/>
            <div class="b-pagination" v-if="pages > 0">
              <div class="">
                <div class="mt-5">
                  <b-pagination-nav pills v-model="currentPage" :number-of-pages="pages"
                                    :link-gen="linkGen" use-router/>
                </div>
              </div>
            </div>
          </b-col>
          <b-col xl="5" lg="5" md="5" class="google-map search-list-pc" v-loading="loading">
            <b-card class="gmap" style="min-height: 500px;">
              <g-map :center="{ lat: 10, lng: 10 }" v-if="!loading" :search_items="$data[selected]"
                     :search_type="selected === 'professionals' ? 'pro' : 'office'"/>
            </b-card>
          </b-col>
          <b-col xl="7" lg="7" md="7" class="search-items search-list-native"
                 v-if="filters.selected === 'list'">
            <search-items v-if="selected === 'professionals'" :search_items="$data[selected]" :hasLocation="hasLocation" />
            <search-office-items v-else :search_items="$data[selected]" :hasLocation="hasLocation" />
          </b-col>
          <b-col xl="5" lg="5" md="5" class="google-map search-list-native" v-else>
            <b-card class="gmap">
              <g-map :center="{ lat: 10, lng: 10 }" v-if="!loading" :search_items="$data[selected]"
                     :search_type="selected === 'professionals' ? 'pro' : 'office'"/>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import SearchComponent from '@/components/Dashboard/Search.vue'
import SearchItems from '../components/Search/SearchItems'
import GmapMap from '../components/Search/GmapMap'
import SearchOfficeItems from '../components/Search/SearchOfficeItems'
import { EventBus } from '../event-bus'
import i18n from '@/lang'

export default {
  name      : 'SearchPage',
  components: {
    SearchOfficeItems,
    'serch-component': SearchComponent,
    'search-items'   : SearchItems,
    'g-map'          : GmapMap,
  },
  data() {
    return {
      queries         : {
        page    : this.$route.query.page || 1,
        q       : this.$route.query.q || '',
        location: this.$route.query.location || '',
        city    : this.$route.query.city || '',
        zip_code: this.$route.query.zip_code || '',
      },
      autofill        : {
        page                       : this.$route.query.page || 1,
        street_number              : this.$route.query.street_number || '',
        route                      : this.$route.query.route || '',
        locality                   : this.$route.query.locality || '',
        administrative_area_level_1: this.$route.query.administrative_area_level_1 || '',
        country                    : this.$route.query.country || '',
        postal_code                : this.$route.query.postal_code || '',
        lat                        : this.$route.query.lat || '',
        lng                        : this.$route.query.lng || '',
      },
      total           : 0,
      rows            : 100,
      currentPage     : 1,
      pages           : 1,
      page            : 1,
      hasLocation     : false,
      loading         : false,
      selected        : this.$route.query.selected || 'professionals',
      find_by         : [],
      options         : [
        {text: 'Professional', value: 'professionals'},
        {text: 'Office', value: 'offices'},
      ],
      filters         : {
        selected: 'list',
        options : [
          {text: 'List', value: 'list'},
          {text: 'Map', value: 'map'},
        ],
      },
      select_selected : 'nearest',
      select_options  : [
        {text: 'Nearest', value: 'nearest'},
        {text: 'Top Rated', value: 'top_rated'},
        {text: 'Recent', value: 'recent'},
        {text: 'Most Reviewed', value: 'most_reviews'},
      ],
      professionals   : [],
      offices         : [],
      search_items    : [],
      coordinates     : {},
      lang            : i18n,
    }
  },
  mounted() {
    if(!(this.autofill.lat || this.autofill.lng)) {
      this.$getLocation()
      .then((coordinates) => {
        this.autofill.lat = coordinates.lat
        this.autofill.lng = coordinates.lng
        this.hasLocation  = true
        this.select_selected = 'nearest';
        if (this.$route.query.sort) {
          this.select_selected = this.$route.query.sort
        } else {
          this.select_selected = 'nearest'
        }

        this.get()
      })
      .catch(() => {
        if (this.$route.query.sort) {
          this.select_selected = this.$route.query.sort
        }
        this.hasLocation = false
        this.get()
      })
    }else {
      this.get()
    }


    EventBus.$on('langChanged', () => {
      // this.get()
    })
  },
  methods: {
    get(data) {
      this.loading = true
      if (!data) {
        data = this.queries

      }
      this.queries = data

      this.$http.get('/' + this.selected.toLowerCase() + '/search?page=' + this.page + '&q=' + data.q + '&location=' + data.location
          + '&city=' + data.city
          + '&zip_code=' + data.zip_code
          + '&sort=' + this.select_selected + '&lang=en',
          {
            params: this.autofill,
          })
          .then((response) => {
            this.loading              = false
            this.$data[this.selected] = response.data.data.data
            this.find_by              = response.data.find_by
            this.total                = response.data.data.total

            this.pages       = Math.round(response.data.data.total / response.data.data.per_page)
            this.currentPage = response.data.data.currentPage
          })
    },
    selectionHandler() {
      setTimeout(() => {
        var q = {
          q                          : this.$route.query.q || '',
          location                   : this.$route.query.location || '',
          street_number              : this.$route.query.street_number || '',
          route                      : this.$route.query.route || '',
          locality                   : this.$route.query.locality || '',
          administrative_area_level_1: this.$route.query.administrative_area_level_1 || '',
          country                    : this.$route.query.country || '',
          postal_code                : this.$route.query.postal_code || '',
          city                       : this.$route.query.city || '',
          zip_code                   : this.$route.query.zip_code || '',
          selected                   : this.selected,
          sort                       : this.sort,
        }
        this.$router.replace({
          name : 'searchpage',
          query: q,
        })
        // this.get(null)
      }, 500)

    },
    sortHandler() {
      setTimeout(() => {
        if (this.select_selected === 'nearest') {
          if (!this.hasLocation) {
            return
          }
        }
        var q = {
          q                          : this.$route.query.q || '',
          location                   : this.$route.query.location || '',
          street_number              : this.$route.query.street_number || '',
          route                      : this.$route.query.route || '',
          locality                   : this.$route.query.locality || '',
          administrative_area_level_1: this.$route.query.administrative_area_level_1 || '',
          country                    : this.$route.query.country || '',
          postal_code                : this.$route.query.postal_code || '',
          zip_code                   : this.$route.query.zip_code || '',
          city                       : this.$route.query.city || '',
          selected                   : this.selected,
          sort                       : this.select_selected,
        }
        this.$router.replace({
          name : 'searchpage',
          query: q,
        })
        // this.get(null)
      }, 500)

    },
    linkGen(pageNum) {
      // eslint-disable-next-line no-unreachable
      return {
        path : '/search',
        query: {
          page                       : pageNum === 1 ? 1 : pageNum,
          q                          : this.$route.query.q || '',
          location                   : this.$route.query.location || '',
          street_number              : this.$route.query.street_number || '',
          route                      : this.$route.query.route || '',
          locality                   : this.$route.query.locality || '',
          administrative_area_level_1: this.$route.query.administrative_area_level_1 || '',
          country                    : this.$route.query.country || '',
          postal_code                : this.$route.query.postal_code || '',
          zip_code                   : this.$route.query.zip_code || '',
          city                       : this.$route.query.city || '',
          selected                   : this.selected,
          sort                       : this.select_selected,
        },
      }
    },
  },
  watch  : {
    $route: function () {
      //
    },
  },
}

</script>
