<template>
    <div class="header">
        <div class="header-top">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="language">
                            <div class="selector">
                                <span class="text-capitalize">{{lang_show}}</span>
                                <b-dropdown @change="changeLang" size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <i class="fa fa-caret-down"></i>
                                    </template>
                                    <b-dropdown-item v-for="lang in supported_languages" :key="lang.key"  @click="changeLang(lang.key)"  href="#">{{lang.name}}</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                        <div class="mobile-nav">
                            <Slide>
                                <img class="img-fluid" src="../assets/logos/DentaSpark.svg">
                                <a id="home" v-on:click="$router.push('/').catch(err => {})"
                                   :class="{ 'active': $route.name === 'home' }">
                                    <span>{{ $t('menu.home') }}</span>
                                </a>
                                <a id="about" v-on:click="$router.push('/about').catch(err => {})"
                                   :class="{ 'active': $route.name === 'about' }">
                                    <span>{{ $t('menu.about') }}</span>
                                </a>
                                <a id="how_it_works" v-on:click="$router.push('/how-it-works').catch(err => {})"
                                   :class="{ 'active': $route.name === 'howitworks' }">
                                    <span>{{ $t('menu.howitworks') }}</span>
                                </a>
                                <a id="blog" v-on:click="$router.push('/blog').catch(err => {})"
                                   :class="{ 'active': $route.name === 'blog' }">
                                    <span>{{ $t('menu.blog') }}</span>
                                </a>
                                <a id="contact" v-on:click="$router.push('/contact').catch(err => {})"
                                   :class="{ 'active': $route.name === 'contact' }">
                                    <span>{{ $t('menu.contact') }}</span>
                                </a>
                                <hr/>
                                <span style="font-size: 13px;margin: 3px 0px 10px 33px !important;padding: 0px;" class="text-capitalize d-inline">{{lang_show}}</span>
                                <b-dropdown @change="changeLang" size="sm" variant="link" toggle-class="text-decoration-none p-0" no-caret >
                                    <template v-slot:button-content>
                                        <i class="fa fa-caret-down"></i>
                                    </template>
                                    <b-dropdown-item v-for="lang in supported_languages" :key="lang.key"  @click="changeLang(lang.key)"  href="#">{{lang.name}}</b-dropdown-item>
                                </b-dropdown>
                            </Slide>
                        </div>
                    </div>
                    <!--                    <div class="col-xl-8 col-lg-8"></div>-->
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="user-login text-right">
                            <router-link :to="{ name: 'login' }" class="login" v-if="!isAuth">
                                <i class="fa fa-user-o"/>{{ $t('menu.login') }}
                            </router-link>
                            <a class="login" href="#" v-else @click.prevent="logout">{{ $t('menu.logout') }}</a>
                        </div>
                        <div class="user-account">
                            <b-dropdown v-if="isAuth" id="dropdown-left" class="user-account-drop" variant="link"
                                        toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                    <img width="40" height="40" class="rounded-circle" v-if="user.patient"
                                         :src="user.patient.avatar">
                                    <img width="40" height="40" class="rounded-circle" v-else-if="user.professional"
                                         :src="user.professional.avatar">
                                    <img width="40" height="40" class="rounded-circle" v-else :src="user.avatar">
                                  <sup v-if="inboxCount > 0"><span class="badge badge-danger">1</span></sup>
                                </template>
                                <b-dropdown-item v-if="user.patient" to="/account-settings/profile">
                                                 {{ $t('menu.profile') }}
                                </b-dropdown-item>

                                <span v-if="user.professional">
                                    <b-dropdown-item to="/account-settings/professional">
                                        {{ $t('menu.profile') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/review">{{ $t('menu.startareview') }}</b-dropdown-item>
                                    <b-dropdown-item to="/my-patients">{{ $t('menu.mypatients') }}</b-dropdown-item>
                                    <b-dropdown-item to="/notifications">{{ $t('menu.sendnotification') }}</b-dropdown-item>
                                </span>
                                <b-dropdown-item to="/message-center/inbox">{{ $t('menu.messagecenter') }}</b-dropdown-item>
                                <b-dropdown-item @click.prevent="logout">{{ $t('menu.logout') }}</b-dropdown-item>
                            </b-dropdown>
                            <div class="login-link" v-else>
                                <router-link :to="{ name: 'login' }" class="login">
                                    <i class="fa fa-user-o"/> {{ $t('menu.login') }}
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="header-bottom">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3">
                        <div class="logo text-center">
                            <router-link to="/">
                                <img class="img-fluid" src="../assets/logos/DentaSpark.svg"/>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-9">
                        <div class="nav-custom">
                            <nav class="navbar navbar-expand-md ">
                                <!-- Toggler/collapsibe Button -->
                                <button
                                        class="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapsibleNavbar"
                                >
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <!-- Navbar links -->
                                <div class="collapse navbar-collapse" id="collapsibleNavbar">
                                    <ul class="navbar-nav mr-auto" :class="{ 'active': $route.name === 'home' }">
                                        <li class="nav-item" :class="{ 'active': $route.name === 'home' }">
                                            <router-link class="nav-link" :to="{ name: 'home' }"
                                            >{{ $t('menu.home') }}
                                            </router-link
                                            >
                                        </li>
                                        <li class="nav-item" :class="{ 'active': $route.name === 'about' }">
                                            <router-link class="nav-link" :to="{ name: 'about' }"
                                            >{{ $t('menu.about') }}
                                            </router-link
                                            >
                                        </li>
                                        <li class="nav-item" :class="{ 'active': $route.name === 'howitworks' }">
                                            <router-link class="nav-link" :to="{ name: 'howitworks'}">
                                                {{ $t('menu.howitworks') }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item" :class="{ 'active': $route.name === 'blog' }">
                                            <router-link class="nav-link" :to="{ name: 'blog' }"
                                            >{{ $t('menu.blog') }}
                                            </router-link
                                            >
                                        </li>
                                        <li class="nav-item" :class="{ 'active': $route.name === 'contact' }">
                                            <router-link class="nav-link" :to="{ name: 'contact' }"
                                            >{{ $t('menu.contact') }}
                                            </router-link
                                            >
                                        </li>
                                        <li class="nav-item" v-if="!isAuth">
                                            <router-link class="signup" :to="{ name: 'signup' }"
                                            >{{ $t('menu.signup').replace(/[\s\/]/g, '') }}
                                            </router-link>


                                        </li>
                                        <li class="nav-item" v-else>
                                            <b-dropdown id="dropdown-left" class="user-account-drop" variant="link"
                                                        toggle-class="text-decoration-none" no-caret>
                                                <template v-slot:button-content>
                                                    <img width="40" height="40" class="rounded-circle" v-if="user.patient"
                                                         :src="user.patient.avatar">
                                                    <img width="40" height="40" class="rounded-circle" v-else-if="user.professional"
                                                         :src="user.professional.avatar">
                                                    <img width="40"  height="40" class="rounded-circle" v-else :src="user.avatar">
                                                  <sup v-if="inboxCount > 0"><span class="badge badge-danger">1</span></sup>
                                                </template>

                                                <b-dropdown-item v-if="user.patient" to="/account-settings/profile">
                                                    {{ $t('menu.profile') }}
                                                </b-dropdown-item>


                                                <span v-if="user.professional">
                                                    <b-dropdown-item to="/account-settings/professional">
                                                        {{ $t('menu.profile') }}
                                                    </b-dropdown-item>
                                                    <b-dropdown-item to="/review">{{ $t('menu.startareview') }}</b-dropdown-item>
                                                    <b-dropdown-item to="/my-patients">{{ $t('menu.mypatients') }}</b-dropdown-item>
                                                    <b-dropdown-item
                                                            to="/notifications">{{ $t('menu.sendnotification') }}</b-dropdown-item>
                                                </span>
                                                <b-dropdown-item to="/message-center/inbox">{{ $t('menu.messagecenter') }}
                                                </b-dropdown-item>

                                                <b-dropdown-item v-if="isAuth" @click.prevent="logout">{{ $t('menu.logout') }}
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Slide} from 'vue-burger-menu';
    // import treeNav from 'vue-tree-nav';
    import {CONFIG} from "../config";
    import i18n from "../lang";
    import {http} from "../plugins/http";
    import { EventBus } from '../event-bus.js';

    export default {
        name: "Header",
        components: {
            Slide,
        },
        data() {
            return {
                config: CONFIG,
                supported_languages: [],
                lang_show: 'En',
              inboxCount: 0
            }
        },
        computed: {
            user() {
                return this.$store.getters.user;
            },
            isAuth() {
                // console.log(this.$store.getters.isAuth)
                return this.$store.getters.isAuth;
            }
        },
        mounted() {
            this.user;
            this.getLanguagesList()
            this.isAuth ? this.$store.dispatch('getUser') : null;
           setTimeout(() => {
               if(this.user.lang_preference === 'zh'){
                   this.getLang('zh');
                   this.$store.dispatch('setLang', 'CH');
                   this.lang_show = 'CH'
                   i18n.locale = 'zh';
                   http.defaults.headers.common['Accept-Language'] = 'zh';
                   document.querySelector('html').setAttribute('lang', 'zh');
               }else{
                   this.getLang('en');
                   this.lang_show = 'EN'
                   this.$store.dispatch('setLang', 'EN');
                   i18n.locale = 'en';
                   http.defaults.headers.common['Accept-Language'] = 'en';
                   document.querySelector('html').setAttribute('lang', 'en');
               }
           },2000)

          this.$http.get('/threads')
              .then((response) => {
                this.inboxCount = response.data.data.length;
              }).catch((err) => {})

        },
        methods: {
            logout() {
                const loading = this.$loading({
                    lock: true,
                    text: 'Please wait! Exiting...',
                });
                setTimeout(() => {
                    loading.close();
                    this.$store.dispatch('logout');
                    this.dialog = false;
                    this.$router.push({name: 'home'});
                }, 2000);
            },
            getLanguagesList(){
                this.$http.get('supportedlanguages')
                    .then((response) => {
                        this.supported_languages = response.data.data
                    })
            },
            changeLang(lng){
                this.getLang(lng)
                console.log(lng);
            },
            getLang(lng){
                this.$http.get('translations?group=app&local='+lng).then(res => {
                    i18n.setLocaleMessage(lng, res.data)
                    this.$store.dispatch('setLang', lng);
                    i18n.locale = lng;
                    if(lng === 'zh'){
                        this.lang_show = 'CH'
                    }

                    if(lng === 'en'){
                        this.lang_show = 'EN'
                    }

                    http.defaults.headers.common['Accept-Language'] = lng;
                    EventBus.$emit('langChanged', lng);
                    document.querySelector('html').setAttribute('lang', lng);
                });
            }
        }
    };
</script>
<style scoped>
.badge{
  left: -6px;
  top: -7px;
}
</style>
