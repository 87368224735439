<template>
    <div class="section account-settings">
        <div class="view">
            <div class="container">
                <h3 class="title">Register New Office</h3>
                <br>
                <br>
                <office-add-form/>
            </div>
        </div>
    </div>
</template>

<script>
    /*eslint-env jquery*/
    import OfficeAddForm from "../components/AccountSettings/OfficeAddForm";



    export default {
        name: "AccountSettings",
        components: {
            "office-add-form": OfficeAddForm,
        },
        data() {
            return {
                tabIndex: 0,
                profile_type: this.$route.params.profileType === "profile",
                officeType: true,
                form_password: {
                    old_password: '',
                    password: '',
                    password_confirmation: ''
                },
                response_errors: [],
                loading: false,
                languages: {
                    selected: 'english',
                    options: [
                        {text: 'English', value: 'english'},
                        {text: 'Chinese', value: 'chinese'}
                    ]
                }
            }
        },
        computed: {
            user() {
                return this.$store.getters.user;
            }
        },
        created() {

        },

        methods: {

        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>