<template>
    <div class="works">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                    <div class="text text-left">
                        <h3 class="pb-3">{{ $t('home.howhealthydentalworks') }}</h3>
                        <p class="text-justify pb-3">{{ $t('home.howhealthydentalworksmeta1') }}</p>
                        <p class="text-justify pb-3">{{ $t('home.howhealthydentalworksmeta2') }}</p>
                        <div class="text-center">
                            <button v-on:click="$router.push('/how-it-works').catch(err => {})" class="link">{{ $t('home.readmore') }}</button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div class="row">
                        <div
                                class="col-xl-4 col-lg-4 col-md-4 col-sm-4"
                                v-for="(workingstep, index)  in workingsteps"
                                v-bind:key="workingstep.id"
                        >
                            <div class="steps">
                                <div class="img-container">
                                    <h1>{{index+1}}</h1>
                                </div>
                                <div class="text-wrap mt-5">
                                    <h3>Step {{index+1}}</h3>
                                    <p v-html="workingstep.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "howWorks",
        props: {
            workingsteps: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        mounted() {
        }
    };
</script>
