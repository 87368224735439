<template>
  <div class="hours" v-if="offices.length">
    <h3>{{$t('profile.offices')}}</h3>
    <div class="row">
      <div v-for="office in offices" :key="'office'+office.id" class="col-xl-6 col-lg-6 col-md-6">
        <div class="panel card-shadow">
          <div class="head">
            <div class="img-container">
              <img :src="office.thumbnail"/>
            </div>
            <div class="details">
              <h4>
                <router-link :to="'/office-profile?office_id=' + office.id+'&lang='+lang">
                  {{ officeName(office) }}
                </router-link>
              </h4>
              <div class="ratings">
                <span class="stars">
                  <i v-for="n in 5"
                     :key="'stars'+n"
                     :class="{'active' : office.rating >= n}"
                     class="fa fa-star active "/>
                </span>
                <span class="count">({{ office.reviews.length }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name   : 'OfficesCard',
  props  : {
    lang   : {
      default: 'en',
      type   : String,
    },
    offices: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  methods: {
    officeName: function (office) {
      if(this.lang!=='en'){
        if(office.multi_language_office.length){
          return office.multi_language_office[0].name;
        }
      }
      return office ? office.name : ''
    },
  },
}
</script>
<style scoped>
.hours table tr {
  padding: 0px;
  font-size: 13px;
}
</style>
