<template>
    <div class="section bgimg">
        <div class="mask"></div>
        <div class="close-mark" v-on:click="$router.push('/').catch(err => {})">
            <i class="fa fa-times"/>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="left-panel">
                        <h1>
                            Find A Dental
                            <br/>Services Near You.
                        </h1>
                        <p class="col-md-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                            vel est ac sem porttitor luctus. Cras porttitor ac tellus et
                            blandit. Proin fermentum arcu sapien, ut gravida lorem vestibulum
                            eget. Donec at vehicula tellus.
                        </p>
                    </div>
                </div>
                <div class="offset-xl-2 offset-lg-2"></div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="reg-panel">
                        <router-link to="/">
                            <img class="mb-5" src="../../assets/logos/DentaSpark.svg" alt/>
                        </router-link>
                        <div class="text-center">
                            <i class="fa fa-heart mb-5"/>
                          <span v-if="this.$route.query.usertype == 'user'">
                            <h5>{{$t('thankyou.title.patient')}}</h5>
                            <p>{{$t('thankyou.text.patient')}}</p>
                          </span>
                          <span v-else>
                            <h5>{{$t('thankyou.title')}}</h5>
                            <p>{{$t('thankyou.text')}}</p>
                          </span>

                            <div class="btn-wrap">
                                <router-link class="btn btn-success btn-block" to="/">Home</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "thankyou",
        methods: {
            emitToSignUp() {
                this.$emit("submitAccountRegistrationCompletionStatus", false);
            }
        }
    };
</script>
<style scoped>
    * {
        font-family: "Poppins" !important;
    }

    .fa {
        font-family: "FontAwesome" !important;
    }

    h1 {
        color: #ffffff;
        font-size: 56px;
        font-weight: bold;
    }

    p {
        color: #ffffff;
        font-size: 16px;
        padding: 10px 0px;
    }

    .bgimg {
        background: url("../../assets/images/bg-imag.png") no-repeat -430px 0 fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        padding-top: 120px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .bgimg .mask {
        position: fixed; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(
                255,
                255,
                255,
                0.2
        ); /* Black background with opacity */
    }

    .close-mark {
        text-align: center;
        position: absolute;
        top: 20px;
        left: 80px;
        cursor: pointer;
    }

    .close-mark i {
        font-size: 30px;
        color: #6b6b6b;
        background-color: rgba(126, 126, 126, 0.1);
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        transition: ease-out 0.3s;
    }

    .close-mark:hover > i {
        background-color: rgba(126, 126, 126, 0.2);
    }

    .reg-panel h5 {
        color: #2C3E50;
        font-size: 20px;
    }

    .reg-panel .fa.fa-heart {
        color: #0FBC9C;
        font-size: 96px;
    }

    .reg-panel p {
        color: #3a3a3a;
    }

    @media only screen and (max-width: 768px) {
        .bgimg {
            padding-top: 70px;
        }

        .bgimg .mask {
            background-color: rgba(
                    255,
                    255,
                    255,
                    0.1
            ); /* Black background with opacity */
        }

        .close-mark {
            top: 2px;
            left: 50px;
        }

        .reg-panel {
            text-align: center;
        }

        .img-wrap img {
            width: 100px;
            height: 100px;
        }
    }

    @media only screen and (max-width: 480px) {
        .bgimg {
            background: none;
        }

        .close-mark {
            display: none;
        }

        .left-panel {
            display: none;
        }

        .reg-panel img {
            max-width: 100%;
        }

        form input::placeholder {
            font-size: 12px !important;
        }
    }
</style>
