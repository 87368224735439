<template>
    <div>
        <div class="mb-5">
            <professional-items :professionals="{professionals}" class="offices" :page_type="false"/>
        </div>
        <ValidationObserver ref="form">
            <b-form @submit="onSubmitReview">
                <b-row>
                    <b-col xl="6" lg="6" md="6" sm="12">
                        <ValidationProvider name="cleanliness" rules="required" v-slot="{errors}">
                            <div class="content">
                                <span class="text">{{ $t('review.cleanliness') }}</span><br/>
                                <div class="stars">
                                    <input class="star star-5"
                                        id="cleanliness-5" type="radio" name="cleanliness" value="5"
                                        v-model="form.cleanliness"/>
                                    <label class="star star-5" for="cleanliness-5"/>
                                    <input class="star star-4" id="cleanliness-4" type="radio" name="cleanliness" value="4"
                                        v-model="form.cleanliness"/>
                                    <label class="star star-4" for="cleanliness-4"/>
                                    <input class="star star-3" id="cleanliness-3" type="radio" name="cleanliness" value="3"
                                        v-model="form.cleanliness"/>
                                    <label class="star star-3" for="cleanliness-3"/>
                                    <input class="star star-2" id="cleanliness-2" type="radio" name="cleanliness" value="2"
                                        v-model="form.cleanliness"/>
                                    <label class="star star-2" for="cleanliness-2"/>
                                    <input class="star star-1" id="cleanliness-1" type="radio" name="cleanliness" value="1"
                                        v-model="form.cleanliness"/>
                                    <label class="star star-1" for="cleanliness-1"/>
                                </div>
                                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                            </div>
                        </ValidationProvider>
                    </b-col>
                    <b-col xl="6" lg="6" md="6" sm="12">
                        <ValidationProvider name="communication" rules="required" v-slot="{errors}">
                            <div class="content">
                                <span class="text">{{ $t('review.communication') }}</span><br/>
                                <div class="stars">
                                    <input class="star star-5"
                                        id="communication-5" type="radio" name="communication"
                                        value="5"
                                        v-model="form.communication"/>
                                    <label class="star star-5" for="communication-5"/>
                                    <input class="star star-4" id="communication-4" type="radio" name="communication"
                                        value="4"
                                        v-model="form.communication"/>
                                    <label class="star star-4" for="communication-4"/>
                                    <input class="star star-3" id="communication-3" type="radio" name="communication"
                                        value="3"
                                        v-model="form.communication"/>
                                    <label class="star star-3" for="communication-3"/>
                                    <input class="star star-2" id="communication-2" type="radio" name="communication"
                                        value="2"
                                        v-model="form.communication"/>
                                    <label class="star star-2" for="communication-2"/>
                                    <input class="star star-1" id="communication-1" type="radio" name="communication"
                                        value="1"
                                        v-model="form.communication"/>
                                    <label class="star star-1" for="communication-1"/>
                                </div>
                                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                            </div>
                        </ValidationProvider>
                    </b-col>
                    <b-col xl="12" lg="12" md="12" sm="12">
                        <ValidationProvider name="waiting_time" rules="required" v-slot="{errors}">
                            <div class="content">
                                <span class="text">{{ $t('review.reasonabletime') }}</span><br/>
                                <div class="stars">
                                    <input class="star star-5"
                                        id="waiting_time-5" type="radio" name="waiting_time"
                                        value="5"
                                        v-model="form.waiting_time"/>
                                    <label class="star star-5" for="waiting_time-5"/>
                                    <input class="star star-4" id="waiting_time-4" type="radio" name="waiting_time"
                                        value="4"
                                        v-model="form.waiting_time"/>
                                    <label class="star star-4" for="waiting_time-4"/>
                                    <input class="star star-3" id="waiting_time-3" type="radio" name="waiting_time"
                                        value="3"
                                        v-model="form.waiting_time"/>
                                    <label class="star star-3" for="waiting_time-3"/>
                                    <input class="star star-2" id="waiting_time-2" type="radio" name="waiting_time"
                                        value="2"
                                        v-model="form.waiting_time"/>
                                    <label class="star star-2" for="waiting_time-2"/>
                                    <input class="star star-1" id="waiting_time-1" type="radio" name="waiting_time"
                                        value="1"
                                        v-model="form.waiting_time"/>
                                    <label class="star star-1" for="waiting_time-1"/>
                                </div>
                                <p class="mt-2" style="color: #ff0000;font-size: 13px" v-if="errors[0]">{{ errors[0] }}</p>
                            </div>
                        </ValidationProvider>
                    </b-col>
                    <b-col xl="12" lg="12" md="12" sm="12" class="mt-3">
                        <div>
                            <b-form-textarea
                                    id="textarea"
                                    :placeholder="$t('review.commentplaceholder')"
                                    rows="4"
                                    max-rows="5"
                                    v-model="form.text"
                                    name="text"
                            />
                        </div>
                    </b-col>
                    <b-col xl="12" lg="12" md="12" sm="12" class="mt-3 mb-5">
                        <b-form-checkbox
                                id="check"
                                name="checkbox-1"
                                value="1"
                                v-model="form.anonymous"
                        >{{ $t('review.postcommentanonymously') }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col xl="6" lg="6" md="6" sm="12" offset="3">
                        <button type="submit"
                                class="btn btn-success btn-block">{{ $t('form.submit') }}
                        </button>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from "vee-validate";
import ProfessionalItem from "./ProfessionalItem";
    import _ from 'lodash'

    export default {
        name: "reviewOffice",
        components: {
            "professional-items": ProfessionalItem,
            ValidationObserver,
            ValidationProvider
        },
        props: {
            form: {
                type: Object
            },
            professionals: {
                type: Array
            },
            office: {
                type: Array
            }
        },
        data(){
          return {
              routePath: ''
          }
        },
        methods: {
            onSubmitReview(evt) {
                evt.preventDefault();
                this.$refs.form.validate().then(result => {
                    if (result) {
                        this.$props.form.type = 'professional'
                        this.$props.form.patient_id = this.$route.query.patient_id
                        var ids = _.split(this.$route.params.name,',');

                        this.$props.form.professional_id = ids[0]
                        this.$props.form.existing_patient = this.$route.query.existing_patient

                        var office_id = this.$route.query.office_id ? this.$route.query.office_id : '';
                    
                        this.$http.post('/reviews/', this.$props.form)
                            .then(() => {
                                if(ids.length > 1){
                                    ids = _.drop(ids,1)

                                    this.routePath = '/review/professional/' + _.join(ids,',') + '/write?' + 'patient_id='+this.$props.form.patient_id+'&existing_patient='+this.$props.form.existing_patient+'&office_id='+office_id;
                                    this.$router.push(this.routePath)

                                    this.message.success('Thanks for the submit your review. Continue...');
                                }else{
                                    this.$router.push('/review/thank-you')
                                }
                            })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    @import url('//netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');

    div.stars {
        width: 150px;
        display: inline-block;
    }

    input.star {
        display: none;
    }

    label.star {
        float: right;
        padding: 3px;
        font-size: 24px;
        color: #444;
        transition: all .2s;
        font-family: FontAwesome !important;
        cursor: pointer;
    }

    input.star:checked ~ label.star:before {
        font-family: FontAwesome !important;
        content: '\f005';
        color: #FD4;
        transition: all .25s;
    }

    input.star-5:checked ~ label.star:before {
        font-family: FontAwesome !important;
        color: #FE7;
        text-shadow: 0 0 3px #952;
    }

    input.star-1:checked ~ label.star:before {
        font-family: FontAwesome !important;
        color: #F62;
    }

    label.star:hover {
        transform: rotate(-15deg) scale(1.3);
    }

    label.star:before {
        content: '\f006';
        font-family: FontAwesome !important;
    }
</style>