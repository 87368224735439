<template>
    <div class="manage-office">
        <div  class=" mb-5">

        </div>

        <b-tabs @input="tabHandler" content-class="mt-3">
            <b-tab :title="$t('profile.manageoffice')" v-loading="office_loading" active>
                <office-form @change="getOffices" v-if="!office_loading" :form_professional="office"></office-form>
            </b-tab>
            <b-tab class="o-hours" :title="$t('profile.officehours')">
                <span v-if="hours_show">
                    <hours placement="ownership" @change-working-hours="handleWorkingHours" :working_hours_data="office.working_days"/>

                    <div class="my-4">
                        <button @click="save()" class="btn btn-success ">{{ $t('profile.save') }}</button>
                        <router-link style="border: 1px solid #343a40;margin-top: 10px;" class="btn ml-4"   to="/account-settings/professional?tab=office" >{{ $t('profile.manageofficebackbutton') }}</router-link>
                    </div>

                </span>
            </b-tab>
            <b-tab :title="$t('profile.teammembers')" @click="memberType = true"
            >
                <div class="members" v-if="memberType">
                    <members @updated="get" :office="office" :members="members"  :make-admin="true"/>

                    <b-row class="mt-4">
                        <b-col xl="6" lg="6" md="12" sm="12">
                            <!--<button v-on:click="$router.push('/account-settings/professional/?tab=office&id=1&miniTab=members&memberType=new').catch(err => {})"
                                    class="btn btn-success btn-block">Add Team Member
                            </button>-->
                            <button v-on:click="memberType = false"
                                    class="btn btn-success btn-block">{{ $t('profile.addteammember') }}
                            </button>
                        </b-col>
                    </b-row>
                </div>
                <div class="new-member" v-else>
                    <b-form @submit.prevent class="searchForm">
                        <b-input-group>
                            <template v-slot:append>
                                <b-input-group-text><i class="fa fa-search"></i></b-input-group-text>
                            </template>
                            <b-form-input
                                    id="searchMember"
                                    class="form-control"
                                    v-model="form.search"
                                    type="text"
                                    required
                                    :placeholder="$t('profile.enterprofessionalname')"
                                    @keyup="onSearch($event.target.value)"
                            />
                        </b-input-group>
                    </b-form>

                    <div v-if="!memberType">
                        <div v-if="filteredMembers">
                            <members class="mt-5" :office="office"
                                     :members="filteredMembers" :memberSelect="memberSelect"/>

                        </div>
                    </div>

                    <div v-if="warning" class="form-alert mt-5">
                        <div class="warning mb-5">
                            <p><i class="fa fa-exclamation" aria-hidden="true"></i></p>
                            <p class="text">

                            </p>
                        </div>

                        <b-form @submit.prevent class="invitation">
                            <b-form-group>
                                <b-form-input
                                        id="invitationInput"
                                        class="form-control"
                                        type="email"
                                        required
                                        placeholder="Enter Email Address"
                                />
                            </b-form-group>
                            <b-form-group class="mt-3" v-if="selected_member.length">
                                <b-row>
                                    <b-col xl="6" lg="6" md="6" sm="12">
                                        <b-button type="button" class="btn-block" variant="success">{{
                                            $t('profile.sendinvitation') }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-form>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

    import Hours from "./Hours";
    import Members from "./Members";
    import OfficeForm from "./OfficeForm";


    export default {
        name: "OfficeItem",
        components: {
            "hours": Hours,
            "members": Members,
            "office-form": OfficeForm
        },
        data: () => {
            return {
                hours_show: false,
                search: '',
                office: {},
                searchResult: false,
                office_loading: true,
                warning: false,
                miniTab: true,
                memberType: true,
                memberSelect: true,
                form: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    address: '',
                    phone: '',
                    languages: {
                        selected: 'english',
                        options: [
                            {text: 'English', value: 'english'},
                            {text: 'Chinese', value: 'chinese'}
                        ]
                    }
                },
                form_professional: {
                    email: '',
                    office_name: '',
                    address: '',
                    phone: '',
                    bio: '',
                    school: '',
                    about_office: '',
                    years: {
                        selected: 1,
                        options: [
                            {
                                value: 1,
                                text: 2000
                            },
                            {
                                value: 2,
                                text: 2001
                            },
                            {
                                value: 3,
                                text: 2002
                            },
                            {
                                value: 4,
                                text: 2004
                            },
                            {
                                value: 5,
                                text: 2005
                            },
                            {
                                value: 6,
                                text: 2006
                            },
                        ]
                    },
                    services: {
                        selected: [],
                        options: [
                            {
                                value: 1,
                                text: 'Dental Cleaning'
                            },
                            {
                                value: 2,
                                text: 'Dental Fillings'
                            },
                            {
                                value: 3,
                                text: 'Root Canal Treatments'
                            },
                            {
                                value: 4,
                                text: 'Crowns and Bridges'
                            },
                            {
                                value: 5,
                                text: 'Extractions'
                            }
                        ]
                    },
                    languages: {
                        selected: 'english',
                        options: [
                            {text: 'English', value: 'english'},
                            {text: 'Chinese', value: 'chinese'}
                        ]
                    },
                    gender: {
                        selected: 'male',
                        options: [
                            {text: 'Male', value: 'male'},
                            {text: 'Female', value: 'female'},
                            {text: 'Other', value: 'other'}
                        ]
                    }
                },
                date: {
                    monChecked: [],
                    mon: {
                        hours: [
                            {
                                id: 1,
                                checked: false,
                                time: "06:30"
                            }, {
                                id: 2,
                                checked: false,
                                time: "07:30"
                            }, {
                                id: 3,
                                checked: false,
                                time: "08:30"
                            }, {
                                id: 4, checked: false,
                                time: "09:30"
                            }, {
                                id: 5, checked: false,
                                time: "10:30"
                            }, {
                                id: 6, checked: false,
                                time: "11:30"
                            }, {
                                id: 7, checked: false,
                                time: "12:30"
                            }, {
                                id: 8, checked: false,
                                time: "13:30"
                            }, {
                                id: 9, checked: false,
                                time: "14:30"
                            }, {
                                id: 10, checked: false,
                                time: "15:30"
                            }, {
                                id: 11, checked: false,
                                time: "16:30"
                            }, {
                                id: 12, checked: false,
                                time: "17:30"
                            }, {
                                id: 13, checked: false,
                                time: "18:30"
                            },
                        ]
                    },
                    tueChecked: [],
                    tue: {
                        hours: [
                            {
                                id: 1,
                                checked: false,
                                time: "06:30"
                            }, {
                                id: 2,
                                checked: false,
                                time: "07:30"
                            }, {
                                id: 3,
                                checked: false,
                                time: "08:30"
                            }, {
                                id: 4,
                                checked: false,
                                time: "09:30"
                            }, {
                                id: 5,
                                checked: false,
                                time: "10:30"
                            }, {
                                id: 6,
                                checked: false,
                                time: "11:30"
                            }, {
                                id: 7,
                                checked: false,
                                time: "12:30"
                            }, {
                                id: 8,
                                checked: false,
                                time: "13:30"
                            }, {
                                id: 9,
                                checked: false,
                                time: "14:30"
                            }, {
                                id: 10,
                                checked: false,
                                time: "15:30"
                            }, {
                                id: 11,
                                checked: false,
                                time: "16:30"
                            }, {
                                id: 12,
                                checked: false,
                                time: "17:30"
                            }, {
                                id: 13,
                                checked: false,
                                time: "18:30"
                            },
                        ]
                    },
                    wedChecked: [],
                    wed: {
                        hours: [
                            {
                                id: 1,
                                checked: false,
                                time: "06:30"
                            }, {
                                id: 2,
                                checked: false,
                                time: "07:30"
                            }, {
                                id: 3,
                                checked: false,
                                time: "08:30"
                            }, {
                                id: 4,
                                checked: false,
                                time: "09:30"
                            }, {
                                id: 5,
                                checked: false,
                                time: "10:30"
                            }, {
                                id: 6,
                                checked: false,
                                time: "11:30"
                            }, {
                                id: 7,
                                checked: false,
                                time: "12:30"
                            }, {
                                id: 8,
                                checked: false,
                                time: "13:30"
                            }, {
                                id: 9,
                                checked: false,
                                time: "14:30"
                            }, {
                                id: 10,
                                checked: false,
                                time: "15:30"
                            }, {
                                id: 11,
                                checked: false,
                                time: "16:30"
                            }, {
                                id: 12,
                                checked: false,
                                time: "17:30"
                            }, {
                                id: 13,
                                checked: false,
                                time: "18:30"
                            },
                        ]
                    },
                    thuChecked: [],
                    thu: {
                        hours: [
                            {
                                id: 1,
                                checked: false,
                                time: "06:30"
                            }, {
                                id: 2,
                                checked: false,
                                time: "07:30"
                            }, {
                                id: 3,
                                checked: false,
                                time: "08:30"
                            }, {
                                id: 4,
                                checked: false,
                                time: "09:30"
                            }, {
                                id: 5,
                                checked: false,
                                time: "10:30"
                            }, {
                                id: 6,
                                checked: false,
                                time: "11:30"
                            }, {
                                id: 7,
                                checked: false,
                                time: "12:30"
                            }, {
                                id: 8,
                                checked: false,
                                time: "13:30"
                            }, {
                                id: 9,
                                checked: false,
                                time: "14:30"
                            }, {
                                id: 10,
                                checked: false,
                                time: "15:30"
                            }, {
                                id: 11,
                                checked: false,
                                time: "16:30"
                            }, {
                                id: 12,
                                checked: false,
                                time: "17:30"
                            }, {
                                id: 13,
                                checked: false,
                                time: "18:30"
                            },
                        ]
                    },
                    friChecked: [],
                    fri: {
                        hours: [
                            {
                                id: 1,
                                checked: false,
                                time: "06:30"
                            }, {
                                id: 2,
                                checked: false,
                                time: "07:30"
                            }, {
                                id: 3,
                                checked: false,
                                time: "08:30"
                            }, {
                                id: 4,
                                checked: false,
                                time: "09:30"
                            }, {
                                id: 5,
                                checked: false,
                                time: "10:30"
                            }, {
                                id: 6,
                                checked: false,
                                time: "11:30"
                            }, {
                                id: 7,
                                checked: false,
                                time: "12:30"
                            }, {
                                id: 8,
                                checked: false,
                                time: "13:30"
                            }, {
                                id: 9,
                                checked: false,
                                time: "14:30"
                            }, {
                                id: 10,
                                checked: false,
                                time: "15:30"
                            }, {
                                id: 11,
                                checked: false,
                                time: "16:30"
                            }, {
                                id: 12,
                                checked: false,
                                time: "17:30"
                            }, {
                                id: 13,
                                checked: false,
                                time: "18:30"
                            },
                        ]
                    },
                    satChecked: [],
                    sat: {
                        hours: [
                            {
                                id: 1,
                                checked: false,
                                time: "06:30"
                            }, {
                                id: 2,
                                checked: false,
                                time: "07:30"
                            }, {
                                id: 3,
                                checked: false,
                                time: "08:30"
                            }, {
                                id: 4,
                                checked: false,
                                time: "09:30"
                            }, {
                                id: 5,
                                checked: false,
                                time: "10:30"
                            }, {
                                id: 6,
                                checked: false,
                                time: "11:30"
                            }, {
                                id: 7,
                                checked: false,
                                time: "12:30"
                            }, {
                                id: 8,
                                checked: false,
                                time: "13:30"
                            }, {
                                id: 9,
                                checked: false,
                                time: "14:30"
                            }, {
                                id: 10,
                                checked: false,
                                time: "15:30"
                            }, {
                                id: 11,
                                checked: false,
                                time: "16:30"
                            }, {
                                id: 12,
                                checked: false,
                                time: "17:30"
                            }, {
                                id: 13,
                                checked: false,
                                time: "18:30"
                            },
                        ]
                    },
                },
                members: [],
                filteredMembers: [],
                selected_member: [],
                selected_langauge: 'en'
            }
        },
        mounted() {
            this.get()
        },
        watch: {
            $route: function () {
                if (this.$route.query.memberType === "all") {
                    this.memberType = true;
                } else {
                    this.memberType = false;
                }
            }
        },
        methods: {
            save() {
                this.$http.post('/offices/' + this.$route.query.office_id, this.office)
                    .then(() => {
                        this.$message.success('changes has been saved!')
                    })
            },
            onSearch(value) {
                if (!value) {
                    this.filteredMembers = []
                    return
                }
                this.$http.get('/professionals/search?q=' + value)
                    .then((response) => {
                        this.filteredMembers = response.data.data.data
                    })
            },
            getOffices(lang){
              this.selected_langauge =   lang;
              this.get()
            },
            get() {
                if(this.$route.query.office_id) {
                    this.$http.get("/offices/" + this.$route.query.office_id+'?lang='+this.selected_langauge)
                    .then((response) => {
                        this.office = response.data.data

                        if(response.data.data.multi_language_office && response.data.data.multi_language_office.length){
                            if(this.selected_langauge !== 'en'){
                                var langProf = response.data.data.multi_language_office[0];
                                this.office.name = langProf.name;
                                this.office.bio = langProf.bio;
                                this.office.phone_number = langProf.phone_number;
                            }
                        }else{
                            if(this.selected_langauge !== 'en'){
                                this.office.name = '';
                                this.office.bio = '';
                                this.office.phone_number = '';
                            }
                        }

                        this.members = this.office.professionals
                        this.office_loading = false;
                    })
                }
            },
            handleWorkingHours(data) {
                this.office.working_days = data
            },
            tabHandler(tab) {
                if (tab === 1) {
                    this.hours_show = true
                } else {
                    this.hours_show = false
                }

                this.get();
            }
        }
    }
</script>