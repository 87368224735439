<template>
    <div>
        <p>{{ $t('signup.claimprofile') }}</p>
        <ValidationObserver ref="form">
            <form>
                <div class="form-group boder-bottom element">
                    <span v-if="file_name">{{file_name}}</span>
                    <span v-else>{{ $t('form.file') }}</span>
                    <i class="fa fa-file" @click="chooseFiles()"></i>
                    <input type="file" ref="file" id="fileUpload" @change="onFileChange" hidden/>
                </div>
                <p class="errors" v-if="file_upload">{{ $t('form.uploadproof') }}</p>

                <div class="form-group">
                    <ValidationProvider name="work_email" rules="required" v-slot="{errors}">
                        <input
                            v-model="form.claimProfile.work_email"
                            name="work_email"
                            type="email"
                            :placeholder="$t('form.workemail')"
                        />
                        <p class="errors" v-if="errors[0]">{{errors[0]}}</p>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <ValidationProvider name="number" rules="required" v-slot="{errors}">
                        <input
                            v-model="form.claimProfile.work_phone"
                            name="number"
                            type="text"
                            :placeholder="$t('form.workphone')"
                        />
                        <p class="errors" v-if="errors[0]">{{errors[0]}}</p>
                    </ValidationProvider>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
    export default {
        name: "claimprofile",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            form: {
                type: Object
            }
        },
        data() {
            return {
                file_name: "",
                file_upload: false
            };
        },
        methods: {
            chooseFiles() {
                document.getElementById("fileUpload").click();
            },
            onFileChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                let form = this.form;
                let formData = new FormData();
                let $this = this;
                if (files.length) {
                    this.form.claimProfile.proof_1 = files[0];
                    this.file_upload = false;

                    formData.append("file", files[0]);
                    this.$http
                        .post(process.env.VUE_APP_API_URL + "/claims/uploadProofFile", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(function (response) {
                            form.claimProfile.proof_1 = response.data.proof_1;
                            form.claimProfile.file_name_1 = response.data.file_name_1;
                            $this.file_name = response.data.file_name_1;
                        })
                        .catch(function (e) {
                            console.log("FAILURE!!", e);
                        });
                }
            },
            async validation() {
                this.$refs.form.validate();
                let isValid = await this.$refs.form.validate();
                this.$emit("on-validation", this.$props.form, isValid);
                return isValid;
            }
        }
    };
</script>
<style scoped>
    p {
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #e9e9f0;
        width: 100%;
        background-color: transparent;
        font-family: Poppins;
        font-size: 15px;
        margin: 0.5rem 0;
    }

    input:focus {
        border-color: black;
    }

    .btn-signup {
        color: #ffffff;
        background-color: #0fbc9c;
        border-color: #0fbc9c;
        font-weight: bold;
        border-radius: 1px;
        width: 100%;
    }

    .btn-signup:hover,
    .btn-signup:active,
    .btn-signup:focus,
    .btn-signup.active {
        background: #169e85;
        color: #ffffff;
        border-color: #0fbc9c;
    }

    select {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #e9e9f0;
        width: 100%;
        background-color: transparent;
        font-family: Poppins;
        margin: 0.5rem 0;
    }

    select:focus {
        border-color: black;
    }

    .btn-wrap {
        padding-bottom: 30px;
    }

    .no-border {
        border: none;
    }

    .label-wrap {
        border: transparent;
        display: table-row-group;
    }

    label.filebutton {
        width: 120px;
        height: 40px;
        overflow: hidden;
        position: relative;
        background-color: white;
        border-bottom-style: solid;
        padding: 0px;
    }

    label span input {
        z-index: 999;
        line-height: 0;
        font-size: 50px;
        position: absolute;
        top: -2px;
        left: -700px;
        opacity: 0;
        filter: alpha(opacity=0);
        -ms-filter: "alpha(opacity=0)";
        cursor: pointer;
        _cursor: hand;
        margin: 0;
        padding: 0;
    }

    .boder-bottom {
        border-bottom: 0.1rem solid;
        border-bottom-color: #e9e9f0;
    }

    .errors {
        text-align: left;
        color: red;
        font-weight: 400;
        font-size: 12px;
    }

    .element {
        display: flex;
        justify-content: space-between;
    }

    @media only screen and (max-width: 480px) {
        form input,
        form select {
            font-size: 12px !important;
        }

        form input::placeholder {
            font-size: 12px !important;
        }
    }
</style>
