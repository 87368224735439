<template>
    <div v-loading="loading" class="section bgimg">
        <div class="mask"></div>
        <div class="close-mark" v-on:click="$router.push('/').catch(err => {})">
            <i class="fa fa-times"/>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-6 col-lg-6  col-md-6">
                    <div class="left-panel">
                        <h1>
                            {{ $t('home.title') }}
                        </h1>
                        <p class="col-md-10">
                            {{ $t('home.subtitle') }}
                        </p>
                    </div>
                </div>
                <div class="offset-xl-2 offset-lg-2"></div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="reg-panel">
                        <router-link to="/"><img class="mb-5 img-fluid" src="../assets/logos/DentaSpark.svg" alt/>
                        </router-link>
                        <!--                        <p style="margin: 20px 0;">please fill the information below</p>-->
                        <div v-if="message" class="alert alert-danger" role="alert">
                            {{message}}
                        </div>
                        <ValidationObserver ref="form">
                            <form @submit.prevent="submit()">
                                <div class="form-group">
                                    <ValidationProvider name="email" rules="required|email" v-slot="{errors}">
                                        <input name="email" type="email" v-model="user.email"
                                        :placeholder="$t('form.emailplaceholder')" />
                                        <span style="color: red" v-if="errors[0]">{{ errors[0]}}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <ValidationProvider name="password" rules="required|min:6" v-slot="{errors}">
                                        <input name="password" type="password" v-model="user.password" :placeholder="$t('form.passwordplaceholder')"
                                        />
                                        <span style="color: red"
                                            v-if="errors[0]">{{ errors[0]}}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="row texts">
                                    <div class="col-xl-7 col-lg-7 col-sm-6 text-left">
                                        <b-form-checkbox
                                                id="checkbox-1"
                                                name="checkbox-1"
                                                value="1"
                                        >
                                            {{ $t('login.rememberme') }}
                                        </b-form-checkbox>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 col-sm-6 text-right">
                                        <router-link
                                                class="smalltext"
                                                :to="{ name: 'forget-password' }"
                                        >{{ $t('login.forgotpassword') }}
                                        </router-link
                                        >
                                    </div>
                                </div>

                                <div class="row">
                                    <div
                                            class="col-xl-6 col-lg-6 col-sm-12 text-left padding-top-sm"
                                    >
                                        <button type="submit" class="btn btn-signup login-btn">
                                            {{ $t('menu.login') }}
                                        </button>
                                    </div>
                                    <div
                                            class="col-xl-6 col-lg-6 col-sm-12 text-right padding-top-sm"
                                    >
                                        <router-link class="btn btn-signup" :to="{ name: 'signup' }"
                                        >{{ $t('menu.signup') }}
                                        </router-link
                                        >
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
    export default {
        name: "login",
        components: {
    ValidationProvider,
    ValidationObserver
},
        data() {
            return {
                user: {
                    email: '',
                    password: '',
                    type: "frontend"
                },
                message: "",
                loading: false
            }
        },
        mounted() {
            if (this.$route.query.email) {
                // check if the user email exists
                this.checkAccountExists(this.$route.query.email);
                this.user.email = this.$route.query.email;
            }
        },
        methods: {
            submit() {

                this.$refs.form.validate().then(result => {
                    if (result) {
                        this.loading = true;
                        setTimeout(() => {
                            this.$http.post('login', this.user).then(res => {
                                console.log('Login Data, ',res.data.data);
                                this.$store.dispatch('setToken', res.data.data.token);
                                this.$store.dispatch('getUser');

                                /*if (this.$route.query.redirectTo) {
                                    //return this.$router.push(this.$route.query.redirectTo);
                                }*/
                                console.log(res);
                                this.$router.push({name: 'home'});
                                // eslint-disable-next-line no-unused-vars
                            }).catch(error => {
                                this.message = error.response.data.message

                                this.loading = false;
                                this.$message({
                                    showClose: true,
                                    message: 'Oops, please check you input.',
                                    type: 'error'
                                })
                            })
                        }, 1000)
                    }
                });


            },

            checkAccountExists(email) {
                this.$http.get(`check-user/${email}`).then(res => {
                    if (!res.data.id) {
                        this.$router.push({
                            name: 'auth.register',
                            query: {
                                email: this.$route.query.email,
                                redirectTo: this.$route.query.redirectTo
                            }
                        })
                    }
                })
            }
        }
    };
</script>
<style scoped>
    * {
        font-family: "Poppins" !important;
    }

    .fa {
        font-family: "FontAwesome" !important;
    }

    h1 {
        color: white;
        font-size: 56px;
        font-weight: bold;
    }

    p {
        color: white;
        font-size: 16px;
        padding: 10px 0px;
    }

    .bgimg {
        background: url("../assets/images/bg-imag.png") no-repeat -430px 0 fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        padding-top: 120px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .bgimg .mask {
        position: fixed; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.2); /* Black background with opacity */
    }

    .close-mark {
        text-align: center;
        position: absolute;
        top: 20px;
        left: 80px;
        cursor: pointer;
    }

    .close-mark i {
        font-size: 30px;
        color: #6b6b6b;
        background-color: rgba(126, 126, 126, 0.1);
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        transition: ease-out 0.3s;
    }

    .close-mark:hover > i {
        background-color: rgba(126, 126, 126, 0.2);
    }

    .login p {
        color: black;
    }

    .smalltext {
        font-family: Poppins;
        font-size: 15px;
        color: black;
        font-weight: normal;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #e9e9f0;
        width: 100%;
        background-color: transparent;
        font-family: Poppins;
        margin: 1rem 0;
    }

    input:focus {
        border-color: black;
    }

    .btn-signup {
        color: #3297db;
        background-color: #ffffff;
        border-color: #3297db;
        font-weight: bold;
        border-radius: 1;
    }

    .btn-signup:hover,
    .btn-signup:active,
    .btn-signup:focus,
    .btn-signup.active {
        background: #3297db;
        color: #ffffff;
        border-color: #3297db;
    }

    .btn {
        min-width: 100%;
    }

    .login-btn {
        background-color: #3297db !important;
        color: #fff;
    }

    .padding-top-sm {
        padding-top: 1em;
    }

    .remember input[type="checkbox"] {
        width: auto;
        margin: 8px 0;
    }

    .remember label {
        display: inline-block;
        padding: 0 18px;
        font-family: Poppins;
    }

    @media only screen and (max-width: 768px) {
        .bgimg {
            padding-top: 70px;
        }

        .bgimg .mask {
            background-color: rgba(255, 255, 255, 0.1); /* Black background with opacity */
        }

        .close-mark {
            top: 2px;
            left: 50px;
        }

        .reg-panel {
            text-align: center;
        }

        .img-wrap img {
            width: 100px;
            height: 100px;
        }

        .form-group span {
            color: red !important;
        }
    }

    @media only screen and (max-width: 480px) {
        .bgimg {
            background: none;
        }

        .close-mark {
            display: none;
        }

        .left-panel {
            display: none;
        }

        .col-sm-6 {
            width: 50%;
        }

        form .reg-panel img{
            max-width: 100%;
        }

        form .texts, form .texts a{
            font-size: 11px!important;
            font-weight: 500;
            line-height: 20px;
        }

        form input::placeholder{
            font-size: 12px;
        }
        
    }
</style>
